<script setup>
import {useForm} from '@inertiajs/vue3';
import {v4} from 'uuid';
import {inject, ref} from 'vue';

import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card.vue';
import Divider from '@/Components/Divider.vue';
import SelectSearch from '@/Components/Input/SelectSearch.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Form from '@/Pages/Quotations/Partials/Form.vue';
import Line from '@/Pages/Quotations/Partials/Line.vue';
import {useUser} from '@/stores/userStore';
import {mapErrors} from '@/utilities';

const route = inject('route');
const user = useUser();

const props = defineProps({
    query: Object,
    contactOptions: Object,
    templateOptions: Object,
    typeOptions: Object,
    productOptions: Object,
});

const form = useForm({
    status: 'concept',
    template: Object.keys(props.templateOptions)[0],
    type: Object.keys(props.typeOptions)[0],
    number: '',
    date: '',
    assembly_date: '',
    disassembly_date: '',
    delivery_date: '',
    contact_id: '',
    start: '',
    end: '',
    adults: '',
    children: '',
    quotation_lines: [],
    postcode: '',
    street_number: '',
    street_number_addition: '',
    street_name: '',
    city: '',
    country: '',
    longitude: '',
    latitude: '',
    ...props.query,
});

const selectedProductId = ref(null);

const submitForm = () => {
    form.post(route('quotations.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

function addQuotationLine(id) {
    let product = props.productOptions.find((product) => product.id === id);
    form.quotation_lines.push({
        id: v4(),
        product_id: product.id,
        description: product.title,
        quantity: Number.isInteger(form.participants) ? product.scaling_factor * form.participants : 1,
        unit_price: product.price,
        unit_price_includes_vat: product.price_includes_vat,
        vat_rate: product.vat_rate,
        amount: product.price * 1,
    });
    selectedProductId.value = null;
}

function removeQuotationLine(id) {
    form.quotation_lines = form.quotation_lines.filter((line) => line.id !== id);
}
</script>

<template>
    <AppLayout>
        <Card>
            <template #title>
                Offerte aanmaken
            </template>
            <FormKit
                id="signup-form"
                :actions="false"
                type="form"
                @submit="submitForm"
            >
                <div class="grid grid-cols-1 gap-2">
                    <Form
                        :contact-options="contactOptions"
                        :form="form"
                        :template-options="templateOptions"
                        :type-options="typeOptions"
                    />
                </div>
            </FormKit>

            <Divider />

            <template
                v-for="(line, i) in form.quotation_lines"
                :key="line.id"
            >
                <Line
                    :line="line"
                    :participants="form.participants"
                    @delete="removeQuotationLine"
                />
            </template>

            <SelectSearch
                v-model="selectedProductId"
                :options="productOptions"
                clear-on-change
                label="Product toevoegen"
                @change="addQuotationLine(selectedProductId)"
            />

            <template #buttons>
                <Button
                    v-if="user.can('create-quotations')"
                    :form="form"
                    label="Opslaan"
                    @click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>

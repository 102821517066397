<script setup>
import InputGroup from '@/Components/Input/Group.vue';
import SelectSearch from '@/Components/Input/SelectSearch.vue';
import Select from '@/Components/Select.vue';

import {useUser} from '@/stores/userStore';
import {inject} from 'vue';

const user = useUser();
const route = inject('route');
const dayjs = inject('dayjs');

const props = defineProps({
    form: Object,
    userOptions: Object,
    eventOptions: Object,
    typeOptions: Object,
    statusOptions: Object,
});

const fillTimes = () => {
    if (props.form.event_id === '' || props.form.event_id == null) {
        return;
    }

    window.axios
        .get(
            route('api.events.hours.index', {
                event: props.form.event_id,
                user_id: user.can('create-time-registrations') ? props.form.user_id : user.user.id,
            })
        )
        .then(function (response) {
            let hours = response.data.data;
            props.form.date = dayjs(hours.start).format('YYYY-MM-DD');
            props.form.start_time = dayjs(hours.start).format('HH:mm');

            props.form.end_time = hours.all_day ? dayjs(hours.start).format('23:59') : dayjs(hours.end).format('HH:mm');
        });
};

const halfHoursOptions = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30'];
const timeOptions = [...Array(24).keys()].map(hour => String(hour).padStart(2, 0)).map((hour) => [hour + ':00', hour + ':15', hour + ':30', hour + ':45',]).flat();

</script>
<template>
    <SelectSearch
        v-if="user.can('create-time-registrations')"
        v-model="form.user_id"
        :options="userOptions"
        label="Werknemer"
    />

    <SelectSearch
        v-model="form.event_id"
        :help="`<span class='underline cursor-pointer'>Tijden overnemen</span>`"
        :options="eventOptions"
        label="Evenement"
        @help-clicked="fillTimes"
    />

    <Select
        v-if="user.canAny(['approve-time-registrations', 'reject-time-registrations'])"
        v-model="form.status"
        :options="statusOptions"
        label="Status"
    />

    <InputGroup>
        <FormKit
            v-model="form.date"
            :errors="form.errors?.date"
            :value="form.date"
            label="Datum"
            type="date"
        />
        <FormKit
            v-model="form.start_time"
            :errors="form.errors?.start_time"
            :options="timeOptions"
            :value="form.start_time"
            label="Start"
            type="select"
        />
        <FormKit
            v-model="form.end_time"
            :errors="form.errors?.end_time"
            :options="timeOptions"
            :value="form.end_time"
            label="Eind"
            type="select"
        />
        <FormKit
            v-model="form.break"
            :errors="form.errors?.break"
            :options="halfHoursOptions"
            :value="form.end"
            label="Pauze"
            type="select"
        />
    </InputGroup>

    <InputGroup v-if="form.event_id">
        <FormKit
            v-if="useUser().can('create-assembly-time-registrations')"
            v-model="form.duration_assembly"
            :errors="form.errors?.duration_assembly"
            :options="timeOptions"
            :value="form.duration_assembly"
            label="Opbouw tijd"
            type="select"
        />

        <FormKit
            v-if="useUser().can('create-assembly-time-registrations')"
            v-model="form.duration_disassembly"
            :errors="form.errors?.duration_disassembly"
            :options="timeOptions"
            :value="form.duration_disassembly"
            label="Afbouw tijd"
            type="select"
        />

        <FormKit
            v-model="form.drinks"
            :errors="form.errors?.drinks"
            :value="form.drinks"
            label="Drankjes"
            type="text"
        />
    </InputGroup>
</template>

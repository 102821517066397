<script setup>
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {computed, inject} from 'vue';

import Button from '@/Components/Buttons/Button.vue';
import ConformationButton from '@/Components/Buttons/ConformationButton.vue';
import Card from '@/Components/Card.vue';
import Pagination from '@/Components/Pagination.vue';
import Table from '@/Components/Table/Table.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useUser} from '@/stores/userStore';
import Form from '@/Pages/Skills/Partials/Form.vue';

const route = inject('route');
const user = useUser();

const props = defineProps({
    query: Object,
    skill: Object,
    users: Object,
});

const editForm = useForm({
    name: '',
    ...props.skill,
});

const submitEditForm = () => {
    editForm.put(route('skills.update', props.skill.id), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

const form = useForm({
    orderBy: props.query.orderBy ?? 'name',
    orderDir: props.query.orderDir ?? 'desc',
    page: props.query.page ?? 1,
});

const getData = (page = 1) => {
    form.page = page;

    form.get(route('skills.edit', props.skill.id), {
        preserveState: true,
        only: ['users', 'query'],
    });
};

const tableSettings = computed(() => [
    {
        label: 'Naam',
        orderBy: 'name',
        width: '15%',
    },
    {
        label: 'Email',
        width: '15%',
        cellType: 'email',
        header: {
            from: 'md',
        },
        combinesWith: {
            horizontal: true,
            items: [{label: 'Telefoon', till: 'md'}],
        },
    },
    {label: 'Telefoon', width: '15%', cellType: 'phone', from: 'md'},
    {label: '', width: '1%', cellType: 'arrow', permission: user.can('update-users')},
]);

const tableData = computed(() =>
    props.users.data.map((user) => {
        return {
            id: user.id,
            rowData: [user.name, user.email, user.phone_number, route('users.edit', user.id)],
        };
    })
);
</script>

<template>
    <AppLayout>
        <Card>
            <template #title>
                Vaardigheid aanpassen
            </template>

            <div class="grid grid-cols-1 gap-2">
                <Form :form="editForm" />
            </div>

            <template #buttons>
                <ConformationButton
                    v-if="user.can('delete-skill')"
                    :url="route('skills.destroy', skill.id)"
                    conformation-message="Weet je zeker dat je deze vaardigheid wilt verwijderen?"
                    conformation-title="Verwijderen"
                    label="Verwijderen"
                    method="delete"
                />
                <Button
                    v-if="user.can('update-skill')"
                    :form="editForm"
                    label="Aanpassen"
                    @click="submitEditForm"
                />
            </template>
        </Card>

        <Table
            v-model:orderBy="form.orderBy"
            v-model:orderDir="form.orderDir"
            :loading="form.processing"
            :table-data="tableData"
            :table-settings="tableSettings"
            @update:order-by="getData()"
            @update:order-dir="getData()"
        >
            <Pagination
                :page-data="users"
                @set-page="getData"
            />
        </Table>
    </AppLayout>
</template>

<script setup>
import theme from '@/formkitTheme';
import {Listbox, ListboxLabel, ListboxOption, ListboxOptions} from '@headlessui/vue';
import {XMarkIcon} from '@heroicons/vue/20/solid';
import {MagnifyingGlassIcon} from '@heroicons/vue/24/outline';
import {useFuse} from '@vueuse/integrations/useFuse';
import {computed, onMounted, ref, watch} from 'vue';
import Errors from '@/Components/Input/Errors.vue';

const props = defineProps({
    label: String,
    modelValue: {},
    options: [Array, Object],
    help: String,
    errors: Array,
    clearOnChange: Boolean,
    disabled: Boolean,
});

const emits = defineEmits(['update:modelValue', 'change', 'helpClicked']);

const open = ref(false);

const localeOptions = computed(() => {
    if (Array.isArray(props.options)) {
        return props.options.map((option) => {
            return {
                label: option.label ?? option.name ?? option.title,
                value: option.value ?? option.id,
            };
        });
    }

    let ans = [];
    for (const [key, value] of Object.entries(props.options)) {
        ans.push({
            label: value,
            value: key,
        });
    }
    return ans;
});

const selected = ref('');
const searchTerm = ref('');

watch(
    () => props.modelValue,
    () => {
        selected.value = localeOptions.value.find((option) => option.value === props.modelValue);
        searchTerm.value = selected.value?.label;
    }
);

onMounted(() => {
    selected.value = localeOptions.value.find((option) => option.value === props.modelValue);
    searchTerm.value = selected.value?.label;
});

function emitValue(value) {
    value = localeOptions.value.find((el) => el.label === value);
    if (typeof value == 'undefined') {
        return;
    }
    selected.value = value;
    searchTerm.value = value.label;
    emits('update:modelValue', value.value);
    emits('change');
    if (props.clearOnChange) {
        searchTerm.value = null;
        selected.value = null;
    }
}

const {results} = useFuse(
    searchTerm,
    localeOptions.value.map((el) => el.label),
    {matchAllWhenSearchEmpty: true}
);

const displayOptions = computed(() => results.value.map((el) => el.item));

const input = ref(null);

function clearAndFocus() {
    searchTerm.value = '';
    input.value.focus();
    selected.value = null;
    emits('update:modelValue', null);
    emits('change');
}

function tab() {
    open.value = false;
}
</script>

<template>
    <Listbox
        v-model="selected"
        :class="theme.global.outer"
        as="div"
    >
        <ListboxLabel :class="theme.global.label">
            {{ label }}
        </ListboxLabel>
        <div :class="theme.global.inner">
            <div>
                <input
                    id="account-number"
                    ref="input"
                    v-model="searchTerm"
                    :class="theme.global.input"
                    :disabled="disabled"
                    autocomplete="off"
                    name="account-number"
                    tabindex="-1"
                    type="text"
                    @blur="open = false"
                    @focus="open = true"
                    @keydown.enter="emitValue(searchTerm)"
                    @keydown.esc="open = false"
                    @keydown.tab="tab()"
                >
                <div
                    v-if="searchTerm?.length > 0 && !disabled"
                    class="pointer-pointer absolute inset-y-0 right-0 m-1 flex items-center bg-white pr-2"
                    @click="clearAndFocus()"
                >
                    <XMarkIcon
                        aria-hidden="true"
                        class="size-5 text-gray-400"
                    />
                </div>
                <div
                    v-else-if="!disabled"
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                >
                    <MagnifyingGlassIcon
                        aria-hidden="true"
                        class="size-5 text-gray-400"
                    />
                </div>
            </div>

            <transition
                v-show="open"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    static
                >
                    <ListboxOption
                        v-for="(option, key) in displayOptions"
                        :key="key"
                        v-slot="{ active, selected }"
                        :value="option"
                        as="template"
                    >
                        <li
                            :class="[
                                active ? 'bg-pine-500 text-white' : 'text-gray-900',
                                'relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-pine-500 hover:text-white',
                            ]"
                            @click="emitValue(option)"
                        >
                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                {{ option }}
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
        <div
            v-if="help"
            :class="theme.global.help"
            @click="emits('helpClicked')"
            v-html="help"
        />
        <Errors :errors="errors" />
    </Listbox>
</template>

<script setup>
import Button from '@/Components/Buttons/Button.vue';
import InlineButton from '@/Components/Buttons/InlineButton.vue';

import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
    label: String,
    url: String,
    method: {
        type: String,
        default: 'get',
    },
    data: {
        type: Object,
    },
    disabled: Boolean,
    red: Boolean,
    inline: Boolean,
    icon: [String, Object, Function],
    fullWidth: Boolean,
});

const emits = defineEmits(['onSuccess']);

const form = useForm({});

const submitForm = () => {
    form.transform((data) => ({
        ...data,
        ...(props.data ?? {}),
    })).submit(props.method, props.url, {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
        onSuccess: () => {
            emits('onSuccess');
        },
    });
};
</script>
<template>
    <InlineButton
        v-if="inline"
        :label="label"
        :icon="icon"
        :form="form"
        :red="red || method == 'delete'"
        :disabled="disabled"
        :full-width="fullWidth"
        @click="submitForm"
    />
    <Button
        v-else
        :label="label"
        :form="form"
        :red="red || method == 'delete'"
        :disabled="disabled"
        :full-width="fullWidth"
        @click="submitForm"
    />
</template>

import '../css/app.css';
import './bootstrap';

import { createInertiaApp } from '@inertiajs/vue3';
import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
const pinia = createPinia();
import { ZiggyVue } from './plugins/ziggy';
import { Ziggy } from './ziggy';
import dayjs from './plugins/dayjs';
import { createI18n } from 'vue-i18n';
import localeMessages from './vue-i18n-locales.generated';
import { flare } from '@flareapp/flare-client';
import { flareVue } from '@flareapp/flare-vue';
import formKitTheme from '@/formkitTheme';
import { nl } from '@formkit/i18n';
import { generateClasses } from '@formkit/themes';
import { defaultConfig, plugin as FKp } from '@formkit/vue';

if (import.meta.env.PROD) {
    flare.light();
}

const numberFormats = {
    nl: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            notation: 'standard',
        },

        percent: {
            style: 'percent',
            useGrouping: true,
        },
    },
};

import Notifications from 'notiwind';

createInertiaApp({
    progress: {
        color: '#FFF',
        delay: 500,
    },
    resolve: (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
        return pages[`./Pages/${name}.vue`];
    },
    setup({ el, App, props, plugin }) {
        const currentLocale = props.initialPage.props.locale;

        const i18n = createI18n({
            numberFormats,
            legacy: false,
            locale: currentLocale,
            fallbackLocale: 'en',
            messages: localeMessages,
        });

        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(pinia)
            .use(flareVue)
            .use(Notifications)
            .use(i18n)
            .use(ZiggyVue, { ...Ziggy, locale: currentLocale })
            .use(dayjs, { locale: currentLocale })
            .use(
                FKp,
                defaultConfig({
                    config: {
                        classes: generateClasses(formKitTheme),
                    },
                    locales: { nl },
                    locale: 'nl',
                    globalInjection: true,
                    icons: {
                        search: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"> <path id="search_FILL0_wght400_GRAD0_opsz48_3_" data-name="search_FILL0_wght400_GRAD0_opsz48 (3)" d="M21.022,21.95l-5.844-5.844a4.9,4.9,0,0,1-1.556.9,5.5,5.5,0,0,1-1.889.322,5.533,5.533,0,0,1-4.067-1.667A5.481,5.481,0,0,1,6,11.639,5.481,5.481,0,0,1,7.667,7.617,5.507,5.507,0,0,1,11.711,5.95a5.444,5.444,0,0,1,4.011,1.667,5.687,5.687,0,0,1,1.344,5.867,5.641,5.641,0,0,1-.933,1.667L22,20.972Zm-9.311-5.956a4.159,4.159,0,0,0,3.067-1.278,4.214,4.214,0,0,0,1.267-3.078,4.214,4.214,0,0,0-1.267-3.078,4.159,4.159,0,0,0-3.067-1.278,4.363,4.363,0,0,0-4.378,4.356,4.363,4.363,0,0,0,4.378,4.356Z" transform="translate(-6 -5.95)" fill="#292929"/></svg> ',
                    },
                })
            )
            .mount(el);
    },
});

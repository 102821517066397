<script setup>
import SmallButton from '@/Components/Buttons/SmallButton.vue';
import Label from '@/Components/Input/Label.vue';
import SelectSearch from '@/Components/Input/SelectSearch.vue';
import InputGroup from '@/Components/Input/Group.vue';
import AddressForm from '@/Pages/Events/Partials/AddressForm.vue';
import {UserPlusIcon} from '@heroicons/vue/24/outline';
import {router} from '@inertiajs/core';
import {inject} from 'vue';

const route = inject('route');

const props = defineProps({
    form: Object,
    contactOptions: Object,
});

const fillAddress = () => {
    if (props.form.contact_id == '' || props.form.contact_id == null) {
        return;
    }

    window.axios
        .get(
            route('api.address.index', {
                contact_id: props.form.contact_id,
            })
        )
        .then(function (response) {
            props.form.errors.contact_id = [];
            if (response.data.data.length == 0) {
                props.form.postcode = '';
                props.form.street_number = '';
                props.form.street_name = '';
                props.form.city = '';
                props.form.country = '';
                props.form.longitude = '';
                props.form.latitude = '';
                props.form.errors.contact_id = ['Geen adres bekent'];
                return;
            }
            let address = response.data.data[0];
            props.form.postcode = address.postcode;
            props.form.street_number = address.street_number;
            props.form.street_name = address.street_name;
            props.form.city = address.city;
            props.form.country = 'NL';
            props.form.longitude = address.longitude;
            props.form.latitude = address.latitude;
            props.form.errors.postcode = [];
        });
};
</script>
<template>
    <InputGroup>
        <div class="flex w-full gap-4">
            <SelectSearch
                v-model="form.contact_id"
                label="Klant"
                :options="contactOptions"
                :errors="form.errors?.contact_id"
                :help="`<span class='underline cursor-pointer'>Adres overnemen</span>`"
                @helpClicked="fillAddress"
            />
            <div class="flex flex-col gap-1">
                <Label label="&nbsp;" />
                <SmallButton
                    :icon="UserPlusIcon"
                    @click="
                        router.get(
                            route('contacts.create', {
                                intended: route('quick-quotations.create', { contact_id: '{created_id}' }),
                            })
                        )
                    "
                >
                    <UserPlusIcon
                        class="h-5 w-5 text-beige-500"
                        aria-hidden="true"
                    />
                </SmallButton>
            </div>
        </div>
    </InputGroup>

    <AddressForm :form="form" />

    <InputGroup>
        <FormKit
            v-model="form.adults"
            type="text"
            placeholder="Nader te bepalen"
            label="Aantal volwassenen"
            :errors="form.errors?.adults"
            :value="form.adults"
            :disabled="disabled"
        />
        <FormKit
            v-model="form.children"
            type="text"
            placeholder="Nader te bepalen"
            label="Aantal kinderen"
            :errors="form.errors?.children"
            :value="form.children"
            :disabled="disabled"
        />
    </InputGroup>

    <InputGroup>
        <FormKit
            v-model="form.start"
            type="datetime-local"
            label="Start"
            :errors="form.errors?.start"
            :value="form.start"
            validate="required"
        />
        <FormKit
            v-model="form.end"
            type="datetime-local"
            label="Eind"
            :errors="form.errors?.end"
            :value="form.end"
            validate="required"
        />
    </InputGroup>

    <FormKit
        v-model="form.description"
        type="textarea"
        label="Omschrijving"
        :errors="form.errors?.description"
        :value="form.description"
        validate="required"
    />
    <FormKit
        v-model="form.decoration"
        type="textarea"
        label="Aankleding"
        :errors="form.errors?.decoration"
        :value="form.decoration"
        validate="required"
    />
</template>

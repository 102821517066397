<script setup>
import SmallButton from '@/Components/Buttons/SmallButton.vue';
import Label from '@/Components/Input/Label.vue';
import Money from '@/Components/Input/Money.vue';
import Number from '@/Components/Input/Number.vue';
import {TrashIcon} from '@heroicons/vue/24/outline';

const props = defineProps({
    line: Object,
    vatRateOptions: Object,
    errors: Object,
});

const emits = defineEmits(['delete']);

function updateTotal() {
    props.line.amount = props.line.quantity * props.line.price.price
}
</script>

<template>
    <div class="grid grid-cols-12 gap-4 gap-y-0 md:grid-cols-11">
        <FormKit
            v-model="line.description"
            :classes="{ outer: 'col-span-9 md:col-span-6' }"
            :errors="errors?.description"
            :value="line.description"
            label="Omschrijving"
            name="country"
            type="text"
            validate="required"
            @change="updateTotal"
        />

        <Number
            v-model="line.quantity"
            :errors="errors?.quantity"
            class="col-span-3 md:col-span-1"
            label="Aantal"
            @change="updateTotal"
        />

        <Money
            v-model="line.price.price"
            :errors="errors?.unit_price"
            class="col-span-3 md:col-span-1"
            label="Prijs"
            @change="updateTotal"
        />

        <FormKit
            v-model="line.price.vat_rate"
            :classes="{ outer: 'col-span-4 md:col-span-1' }"
            :errors="errors?.vat_rate"
            :options="vatRateOptions"
            :value="line.vat_rate"
            label="BTW"
            name="vat_rate"
            type="select"
            validate="required"
            @change="updateTotal"
        />

        <div class="col-span-5 flex gap-4 md:col-span-2">
            <div class="flex-1">
                <Money
                    v-model="line.amount"
                    :errors="errors?.amount"
                    classes="text-right"
                    label="Totaal"
                />
            </div>
            <div class="mb-2 flex flex-col">
                <Label label="&nbsp;" />
                <SmallButton @click="emits('delete', line.id)">
                    <TrashIcon
                        aria-hidden="true"
                        class="size-5 text-white"
                    />
                </SmallButton>
            </div>
        </div>
    </div>
</template>

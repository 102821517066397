<script setup>
import {useUser} from '@/stores/userStore.js';
import {capitalizeFirstLetter} from '@/utilities.js';
import {computed, inject} from 'vue';
import DateMenu from '@/Pages/Events/Partials/DateMenu.vue';
import ItemCard from '@/Pages/Events/Index/ItemCard.vue';

const dayjs = inject('dayjs');
const user = useUser();

const props = defineProps({
    date: String,
    status: String,
    events: Array,
    selectedType: String,
});

const emits = defineEmits(['dateMarkingUpdated']);

const sortedEvents = computed(() => [
    ...filteredEvents.value.filter((a) => a.all_day),
    ...filteredEvents.value.filter((a) => !a.all_day).sort((a, b) => new Date(a.start) - new Date(b.start)),
]);

const filteredEvents = computed(() => {
    if (!props.selectedType || props.selectedType === 'null') {
        return props.events;
    }
    return props.events.filter((event) => event.type === props.selectedType);
});

</script>
<template>
    <div
        v-if="filteredEvents.length > 0"
        class="relative mx-auto w-full max-w-7xl"
    >
        <div
            :id="date"
            class="absolute top-0 h-20 -translate-y-full"
        />
        <div class="flex items-center justify-between px-4 pb-2 sm:px-0">
            <h2 class="mb-2 flex items-center gap-2 text-base font-semibold text-pine-500">
                <template v-if="status === 'full'">
                    <div class="size-2 rounded-full bg-red-600" />
                </template>
                <template v-if="status === 'almost_full'">
                    <div class="size-2 rounded-full bg-yellow-400" />
                </template>
                {{ capitalizeFirstLetter(dayjs(date).format('dddd, D MMMM YYYY')) }}
            </h2>
            <DateMenu
                v-if="user.can('update-dates')"
                :date="date"
                :status="status"
                @update="emits('dateMarkingUpdated', $event)"
            />
        </div>

        <ul
            class="space-y-4"
            role="list"
        >
            <li
                v-for="event in sortedEvents"
                :key="event.id"
            >
                <ItemCard
                    :date="date"
                    :event="event"
                />
            </li>
        </ul>
    </div>
</template>

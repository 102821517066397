import DataObjectParser from 'dataobject-parser';
import {pickBy} from 'lodash';

export function objectMap(obj, fn) {
    return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
}

export function mapErrors(errors, remap = {}) {
    errors = objectMap(errors, (error) => [error]);
    errors = DataObjectParser.transpose(errors).data();

    for (const [key, value] of Object.entries(remap)) {
        if (Object.hasOwnProperty.call(remap, key)) {
            errors[key] = errors[value];
        }
    }
    console.table(errors);
    return errors;
}

export function filterEmpty(data) {
    return pickBy(data, (v, k, i) => v !== '' && v !== null);
}

export function getTimesBetween(start, end, step = 'day', stepSize = 1) {
    let localStart = start;
    let dates = [];
    while (localStart.isBefore(end)) {
        dates.push(localStart);
        localStart = localStart.add(stepSize, step);
    }
    return dates;
}

export function pick(obj, fields) {
    return fields.reduce((acc, cur) => ((acc[cur] = obj[cur]), acc), {});
}

export function capitalFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getVat(product) {
    let price = parseInt(product?.price ?? product.unit_price ?? product?.price.price ?? 0);
    let vat = parseInt(product?.vat_rate ?? 0);
    let priceIncludesVat = product?.price_includes_vat ?? product?.price.price_including_vat ?? true;

    if (priceIncludesVat) {
        return Math.round((price * vat) / (100 + vat));
    }

    return Math.round(price * (vat / 100));
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

<script setup>
import { PlusIcon } from '@heroicons/vue/20/solid';
defineProps({
    title: String,
});
const emits = defineEmits(['buttonClicked']);
</script>
<template>
    <div class="relative my-4">
        <div
            class="absolute inset-0 flex items-center"
            aria-hidden="true"
        >
            <div class="w-full border-t border-beige-200" />
        </div>
        <div class="relative flex justify-center">
            <button
                type="button"
                class="inline-flex items-center rounded-full border border-beige-200 bg-white px-4 py-1.5 text-sm font-medium leading-5 text-pine-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pine-500 focus:ring-offset-2"
                @click="emits('buttonClicked')"
            >
                <PlusIcon
                    class="-ml-1.5 mr-1 h-5 w-5 text-pine-500"
                    aria-hidden="true"
                />
                <span>{{ title }}</span>
            </button>
        </div>
    </div>
</template>

<script setup>
import Button from '@/Components/Buttons/Button.vue';
import FormButton from '@/Components/Buttons/FormButton.vue';
import CardInset from '@/Components/CardInset.vue';
import Divider from '@/Components/Divider.vue';
import Label from '@/Components/Input/Label.vue';
import AttendeeInput from '@/Pages/Events/Partials/AttendeeInput.vue';
import theme from '@/formkitTheme';
import {useUser} from '@/stores/userStore';
import {computed, inject} from 'vue';

const route = inject('route');
const dayjs = inject('dayjs');

const props = defineProps({
    event: Object,
    userOptions: Object,
    isSingle: Boolean,
    statusOptions: Object,
});

const user = useUser();

const attendanceCurrentUser = computed(() => {
    return props.event.attendances.find((attendance) => attendance.user_id === user.id);
});

const canAttend = computed(() => {
    return (
        (typeof attendanceCurrentUser.value === 'undefined' || attendanceCurrentUser.value.status === 'retracted') &&
        dayjs(props.event.open_attendance_from).isBefore(dayjs().startOf('day'))
    );
});

const canAccept = computed(() => {
    return typeof attendanceCurrentUser.value !== 'undefined' && attendanceCurrentUser.value.status === 'invited';
});

const canRetract = computed(() => {
    return typeof attendanceCurrentUser.value !== 'undefined' && attendanceCurrentUser.value.status === 'applied';
});

const canWithdraw = computed(() => {
    return (
        typeof attendanceCurrentUser.value !== 'undefined' &&
        attendanceCurrentUser.value.status === 'accepted' &&
        dayjs(props.event.retract_attendance_till).isAfter(dayjs().startOf('day'))
    );
});

const canAny = computed(() => {
    return user.can('update-events') || canAttend.value || canAccept.value || canRetract.value || canWithdraw.value;
});
</script>

<template>
    <div class="px-4 py-5 sm:p-6">
        <div :class="theme.global.outer">
            <dt :class="theme.global.label">
                Omschrijving
            </dt>
            <dd
                class="mt-1 text-sm"
                v-html="event.description ?? '---'"
            />
        </div>

        <Label label="Bijlagen" />

        <template v-if="event.attachments.length === 0">
            <div class="mt-1 text-sm text-gray-500">
                ---
            </div>
        </template>

        <ul
            v-else
            class="divide-y divide-gray-200 rounded-md border border-gray-200"
            role="list"
        >
            <a
                v-for="attachment in event.attachments"
                :key="attachment.id"
                :href="route('attachments.show', { attachment: attachment.id })"
                class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                target="_blank"
            >
                {{ attachment.url }}
                <div class="flex w-0 flex-1 items-center">
                    <svg
                        aria-hidden="true"
                        class="size-5 shrink-0 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path
                            clip-rule="evenodd"
                            d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                            fill-rule="evenodd"
                        />
                    </svg>
                    <a class="ml-2 w-0 flex-1 truncate">{{ attachment.name }}</a>
                </div>
            </a>
        </ul>
    </div>

    <template v-if="user.canAny(['accept-attendances', 'reject-attendances', 'invite-attendances'])">
        <Divider :title="event.capacity ? `Aanmeldingen ${event.accepted_attendances_count}/${event.capacity}` : ``" />

        <AttendeeInput
            :attendances="event.attendances"
            :event="event"
            :status-options="statusOptions"
            :user-options="Object.values(userOptions)"
        />
    </template>

    <template v-if="canAny">
        <Divider />

        <CardInset class="flex justify-end gap-8">
            <Button
                is="a"
                v-if="event.type == 'go_ahead_eagles'"
                :disabled="!event.staffing"
                :href="route('events.staffing', { id: event.id })"
                :label="event.staffing ? `Download bezetting` : 'Nog geen bezetting bekent'"
                target="_blank"
            />
            <Button
                is="a"
                v-if="event.type == 'go_ahead_eagles'"
                :disabled="!event.schema"
                :href="route('events.schema', { id: event.id })"
                :label="event.schema ? `Download schema` : 'Nog geen schema bekent'"
                target="_blank"
            />

            <FormButton
                v-if="canAttend"
                :data="{ event_id: event.id, user_id: user.id }"
                :url="route('attendances.store')"
                label="Aanmelden"
                method="post"
            />

            <FormButton
                v-if="canAccept"
                :data="{ attendance_id: attendanceCurrentUser.id }"
                :url="route('accepted-attendances.store')"
                label="Uitnodiging accepteren"
                method="post"
            />

            <FormButton
                v-if="canRetract"
                :data="{ attendance_id: attendanceCurrentUser.id }"
                :url="route('retracted-attendances.store')"
                label="Aanmelden intrekken"
                method="post"
            />

            <FormButton
                v-if="canWithdraw"
                :data="{ attendance_id: attendanceCurrentUser.id }"
                :url="route('retracted-attendances.store')"
                label="Afzeggen"
                method="post"
                red
            />

            <FormButton
                v-if="user.can('update-events')"
                :url="route('events.edit', { id: event.parent_id ?? event.id })"
                label="Aanpassen"
            />
        </CardInset>
    </template>
</template>

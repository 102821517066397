<script setup>
import SelectSearch from '@/Components/Input/SelectSearch.vue';
import { computed, inject, ref } from 'vue';

import MultiButton from '@/Components/Buttons/MultiButton.vue';
import Table from '@/Components/Table/Table.vue';
import { CheckIcon } from '@heroicons/vue/24/outline';
import cross from '@i/cross.svg';
import { useForm } from '@inertiajs/vue3';

const route = inject('route');

const props = defineProps({
    event: Object,
    attendances: Object,
    userOptions: Object,
    statusOptions: Object,
    conflictingAttendances: Object,
    selectedEventId: [Number, String],
});

const selectedEvent = computed(() => {
    return props.event.children.find((evt) => evt.id == props.selectedEventId) || props.event;
});


const tableSettings = computed(() => [
    {
        label: 'Naam',
        width: '15%',
        combinesWith: {
            items: [
                {
                    label: 'Status',
                    till: 'md',
                },
            ],
        },
    },
    {
        label: 'Email',
        width: '15%',
        cellType: 'email',
        combinesWith: {
            horizontal: true,
            items: [
                {
                    label: 'Telefoon',
                    till: 'md',
                },
            ],
        },
    },
    { label: 'Telefoon', width: '15%', cellType: 'phone', from: 'md' },
    { label: 'Status', width: '15%', from: 'md' },
    { label: 'Conflict', width: '15%', from: 'md' },
    { label: '', width: '30%', cellType: 'formButton' },
]);

const tableData = computed(() =>
    selectedEvent.value.attendances
        .filter((att) => !['rejected', 'retracted'].includes(att.status))
        .sort((a, b) => (a.status > b.status ? 1 : -1))
        .map((attendance) => {
            let conflictingAttendances = props.conflictingAttendances.filter(
                (att) => att.user_id == attendance.user_id
            );
            return {
                id: attendance.id,
                rowData: [
                    attendance.user.name,
                    attendance.user.email,
                    attendance.user.phone_number,
                    props.statusOptions[attendance.status],
                    props.conflictingAttendances.filter((att) => att.user_id == attendance.user_id).length > 0
                        ? 'Ja, ' +
                        conflictingAttendances
                            .filter((att) => att.user_id == attendance.user_id)
                            .map((att) => att.event.title)
                            .join(', ')
                        : 'Nee',
                    getButton(attendance),
                ],
            };
        })
);

const alreadyAttendingUsers = computed(() =>
    selectedEvent.value.attendances
        .filter((att) => !['rejected', 'retracted'].includes(att.status))
        .map((attendance) => attendance.user_id)
);

const filteredUserOptions = computed(() =>
    props.userOptions.filter((user) => !alreadyAttendingUsers.value.includes(parseInt(user.id)))
);

const selectedUserId = ref(null);

function resetSelectedId() {
    selectedUserId.value = null;
}

function getButton(attendance) {
    if (attendance.status == 'invited') {
        return [
            {
                label: 'Uitnodiging intrekken',
                method: 'post',
                red: true,
                url: route('retracted-attendances.store'),
                data: { attendance_id: attendance.id },
                icon: cross,
            },
        ];
    }

    if (attendance.status == 'accepted') {
        return [
            {
                label: 'Afzeggen',
                method: 'post',
                red: true,
                url: route('rejected-attendances.store'),
                data: { attendance_id: attendance.id },
                icon: cross,
            },
        ];
    }

    return [
        {
            label: 'Accepteren',
            method: 'post',
            url: route('accepted-attendances.store'),
            data: { attendance_id: attendance.id },
            disabled: selectedEvent.value.accepted_attendances_count >= selectedEvent.value.capacity,
            icon: CheckIcon,
        },
        {
            label: 'Afwijzen',
            method: 'post',
            red: true,
            url: route('rejected-attendances.store'),
            data: { attendance_id: attendance.id },
            icon: cross,
        },
    ];
}

const form = useForm({});

const data = computed(() => ({
    status: 'invited',
    user_id: selectedUserId.value,
    event_id: selectedEvent.value?.id,
}));

function submitForm(button) {
    console.log(button);
    if (button == 'main') {
        form.transform(() => data.value).post(route('attendances.store'), {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors)),
            onSuccess: () => resetSelectedId(),
        });
    }
    if (button == 'favorites') {
        form.transform(() => {
            return { ...data.value, 'action': 'favorite' }
        }).post(route('attendances.store'), {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors)),
            onSuccess: () => resetSelectedId(),
        });
    }




}

</script>

<template>
    <div class="">
        <Table
            remove-spacer
            :table-settings="tableSettings"
            :table-data="tableData"
        />
    </div>
    <div class="-mb-3 flex w-full flex-col items-center gap-4 gap-y-0 p-4 sm:p-6 md:flex-row">
        <SelectSearch
            v-model="selectedUserId"
            class="mb-0 flex-1"
            :options="filteredUserOptions"
        />

        <MultiButton
            label="Uitnodigen"
            :form="form"
            :items="[
                { label: 'Favorite uitnodigen', value: 'favorites' },
            ]"
            @buttonClick="submitForm"
        />
    </div>
</template>

<script setup>
import {
    Combobox,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import { CalendarIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/core';
import { useAxios } from '@vueuse/integrations/useAxios';
import { inject, ref } from 'vue';

const route = inject('route');
const dayjs = inject('dayjs');

defineProps({
    modelValue: Boolean,
});

const emits = defineEmits(['update:modelValue']);

const rawQuery = ref('');

const filteredProjects = ref([]);

async function search() {
    if (rawQuery.value === '') {
        filteredProjects.value = [];
        return;
    }
    const { data, isLoading, isFinished } = await useAxios(route('api.events.search', { s: rawQuery.value }));


    filteredProjects.value = data.value.data.map((event) => {
        return {
            id: event.id,
            name: event.title + ' - ' + dayjs(event.start).format('DD-MM-YYYY'),
            category: 'Events',
            url: route('events.show', event.id),
        };
    });
}

function close() {
    rawQuery.value = '';
    filteredProjects.value = [];
    emits('update:modelValue', false);
}

function setRawQuery(event) {
    rawQuery.value = event.target.value;
    search();
}

function onSelect(item) {
    router.get(item.url);
}
</script>
<template>
    <TransitionRoot
        :show="modelValue"
        as="template"
        appear
        @after-leave="close"
    >
        <Dialog
            as="div"
            class="relative z-10"
            @close="close"
        >
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95"
                >
                    <DialogPanel
                        class="mx-auto max-w-xl divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
                    >
                        <Combobox @update:modelValue="onSelect">
                            <div class="relative">
                                <MagnifyingGlassIcon
                                    class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                                <ComboboxInput
                                    class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                    placeholder="Search..."
                                    @change="setRawQuery"
                                />
                            </div>

                            <ComboboxOptions
                                v-if="filteredProjects.length > 0"
                                static
                                class="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2"
                            >
                                <li v-if="filteredProjects.length > 0">
                                    <h2 class="text-xs font-semibold text-gray-900">
                                        Evenementen
                                    </h2>
                                    <ul class="-mx-4 mt-2 text-sm text-gray-700">
                                        <ComboboxOption
                                            v-for="project in filteredProjects"
                                            :key="project.id"
                                            v-slot="{ active }"
                                            :value="project"
                                            as="template"
                                        >
                                            <li
                                                :class="[
                                                    'flex cursor-default select-none items-center px-4 py-2',
                                                    active && 'bg-pine-500 text-white',
                                                ]"
                                            >
                                                <CalendarIcon
                                                    :class="[
                                                        'h-6 w-6 flex-none',
                                                        active ? 'text-white' : 'text-gray-400',
                                                    ]"
                                                    aria-hidden="true"
                                                />
                                                <span class="ml-3 flex-auto truncate">{{ project.name }}</span>
                                            </li>
                                        </ComboboxOption>
                                    </ul>
                                </li>
                                <!-- <li v-if="filteredUsers.length > 0">
                                    <h2 class="text-xs font-semibold text-gray-900">Users</h2>
                                    <ul class="-mx-4 mt-2 text-sm text-gray-700">
                                        <ComboboxOption
                                            v-for="user in filteredUsers"
                                            :key="user.id"
                                            :value="user"
                                            as="template"
                                            v-slot="{ active }"
                                        >
                                            <li
                                                :class="[
                                                    'flex cursor-default select-none items-center px-4 py-2',
                                                    active && 'bg-indigo-600 text-white',
                                                ]"
                                            >
                                                <img
                                                    :src="user.imageUrl"
                                                    alt=""
                                                    class="h-6 w-6 flex-none rounded-full"
                                                />
                                                <span class="ml-3 flex-auto truncate">{{ user.name }}</span>
                                            </li>
                                        </ComboboxOption>
                                    </ul>
                                </li> -->
                            </ComboboxOptions>

                            <div
                                v-if="rawQuery !== '' && filteredProjects.length === 0"
                                class="px-6 py-14 text-center text-sm sm:px-14"
                            >
                                <ExclamationTriangleIcon
                                    class="mx-auto h-6 w-6 text-gray-400"
                                    aria-hidden="true"
                                />
                                <p class="mt-4 font-semibold text-gray-900">
                                    Niks gevonden
                                </p>
                                <p class="mt-2 text-gray-500">
                                    Er is geen evenement gevonden
                                </p>
                            </div>

                            <!-- <div class="flex flex-wrap items-center bg-gray-50 px-4 py-2.5 text-xs text-gray-700">
                                Type
                                <kbd
                                    :class="[
                                        'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                                        rawQuery.startsWith('#')
                                            ? 'border-indigo-600 text-indigo-600'
                                            : 'border-gray-400 text-gray-900',
                                    ]"
                                    >#</kbd
                                >
                                <span class="sm:hidden">for projects,</span>
                                <span class="hidden sm:inline">to access projects,</span>
                                <kbd
                                    :class="[
                                        'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                                        rawQuery.startsWith('>')
                                            ? 'border-indigo-600 text-indigo-600'
                                            : 'border-gray-400 text-gray-900',
                                    ]"
                                    >&gt;</kbd
                                >
                                for users, and
                                <kbd
                                    :class="[
                                        'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                                        rawQuery === '?'
                                            ? 'border-indigo-600 text-indigo-600'
                                            : 'border-gray-400 text-gray-900',
                                    ]"
                                    >?</kbd
                                >
                                for help.
                            </div> -->
                        </Combobox>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

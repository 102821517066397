<script setup>
import { useSlots } from 'vue';

const props = defineProps({
    label: String,
    form: Object,
    red: Boolean,
    disabled: Boolean,
    is: String,
});

const normal = !props.red;

const slots = useSlots();
</script>
<template>
    <component
        :is="is == 'a' ? 'a' : 'button'"
        :type="is == 'a' ? '' : 'button'"
        class="relative inline-flex h-10 w-10 items-center justify-center rounded-md border border-transparent text-base font-medium shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-offset-1 disabled:bg-gray-300 disabled:text-gray-700"
        :class="{
            'bg-red-700 text-white hover:bg-red-800 focus:ring-red-600': red,
            'bg-pine-500 text-beige-500 hover:bg-pine-600 focus:ring-pine-400': normal,
            'cursor-progress': form?.processing,
            'cursor-not-allowed': form?.recentlySuccessful,
        }"
        :disabled="disabled"
    >
        <slot />
        <!-- <ReactiveSlot :processing="form?.processing ?? false" :recentlySuccessful="form?.recentlySuccessful ?? false">
            <div v-if="!slots.default" class="leading-tight">{{ label }}</div>

            <slot></slot>
        </ReactiveSlot> -->
    </component>
</template>

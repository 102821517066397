import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/nl';

dayjs.extend(isToday);
dayjs.extend(isSameOrAfter)
dayjs.extend(localizedFormat);

export default {
    install: (app, { locale }) => {
        setLocale(locale);
        app.provide('setDayjsLocale', (locale) => setLocale(locale));
        app.provide('dayjs', dayjs);
    },
};

function setLocale(locale) {
    // To prevent the selection of American english (week starts on Sunday)
    if (locale === 'en') {
        dayjs.locale('en-gb');
        return;
    }

    dayjs.locale(locale);
}

<script setup>
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import { useUser } from '@/stores/userStore';
import { mapErrors } from '@/utilities';
import Form from '@/Pages/Skills/Partials/Form.vue';

const route = inject('route');

const props = defineProps({
    query: Object,
});

const form = useForm({
    name: '',
    ...props.query,
});

const submitForm = () => {
    form.post(route('skills.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <AppLayout>
        <Card>
            <template #title>
                Vaardigheid aanmaken
            </template>

            <div class="grid grid-cols-1 gap-2">
                <Form :form="form" />
            </div>

            <template #buttons>
                <Button
                    v-if="useUser().can('create-skill')"
                    label="Opslaan"
                    :form="form"
                    @click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>

<script setup>
import SmallButton from '@/Components/Buttons/SmallButton.vue';
import Label from '@/Components/Input/Label.vue';
import SelectSearch from '@/Components/Input/SelectSearch.vue';
import InputGroup from '@/Components/Input/Group.vue';
import AddressForm from '@/Pages/Events/Partials/AddressForm.vue';

import {UserPlusIcon} from '@heroicons/vue/24/outline';
import {router} from '@inertiajs/vue3';
import {inject} from 'vue';

const dayjs = inject('dayjs');
const route = inject('route');

const props = defineProps({
    form: Object,
    contactOptions: Object,

    templateOptions: Object,
    typeOptions: Object,
    disabled: Boolean,
});

const fillAddress = () => {
    if (props.form.contact_id == '' || props.form.contact_id == null) {
        return;
    }

    window.axios
        .get(
            route('api.address.index', {
                contact_id: props.form.contact_id,
            })
        )
        .then(function (response) {
            props.form.errors.contact_id = [];
            if (response.data.data.length == 0) {
                props.form.postcode = '';
                props.form.street_number = '';
                props.form.street_name = '';
                props.form.city = '';
                props.form.country = '';
                props.form.longitude = '';
                props.form.latitude = '';
                props.form.errors.contact_id = ['Geen adres bekent'];
                return;
            }
            let address = response.data.data[0];
            props.form.postcode = address.postcode;
            props.form.street_number = address.street_number;
            props.form.street_name = address.street_name;
            props.form.city = address.city;
            props.form.country = 'NL';
            props.form.longitude = address.longitude;
            props.form.latitude = address.latitude;
            props.form.errors.postcode = [];
        });
};

function updateOtherDates() {
    props.form.assembly_date = dayjs(props.form.start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
    props.form.disassembly_date = dayjs(props.form.start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
    props.form.end = dayjs(props.form.start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
}

const helperLine = '<span class=\'underline cursor-pointer\'>Adres overnemen</span>';
</script>
<template>
    <InputGroup>
        <div class="flex w-full gap-4">
            <SelectSearch
                v-model="form.contact_id"
                :disabled="disabled"
                :errors="form.errors?.contact_id"
                :help="disabled ? '' : helperLine"
                :options="contactOptions"
                label="Klant"
                @help-clicked="fillAddress"
            />

            <div
                v-if="!disabled"
                class="flex flex-col"
            >
                <Label label="&nbsp;" />
                <SmallButton
                    :icon="UserPlusIcon"
                    @click="
                        router.get(
                            route('contacts.create', {
                                intended: route('quotations.create', { contact_id: '{created_id}' }),
                            })
                        )
                    "
                >
                    <UserPlusIcon
                        aria-hidden="true"
                        class="size-5 text-beige-500"
                    />
                </SmallButton>
            </div>
        </div>
    </InputGroup>

    <AddressForm
        :disabled="disabled"
        :form="form"
    />

    <InputGroup>
        <FormKit
            v-model="form.type"
            :disabled="disabled"
            :errors="form.errors?.type"
            :options="typeOptions"
            :value="form.type"
            help="Regulier of Go Ahead Eagles"
            label="Offerte type"
            type="select"
            validate="required"
        />
    </InputGroup>
    <InputGroup>
        <FormKit
            v-model="form.adults"
            :disabled="disabled"
            :errors="form.errors?.adults"
            :value="form.adults"
            label="Aantal volwassenen"
            placeholder="Nader te bepalen"
            type="text"
        />
        <FormKit
            v-model="form.children"
            :disabled="disabled"
            :errors="form.errors?.children"
            :value="form.children"
            label="Aantal kinderen"
            placeholder="Nader te bepalen"
            type="text"
        />
    </InputGroup>

    <InputGroup>
        <FormKit
            v-model="form.date"
            :disabled="disabled"
            :errors="form.errors?.date"
            :value="form.date"
            label="Offerte datum"
            type="date"
        />

        <FormKit
            v-model="form.number"
            :disabled="disabled"
            :errors="form.errors?.number"
            :value="form.number"
            label="Offertenummer"
            type="text"
        />

        <FormKit
            v-model="form.include_vat"
            :disabled="disabled"
            :errors="form.errors?.include_vat"
            :options="{0: 'Exclusief', 1: 'Inclusief'}"
            :value="form.include_vat"
            label="BTW"
            type="select"
        />
    </InputGroup>

    <InputGroup>
        <FormKit
            v-model="form.start"
            :disabled="disabled"
            :errors="form.errors?.start"
            :value="form.start"
            label="Start"
            type="datetime-local"
            @blur="updateOtherDates"
        />
        <FormKit
            v-model="form.end"
            :disabled="disabled"
            :errors="form.errors?.end"
            :value="form.end"
            label="Eind"
            type="datetime-local"
        />
    </InputGroup>
    <InputGroup>
        <FormKit
            v-model="form.assembly_date"
            :disabled="disabled"
            :errors="form.errors?.assembly_date"
            :value="form.assembly_date"
            label="Opbouwdatum"
            type="date"
        />
        <FormKit
            v-model="form.disassembly_date"
            :disabled="disabled"
            :errors="form.errors?.disassembly_date"
            :value="form.disassembly_date"
            label="Afbouwdatum"
            type="date"
        />
        <FormKit
            v-model="form.delivery_date"
            :disabled="disabled"
            :errors="form.errors?.delivery_date"
            label="Leverdatum"
            type="datetime-local"
        />
    </InputGroup>

    <FormKit
        v-model="form.description"
        :disabled="disabled"
        :errors="form.errors?.description"
        :value="form.description"
        label="Omschrijving"
        type="textarea"
    />
    <FormKit
        v-model="form.decoration"
        :disabled="disabled"
        :errors="form.errors?.decoration"
        :value="form.decoration"
        label="Aankleding"
        type="textarea"
    />
</template>

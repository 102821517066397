<script setup>
/**
 * A form button that shows a conformation modal before submitting.
 */
import { ref } from 'vue';
import ConformationModal from '@/Components/Modal/ConformationModal.vue';
import Button from '@/Components/Buttons/Button.vue';
import FormButton from '@/Components/Buttons/FormButton.vue';

const props = defineProps({
    label: String,
    disabled: Boolean,
    red: Boolean,
    method: String,
    url: String,
    conformationTitle: String,
    conformationMessage: String,
});

const show = ref(false);
</script>
<template>
    <Button
        :label="label"
        :red="red || method == 'delete'"
        :disabled="disabled"
        @click.stop.prevent="show = true"
    />
    <ConformationModal
        v-model="show"
        :title="conformationTitle"
        :message="conformationMessage"
    >
        <FormButton
            :label="label"
            :method="method"
            :url="url"
        />
    </ConformationModal>
</template>

<script setup>
/**
 * This is a component that can be used to group form inputs as input groups.
 * Provides spacing between the inputs.
 */
import {useSlots} from 'vue';
import Help from "@/Components/Input/Help.vue";

const props = defineProps({cols: [String, Number], help: String});
const width = props.cols ?? useSlots()?.default()?.length ?? 1
</script>

<template>
    <div v-if="width > 0">
        <div
            :style="`grid-template-columns: repeat(${width}, minmax(0, 1fr));`"
            class="flex flex-col gap-y-2 md:grid md:gap-4 md:gap-y-0"
        >
            <slot />
        </div>
        <Help :help="help" />
    </div>
</template>

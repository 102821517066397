<script setup>
import theme from '@/formkitTheme';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/vue/20/solid';

import { computed, ref } from 'vue';

const props = defineProps({
    label: String,
    options: {},
    modelValue: {},
    placeholder: String,
    clearable: Boolean,
    firstSelected: Boolean,
});

const emits = defineEmits(['update:modelValue', 'change']);

const localeOptions = computed(() => {
    if (Array.isArray(props.options)) {
        return props.options.map((option) => {
            return {
                label: option.label ?? option.name ?? option.title,
                value: option.value ?? option.id,
            };
        });
    }

    let ans = [];
    for (const [key, value] of Object.entries(props.options)) {
        ans.push({
            label: value,
            value: key,
        });
    }
    return ans;
});

const selected = ref(localeOptions.value.find((option) => option.value == props.modelValue));

if (props.firstSelected) {
    selected.value = localeOptions.value[0];
    emitValue();
}

function emitValue() {
    emits('update:modelValue', selected.value?.value);
    emits('change');
}
</script>

<template>
    <Listbox
        v-model="selected"
        as="div"
        :class="theme.global.outer"
        @update:model-value="emitValue()"
    >
        <ListboxLabel :class="theme.global.label">
            {{ label }}
        </ListboxLabel>
        <div class="relative mt-1">
            <ListboxButton
                :class="theme.global.input"
                class="py-2 pl-3 pr-10"
            >
                <span
                    v-if="selected"
                    class="block truncate"
                >{{ selected.label }}</span>
                <span
                    v-else
                    class="block truncate"
                >{{ placeholder }}</span>

                <span
                    v-if="clearable && selected"
                    class="z-100 absolute inset-y-0 right-0 flex items-center pr-2"
                    @click.prevent="(selected = undefined), emitValue()"
                >
                    <XMarkIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </span>
                <span
                    v-else
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                >
                    <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </span>
            </ListboxButton>

            <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <ListboxOption
                        v-for="(option, key) in localeOptions"
                        :key="key"
                        v-slot="{ active, selected }"
                        as="template"
                        :value="option"
                    >
                        <li
                            :class="[
                                active ? 'bg-pine-500 text-white' : 'text-gray-900',
                                'relative cursor-pointer select-none py-2 pl-3 pr-9',
                            ]"
                        >
                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                {{ option.label }}
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>

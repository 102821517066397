<script setup>
import theme from '@/formkitTheme';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { ref, watch } from 'vue';

import { colors } from '@/colors';

const props = defineProps({
    options: Array,
    value: Object,
    modelValue: {},
    label: String,
});

const emits = defineEmits(['update:modelValue', 'change', 'helpClicked']);

const selected = ref(props.modelValue ?? 'Selecteer een kleur');

function emitValue(value) {
    selected.value = value;
    emits('update:modelValue', value);
    emits('change');
}

watch(selected, (value) => {
    console.log(value);
    emitValue(value);
});
</script>

<template>
    <Listbox
        v-model="selected"
        as="div"
        :class="theme.global.outer"
        @update:model-value="emitValue"
    >
        <ListboxLabel :class="theme.global.label">
            {{ label }}
        </ListboxLabel>
        <div :class="theme.global.inner">
            <ListboxButton :class="theme.global.input">
                <span class="flex items-center">
                    <span
                        v-if="selected?.startsWith('#')"
                        :style="`background-color: ${selected}`"
                        :class="['inline-block h-4 w-4 shrink-0 rounded-full']"
                        aria-hidden="true"
                    />
                    <span class="ml-3 block truncate">{{ selected }}</span>
                </span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </span>
            </ListboxButton>

            <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <ListboxOption
                        v-for="color in colors"
                        :key="color"
                        v-slot="{ active, selected }"
                        as="template"
                        :value="color"
                    >
                        <li
                            :class="[
                                active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9',
                            ]"
                        >
                            <div class="flex items-center">
                                <span
                                    :style="`background-color: ${color}`"
                                    :class="['inline-block h-4 w-4 shrink-0 rounded-full']"
                                    aria-hidden="true"
                                />
                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
                                    {{ color }}
                                </span>
                            </div>

                            <span
                                v-if="selected"
                                :class="[
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                ]"
                            >
                                <CheckIcon
                                    class="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>

<script setup>
import {useUser} from '@/stores/userStore';
import {ClockIcon, UserIcon} from '@heroicons/vue/24/outline';

import {computed, inject} from 'vue';

const route = inject('route');
const dayjs = inject('dayjs');
const user = useUser();

const props = defineProps({
    event: Object,
});

const userAttendance = computed(() => {
    if (typeof props.event.accepted_attendances == 'undefined') {
        return null;
    }

    const userAttendances = props.event.accepted_attendances.filter((a) => a.user_id == user.id);

    return userAttendances.length == 0 ? null : userAttendances[0];
});

const shouldShowTime = computed(() => {
    return user.can('viewAny-events') || userAttendance.value != null;
});

const start = computed(() => {
    if (user.can('viewAny-events')) {
        return dayjs(props.event.start);
    }

    return userAttendance.value == null ? null : dayjs(userAttendance.value.start);
});

const end = computed(() => {
    if (user.can('viewAny-events')) {
        return dayjs(props.event.end);
    }
    return userAttendance.value == null ? null : dayjs(userAttendance.value.end);
});
</script>
<template>
    <div class="flex flex-col gap-x-4 gap-y-2 p-4 md:flex-row md:items-center md:gap-8">
        <div class="flex items-center gap-4 text-sm text-pine-500">
            <div
                v-if="shouldShowTime"
                class="flex items-center gap-2"
            >
                <ClockIcon class="inline-block size-4" />
                <div v-if="!event.all_day">
                    {{ start.format('HH:mm') }} - {{ end.format('HH:mm') }}
                </div>
            </div>
            <div class="flex items-center gap-2">
                <UserIcon class="inline-block size-4" />
                <div>{{ event.accepted_attendances?.length ?? 0 }}/{{ event.capacity }}</div>
            </div>
        </div>
        <p class="text-sm leading-tight text-pine-500">
            {{ event.title }}
        </p>
    </div>
</template>

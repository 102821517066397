<script setup>
import Errors from '@/Components/Input/Errors.vue';
import Label from '@/Components/Input/Label.vue';
import theme from '@/formkitTheme';
import { computed } from 'vue';

const props = defineProps({
    label: {},
    modelValue: {},
    errors: [],
    disabled: Boolean,
    help: String,
    type: String,
    classes: String,
    options: [Array, Object],
});

const emits = defineEmits(['update:modelValue', 'change', 'helpClicked']);

const displayValue = computed(() => props.modelValue);

const emitValue = (value) => {
    value = value.target.value;

    emits('update:modelValue', value);
    emits('change', value);
};
</script>
<template>
    <div :class="theme.global.outer">
        <Label :label="label" />
        <div :class="theme.global.inner">
            <input
                v-if="type !== 'select'"
                :id="label ?? 'input'"
                :type="type"
                :class="theme.global.input"
                :value="displayValue"
                :options="options"
                :disabled="disabled"
                @blur="emitValue"
                @change="emitValue"
            >

            <select
                v-else
                :id="label ?? 'input'"
                :class="theme.global.input"
                :value="displayValue"
                :disabled="disabled"
                @blur="emitValue"
                @change="emitValue"
            >
                <option
                    v-for="option in options"
                    :key="option"
                    :value="option"
                >
                    {{ option }}
                </option>
            </select>
        </div>
        <button
            v-if="help"
            type="button"
            :class="theme.global.help"
            @click="emits('helpClicked')"
            v-html="help"
        />
        <Errors :error="errors" />
    </div>
</template>

<script setup>
import PageTitle from '@/Components/PageTitle.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import { useUser } from '@/stores/userStore';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
import GaeStaffingLocations from '@/Pages/Settings/Partials/GaeStaffingLocations.vue';
import GaeTemplate from '@/Pages/Settings/Partials/GaeTemplate.vue';
import GaeWhatOptions from '@/Pages/Settings/Partials/GaeWhatOptions.vue';
import GaeWhoOptions from '@/Pages/Settings/Partials/GaeWhoOptions.vue';

const route = inject('route');
const dayjs = inject('dayjs');
const user = useUser();

const props = defineProps({
    settings: Object,
});

const form = useForm({
    schema: props.settings.template,
    whoOptions: props.settings.whoOptions,
    whatOptions: props.settings.whatOptions,
    staffingLocations: props.settings.staffingLocations,
});
</script>

<template>
    <AppLayout>
        <PageTitle title="Instellingen" />
        <GaeTemplate
            :form="form"
            :who-options="settings.whoOptions"
            :what-options="settings.whatOptions"
        />
        <GaeWhoOptions :form="form" />
        <GaeWhatOptions :form="form" />
        <GaeStaffingLocations :form="form" />
    </AppLayout>
</template>

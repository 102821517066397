<script setup>
import Group from '@/Components/Input/Group.vue';
import {useUser} from '@/stores/userStore';
import {computed} from 'vue';

defineProps({
    form: Object,
    typeOptions: Object,
});

const permission = computed(() => useUser().can('update-contacts'));
</script>
<template>
    <Group cols="2">
        <FormKit
            v-model="form.first_name"
            :disabled="!permission"
            :errors="form.errors?.first_name"
            :value="form.first_name"
            label="Voornaam"
            type="text"
            validate="required"
        />
        <FormKit
            v-model="form.last_name"
            :disabled="!permission"
            :errors="form.errors?.last_name"
            :value="form.last_name"
            label="Achternaam"
            type="text"
        />
    </Group>

    <Group>
        <FormKit
            v-model="form.email_addresses"
            :disabled="!permission"
            :errors="form.errors?.email_addresses"
            help="Kommagescheiden lijst van email adressen"
            label="Email addressen"
            type="email"
        />

        <FormKit
            v-model="form.phone_number"
            :disabled="!permission"
            :errors="form.errors?.phone_number"
            :value="form.phone_number"
            label="Telefoon"
            type="text"
        />
    </Group>
</template>

<script setup>
import PageTitle from '@/Components/PageTitle.vue';
import Select from '@/Components/Select.vue';
import Table from '@/Components/Table/Table.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useUser} from '@/stores/userStore';
import {capitalizeFirstLetter} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {computed, inject} from 'vue';
import Group from "@/Components/Input/Group.vue";

const route = inject('route');
const user = useUser();
const dayjs = inject('dayjs');

const props = defineProps({
    query: Object,
    time_registrations: Object,
    month: String,
    dateOptions: Object,
    userOptions: Object,
});

const form = useForm({
    orderBy: props.query.orderBy ?? 'name',
    orderDir: props.query.orderDir ?? 'desc',
    user_id: props.query.user_id ?? '',
    month: props.query.month ?? '',
    date: props.query.date ?? '',
    page: props.query.page ?? 1,
});

const getData = (page = 1) => {
    form.page = page;

    form.get(route('time-registrations.overviews.month'), {
        preserveState: true,
    });
};

const tableSettings = computed(() => [
    {
        label: 'Naam',
        orderBy: 'name',
        width: '15%',
    },
    {
        label: 'Start',
        width: '10%',
    }, {
        label: 'Eind',
        width: '10%',
    }, {
        label: 'Datum',
        orderBy: 'date',
        width: '10%',
    },
]);

const tableData = computed(() =>
    props.time_registrations.map((reg) => {
        return {
            id: reg.id,
            rowData: [reg.user.name, reg.start_time, reg.end_time, dayjs(reg.date).format('dddd, DD-MM-YYYY')],
        };
    })
);

const monthOptions = computed(() => {
    return props.dateOptions.map((date) => {
        return {
            value: date,
            label: capitalizeFirstLetter(dayjs(date).format('MMMM YYYY')),
        };
    });
});

const monthName = computed(() => {
    return dayjs(form.month).format('MMMM YYYY');
})
</script>

<template>
    <AppLayout>
        <PageTitle :title="`Maand overzicht: ` + monthName" />
        <Table
            v-model:orderBy="form.orderBy"
            v-model:orderDir="form.orderDir"
            :loading="form.processing"
            :table-data="tableData"
            :table-settings="tableSettings"
            @update:order-by="getData()"
            @update:order-dir="getData()"
        >
            <template #filters>
                <Group>
                    <Select
                        v-model="form.month"
                        :options="monthOptions"
                        class="mb-3"
                        clearable
                        label="Maand"
                        placeholder="Selecteer een maand..."
                        @change="getData()"
                    />
                    <Select
                        v-model="form.user_id"
                        :options="userOptions"
                        class="mb-3"
                        clearable
                        label="Gebruiker"
                        placeholder="Selecteer een gebruiker..."
                        @change="getData()"
                    />
                    <FormKit
                        v-model="form.date"
                        class="mb-3"
                        label="Datum"
                        type="date"
                        @update:model-value="getData()"
                    />
                </Group>
            </template>
        </Table>
    </AppLayout>
</template>

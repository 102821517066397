<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card.vue';
import Password from '@/Components/Input/Password.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import { mapErrors } from '@/utilities';
import { Head, Link, useForm, usePage } from '@inertiajs/vue3';

import { inject } from 'vue';
const route = inject('route');

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submitForm = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
const login = () => {
    form.transform((data) => ({ ...data, email: 'dev@strixi.nl' })).post('/laravel-login-link-login', {
        onFinish: () => form.reset('password'),
    });
};
const loginCook = () => {
    form.transform((data) => ({ ...data, email: 'cook@strixi.nl' })).post('/laravel-login-link-login', {
        onFinish: () => form.reset('password'),
    });
};
const loginPayroll = () => {
    form.transform((data) => ({ ...data, email: 'payroll@strixi.nl' })).post('/laravel-login-link-login', {
        onFinish: () => form.reset('password'),
    });
};
const loginStandaard = () => {
    form.transform((data) => ({ ...data, email: 'default@strixi.nl' })).post('/laravel-login-link-login', {
        onFinish: () => form.reset('password'),
    });
};
const loginBackoffice = () => {
    form.transform((data) => ({ ...data, email: 'backoffice@strixi.nl' })).post('/laravel-login-link-login', {
        onFinish: () => form.reset('password'),
    });
};
const loginLogistics = () => {
    form.transform((data) => ({ ...data, email: 'logistics@strixi.nl' })).post('/laravel-login-link-login', {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <GuestLayout>
        <Head title="Log in" />
        <Card narrow>
            <div
                v-if="status"
                class="mb-4 text-sm font-medium text-green-600"
            >
                {{ status }}
            </div>

            <form @submit.prevent="submit">
                <div>
                    <FormKit
                        v-model="form.email"
                        type="email"
                        label="Email"
                        :errors="form.errors?.email"
                        :value="form.email"
                    />

                    <Password
                        v-model="form.password"
                        label="Wachtwoord"
                        :errors="form.errors?.password"
                        :value="form.password"
                    />

                    <FormKit
                        v-model="form.remember"
                        type="checkbox"
                        label="Onthoud mij"
                        :errors="form.errors?.remember"
                        :value="form.remember"
                    />

                    <div class="mt-4 flex items-center justify-end gap-4">
                        <Link
                            :href="route('password.request')"
                            class="rounded-md text-sm text-gray-600 underline hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Wachtwoord vergeten?
                        </Link>

                        <Button
                            label="Inloggen"
                            :form="form"
                            @click="submitForm"
                        />
                    </div>
                    <div
                        v-if="usePage().props.app?.debug === true"
                        class="mt-10 flex flex-col gap-3"
                    >
                        <Button
                            label="Login Admin"
                            @click="login"
                        />
                        <Button
                            label="Login Backoffice"
                            @click="loginBackoffice"
                        />
                        <Button
                            label="Login Logistics"
                            @click="loginLogistics"
                        />
                        <Button
                            label="Login cook"
                            @click="loginCook"
                        />
                        <Button
                            label="Login standaard"
                            @click="loginStandaard"
                        />
                        <Button
                            label="Login payroll"
                            @click="loginPayroll"
                        />
                    </div>
                </div>
            </form>
        </Card>
    </GuestLayout>
</template>

<script setup>
import GenericInput from '@/Components/Input/GenericInput.vue';
import InputGroup from '@/Components/Input/Group.vue';
import {ref} from 'vue';

let props = defineProps({
    form: Object,
    areaOptions: [Array, Object],
    roleOptions: [Array, Object],
    statusOptions: Object,
});

const inputType = ref('select');
const roleInputType = ref('select');

function toggleInput() {
    inputType.value = inputType.value == 'text' ? 'select' : 'text';
}

function toggleRoleInput() {
    roleInputType.value = roleInputType.value == 'text' ? 'select' : 'text';
}
</script>
<template>
    <InputGroup>
        <FormKit
            v-model="form.start"
            :errors="form.errors?.start"
            :value="form.start"
            label="Start"
            type="text"
        />

        <FormKit
            v-model="form.end"
            :errors="form.errors?.end"
            :value="form.end"
            label="Eind"
            type="text"
        />

        <GenericInput
            v-model="form.area"
            label="Werkplek"
            :type="inputType"
            :help="inputType == 'select' ? 'Vrije invoer' : 'Selectie'"
            :options="areaOptions"
            @helpClicked="toggleInput()"
        />

        <GenericInput
            v-model="form.role"
            label="Rol"
            :type="roleInputType"
            :help="roleInputType == 'select' ? 'Vrije invoer' : 'Selectie'"
            :options="roleOptions"
            @helpClicked="toggleRoleInput()"
        />
    </InputGroup>
</template>

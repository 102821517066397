<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card.vue';
import SelectMultiple from '@/Components/Input/SelectMultiple.vue';
import InputGroup from '@/Components/Input/Group.vue';
import {mapErrors} from '@/utilities';
import {useForm, usePage} from '@inertiajs/vue3';
import {inject} from 'vue';

const route = inject('route');
const props = defineProps({
    mustVerifyEmail: Boolean,
    status: String,
    drivingLicenseOptions: Object,
});

const user = usePage().props.auth.user;

const form = useForm({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone_number: user.phone_number,
    driving_licenses: user.driving_licenses,
});

const submitForm = () => {
    form.patch(route('profile.update'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <Card>
        <InputGroup>
            <FormKit
                v-model="form.first_name"
                type="text"
                label="Voornaam"
                :errors="form.errors?.first_name"
                :value="form.first_name"
                validate="required"
            />
            <FormKit
                v-model="form.last_name"
                type="text"
                label="Achternaam"
                :errors="form.errors?.last_name"
                :value="form.last_name"
            />
        </InputGroup>
        <InputGroup>
            <FormKit
                v-model="form.email"
                type="email"
                label="Email"
                :errors="form.errors?.email"
                :value="form.email"
            />

            <FormKit
                v-model="form.phone_number"
                type="text"
                label="Telefoon"
                :errors="form.errors?.phone_number"
                :value="form.phone_number"
            />
        </InputGroup>

        <SelectMultiple
            v-model="form.driving_licenses"
            label="Rijbewijzen"
            :options="drivingLicenseOptions"
            :errors="form.errors?.driving_licenses"
            placeholder="Geen rijbewijs"
        />

        <template #buttons>
            <Button
                label="Aanpassen"
                :form="form"
                @click="submitForm"
            />
        </template>
    </Card>
</template>

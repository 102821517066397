<script setup>
import FormButton from '@/Components/Buttons/FormButton.vue';
import CardInset from '@/Components/CardInset.vue';
import Divider from '@/Components/Divider.vue';
import Number from '@/Components/Input/Number.vue';
import { computed } from 'vue';

const props = defineProps({
    category: Object,
    quotationLines: Object,
});
const productIds = computed(() => {
    return props.category.products.map((product) => product.id);
});

const linesInCategory = computed(() => {
    return props.quotationLines.filter((line) => productIds.value.includes(line.product_id));
});
</script>
<template>
    <Divider :title="category.title" />

    <CardInset>
        <template
            v-for="line in linesInCategory"
            :key="line.id"
        >
            <div class="grid grid-cols-12 gap-4">
                <div class="mt-6 w-10">
                    <FormButton
                        v-if="line.status == 'created'"
                        :full-width="true"
                        label="+"
                        method="put"
                        :url="route('quotation-lines.update',{ quotation_line: line.id, })"
                        :data="{ status: 'packed' }"
                    />
                    <FormButton
                        v-else
                        :full-width="true"
                        label="X"
                        red
                        method="put"
                        :url="route('quotation-lines.update',{ quotation_line: line.id, })"
                        :data="{ status: 'created' }"
                    />
                </div>
    
                <FormKit
                    v-model="line.description"
                    :classes="{ outer: 'col-span-8 lg:col-span-9' }"
                    type="text"
                    name="country"
                    label="Omschrijving"
                    :disabled="true"
                />

                <Number
                    v-model="line.quantity"
                    class="col-span-3 lg:col-span-2"
                    label="Aantal"
                    :disabled="true"
                />
            </div>
        </template>
    </CardInset>
</template>

<script setup>
import { computed, useSlots } from 'vue';
import ReactiveSlot from '@/Components/Buttons/ReactiveSlot.vue';

const props = defineProps({
    label: String,
    form: Object,

    is: String,
    disabled: Boolean,

    red: Boolean,
    transparent: Boolean,

    removeRounding: Boolean,
    classes: String,
    fullWidth: Boolean,
});

const normal = !props.red && !props.transparent;

const slots = useSlots();

const hasError = computed(() => {
    return typeof props.form?.errors == 'undefined'
        ? false
        : Object.values(props.form.errors).some((error) => error.length > 0);
});
</script>
<template>
    <component
        :is="is == 'a' ? 'a' : 'button'"
        :type="is == 'a' ? '' : 'button'"
        class="relative inline-flex h-10 w-full items-center border border-transparent px-4 py-2 text-sm shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-offset-1 disabled:bg-gray-300 disabled:text-gray-700"
        :class="[
            {
                'bg-red-700 text-white hover:bg-red-800 focus:ring-red-600': red || hasError,
                'bg-pine-800 text-white hover:bg-pine-600 focus:ring-pine-500': normal && !hasError,
                'border-gray-300 bg-white text-gray-700': transparent && !hasError,
                'cursor-progress': form?.processing,
                'cursor-not-allowed': form?.recentlySuccessful,
                'rounded-md': !removeRounding,
                'md:w-64': !fullWidth,
            },
            classes,
        ]"
        :disabled="disabled"
    >
        <ReactiveSlot
            :processing="form?.processing ?? false"
            :recently-successful="form?.recentlySuccessful ?? false"
        >
            <div
                v-if="!slots.default"
                class="text-center leading-tight"
            >
                {{ label }}
            </div>

            <slot />
        </ReactiveSlot>
    </component>
</template>

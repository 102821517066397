<script setup>
import FormButton from "@/Components/Buttons/FormButton.vue";
import Card from '@/Components/Card.vue';
import Table from "@/Components/Table/Table.vue";
import {useUser} from '@/stores/userStore';
import {capitalFirstLetter} from "@/utilities";
import {useForm, usePage} from "@inertiajs/vue3";
import {computed, inject} from 'vue';

const route = inject('route');
const dayjs = inject('dayjs');
const user = useUser();

const props = defineProps({
    query: Object,
    timeRegistrations: Object,
    timeRegistrationOptions: Object,
    selectedEventId: [Number, String],
});

const form = useForm({
    orderBy: props.query.orderBy ?? 'name',
    orderDir: props.query.orderDir ?? 'desc',
    page: props.query.page ?? 1,
    user_id: props.query.user_id ?? '',
    event_id: props.query.event_id ?? '',
    status: props.query.status ?? '',
});

const tableSettings = computed(() => [
    {
        label: 'Naam',
        width: '15%',
        combinesWith: {
            label: 'Start',
            till: 'md',
        },
    },
    {
        label: 'Start',

        width: '10%',
        from: 'md',
    },
    {label: 'Einde', width: '10%', from: 'xl'},
    {label: 'Pauze', width: '10%', from: 'md'},
    {label: 'Status', width: '10%'},
    {
        label: 'Acties',
        width: '15%',
        cellType: 'formButton',
        permission: user.canAny(['approve-time-registrations', 'reject-time-registrations']),
        from: 'md',
    },
    {
        width: '1%',
        cellType: 'arrow',
        permission: user.canAny(['update-time-registrations', 'update-own-time-registrations']),
    },
]);
const tableData = computed(() =>
    props.timeRegistrations.data.map((registration) => {
        return {
            id: registration.id,
            rowData: [
                registration.user_name,
                capitalFirstLetter(dayjs(registration.start).format('dddd, DD-MM-YYYY HH:mm')),
                capitalFirstLetter(dayjs(registration.end).format('dddd, DD-MM-YYYY HH:mm')),
                registration.break,
                props.timeRegistrationOptions[registration.status],
                getButton(registration),
                route('time-registrations.edit', {
                    time_registration: registration.id,
                    intended: usePage().props.route.current
                }),
            ],
        };
    })
);

function getButton(registration) {
    let buttons = [];

    if (registration.status == 'submitted' || registration.status == 'revised') {
        if (user.can('approve-time-registrations')) {
            buttons.push({
                label: 'Accorderen',
                method: 'post',
                url: route('approved-time-registrations.store'),
                data: {time_registration_id: registration.id},
            });
        }
        if (user.can('reject-time-registrations')) {
            buttons.push({
                label: 'Afwijzen',
                method: 'post',
                url: route('rejected-time-registrations.store', registration.id),
                red: true,
                data: {time_registration_id: registration.id},
            });
        }
    }

    return buttons;
}

</script>
<template>
    <Card
        collapsable
        remove-padding
    >
        <template #title>
            Uren registratie
        </template>
        <template #buttons>
            <FormButton
                :url="route('time-registrations.create',{event_id:selectedEventId, intended:usePage().props.route.current})"
                label="Uren registreren"
            />
        </template>

        <Table
            v-model:orderBy="form.orderBy"
            v-model:orderDir="form.orderDir"
            :loading="form.processing"
            :table-data="tableData"
            :table-settings="tableSettings"
            remove-spacer
        />
    </Card>
</template>

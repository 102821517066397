<script setup>
import {ChevronDownIcon} from '@heroicons/vue/20/solid';
import {computed} from 'vue';

const props = defineProps({
    tableSettings: Array,
    orderBy: String,
    orderDir: String,
});

const emit = defineEmits(['update:orderBy', 'update:orderDir']);

const emitUpdates = (header) => {
    if (header.orderBy === props.orderBy) {
        emit('update:orderDir', props.orderDir === 'asc' ? 'desc' : 'asc');
    } else {
        emit('update:orderDir', 'asc');
        emit('update:orderBy', header.orderBy);
    }
};

const filteredTableSettings = computed(() =>
    props.tableSettings.filter(
        (columnSetting) => typeof columnSetting.permission === 'undefined' || columnSetting.permission === true
    )
);
</script>

<template>
    <thead class="bg-beige-100">
        <tr>
            <th
                v-for="(header, i) in filteredTableSettings"
                :key="header.label"
                :class="{
                    'pl-4 sm:pl-6': i === 0,
                    'pr-4 sm:pr-6': i === filteredTableSettings.length - 1,
                    'hidden md:table-cell': filteredTableSettings[i]?.from === 'md',
                    'hidden lg:table-cell': filteredTableSettings[i]?.from === 'lg',
                    'hidden xl:table-cell': filteredTableSettings[i]?.from === 'xl',
                    'hidden 2xl:table-cell': filteredTableSettings[i]?.from === '2xl',
                    'hidden 3xl:table-cell': filteredTableSettings[i]?.from === '3xl',
                    'md:hidden': filteredTableSettings[i]?.till === 'md',
                    'lg:hidden': filteredTableSettings[i]?.till === 'lg',
                    'xl:hidden': filteredTableSettings[i]?.till === 'xl',
                    '2xl:hidden': filteredTableSettings[i]?.till === '2xl',
                    '3xl:hidden': filteredTableSettings[i]?.till === '3xl',
                }"
                :style="{ width: header.width }"
                class="px-3 py-3.5 text-left text-sm font-semibold text-pine-500"
                scope="col"
            >
                <template v-if="!header.orderBy">
                    <div
                        :class="{
                            'hidden md:inline-flex': filteredTableSettings[i]?.header?.from === 'md',
                            'hidden lg:inline-flex': filteredTableSettings[i]?.header?.from === 'lg',
                            'hidden xl:inline-flex': filteredTableSettings[i]?.header?.from === 'xl',
                            'hidden 2xl:inline-flex': filteredTableSettings[i]?.header?.from === '2xl',
                            'hidden 3xl:inline-flex': filteredTableSettings[i]?.header?.from === '3xl',
                            'md:hidden': filteredTableSettings[i]?.header?.till === 'md',
                            'lg:hidden': filteredTableSettings[i]?.header?.till === 'lg',
                            'xl:hidden': filteredTableSettings[i]?.header?.till === 'xl',
                            '2xl:hidden': filteredTableSettings[i]?.header?.till === '2xl',
                            '3xl:hidden': filteredTableSettings[i]?.header?.till === '3xl',
                        }"
                        class="group"
                    >
                        {{ header.label }}
                    </div>
                </template>
                <template v-else>
                    <button
                        class="group inline-flex"
                        @click="emitUpdates(header)"
                    >
                        {{ header.label }}
                        <span
                            :class="{
                                'text-gray-900': header.orderBy === orderBy,
                                'rotate-180': header.orderBy === orderBy && orderDir === 'asc',
                            }"
                            class="ml-2 flex-none rounded text-gray-400"
                        >
                            <ChevronDownIcon
                                aria-hidden="true"
                                class="size-5"
                            />
                        </span>
                    </button>
                </template>
            </th>
        </tr>
    </thead>
</template>

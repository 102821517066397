<script setup>
import Divider from '@/Components/Divider.vue';
import { computed } from 'vue';
import Line from '@/Pages/Quotations/Partials/Line.vue';

const props = defineProps({
    form: Object,
    category: Object,
    productOptions: Object,
    vatRateOptions: Object,
});

const emits = defineEmits(['remove']);

function productIsInCategory(product_id, category) {
    const product = props.productOptions.find((prod) => prod.id === product_id);

    if (typeof product?.categories === 'undefined') {
        return false;
    }

    return product.categories.map((cat) => parseInt(cat.id)).includes(parseInt(category));
}

const linesInCategory = computed(() => {
    return props.form.quotation_lines.filter((line) => productIsInCategory(line.product_id, props.category.id));
});
</script>
<template>
    <template v-if="linesInCategory.length > 0">
        <Divider :title="category.title" />
        <template
            v-for="(line, i) in linesInCategory"
            :key="line.id"
        >
            <Line
                :line="line"
                :vat-rate-options="
                    Object.values(vatRateOptions).map((value) => ({ value: value.replace('%', ''), label: value }))
                "
                :participants="form.participants"
                :errors="(form.errors?.quotation_lines ?? [])[i]"
                @delete="() => emits('remove', line.id)"
            />
        </template>
    </template>
</template>

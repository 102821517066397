<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import { Head, useForm, usePage } from '@inertiajs/vue3';

import { inject } from 'vue';
const route = inject('route');

const props = defineProps({
    hasValidSignature: Boolean,
    quotation: Object,
});

const form = useForm({
    id: props.quotation.id,
    url: usePage().props.route.current,
});

function submitForm() {
    form.put(route('accepted-quotations.update', props.quotation.token), {
        preserveScroll: true,
        onSuccess: () => {
            form.reset();
        },
    });
}
</script>

<template>
    <GuestLayout>
        <Head title="Log in" />
        <Card>
            <template v-if="hasValidSignature">
                <template v-if="quotation.status == 'sent'">
                    <div class="prose">
                        <p>{{ $t('Dear') }} {{ quotation.contact.name }},</p>
                        <p>{{ $t('To conform your acceptance of the quotation, please click the button below.') }}</p>

                        <div class="flex justify-end" />
                    </div>
                </template>
                <template v-else>
                    <div class="font-bold">
                        {{ $t('Quotation has been accepted.') }}
                    </div>
                    {{ $t('Thank you for your business.') }}
                </template>
            </template>
            <template v-else>
                <div class="text-center">
                    <div class="font-bold">
                        {{ $t('Please use the link in the email you received.') }}
                    </div>
                    {{ $t('If you no longer have the email, please contact us.') }}
                </div>
            </template>

            <template
                v-if="hasValidSignature && quotation.status == 'sent'"
                #buttons
            >
                <Button
                    :label="$t('Accept {model}', { model: $t('Quotation') })"
                    @click="submitForm"
                />
            </template>
        </Card>
    </GuestLayout>
</template>

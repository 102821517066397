<script setup>
import SmallButton from '@/Components/Buttons/SmallButton.vue';
import GenericInput from '@/Components/Input/GenericInput.vue';
import Label from '@/Components/Input/Label.vue';
import InputGroup from '@/Components/Input/Group.vue';
import {TrashIcon} from '@heroicons/vue/24/outline';

const props = defineProps({
    form: Object,
    whatOptions: Object,
    whoOptions: Object,
    disabled: Boolean,
});

function toggleInput(id) {
    const index = props.form.schema.findIndex((line) => line.id === id);

    if (props.form.schema[index].time.type === 'time') {
        props.form.schema[index].time.type = 'text';
        props.form.schema[index].time.value = 'Na afloop';
    } else {
        props.form.schema[index].time.type = 'time';
        props.form.schema[index].time.value = '';
    }
}

function toggleWhoInput(id) {
    const index = props.form.schema.findIndex((line) => line.id === id);

    if (props.form.schema[index].who.type === 'select') {
        props.form.schema[index].who.type = 'text';
        props.form.schema[index].who.value = '';
    } else {
        props.form.schema[index].who.type = 'select';
        props.form.schema[index].who.value = '';
    }
}

function toggleWhatInput(id) {
    const index = props.form.schema.findIndex((line) => line.id === id);

    if (props.form.schema[index].what.type === 'select') {
        props.form.schema[index].what.type = 'text';
        props.form.schema[index].what.value = '';
    } else {
        props.form.schema[index].what.type = 'select';
        props.form.schema[index].what.value = '';
    }
}

function removeLine(id) {
    const index = props.form.schema.findIndex((line) => line.id === id);
    props.form.schema.splice(index, 1);
}
</script>

<template>
    <template v-if="disabled">
        <template
            v-for="line in form.schema"
            :key="line.id"
        >
            <InputGroup cols="16">
                <GenericInput
                    v-model="line.time.value"
                    label="Tijd"
                    disabled
                    class="col-span-4"
                />
                <GenericInput
                    v-model="line.number.value"
                    label="Aantal"
                    disabled
                    class="col-span-4"
                />
                <GenericInput
                    v-model="line.who.value"
                    label="Wie"
                    disabled
                    class="col-span-4"
                />
                <GenericInput
                    v-model="line.what.value"
                    label="Wat"
                    disabled
                    class="col-span-4"
                />
            </InputGroup>
        </template>
    </template>
    <template v-else>
        <template
            v-for="line in form.schema"
            :key="line.id"
        >
            <InputGroup cols="17">
                <GenericInput
                    v-model="line.time.value"
                    :type="line.time.type"
                    label="Tijd"
                    :help="line.time.type == 'time' ? 'Na afloop' : 'Tijd invoeren'"
                    class="col-span-4"
                    @helpClicked="toggleInput(line.id)"
                />
                <GenericInput
                    v-model="line.number.value"
                    type="text"
                    label="Aantal"
                    class="col-span-4"
                />
                <GenericInput
                    v-model="line.who.value"
                    label="Wie"
                    :type="line.who.type"
                    :help="line.who.type == 'select' ? 'Vrije invoer' : 'Selectie'"
                    :options="whoOptions"
                    class="col-span-4"
                    @helpClicked="toggleWhoInput(line.id)"
                />
                <GenericInput
                    v-model="line.what.value"
                    label="Wat"
                    :type="line.what.type"
                    :help="line.what.type == 'select' ? 'Vrije invoer' : 'Selectie'"
                    :options="whatOptions"
                    class="col-span-4"
                    @helpClicked="toggleWhatInput(line.id)"
                />
                <div>
                    <Label label="&nbsp;" />
                    <SmallButton
                        red
                        @click="removeLine(line.id)"
                    >
                        <TrashIcon
                            class="h-5 w-5 text-white"
                            aria-hidden="true"
                        />
                    </SmallButton>
                </div>
            </InputGroup>
        </template>
    </template>
</template>

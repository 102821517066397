<script setup>
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import { useUser } from '@/stores/userStore';
import { mapErrors } from '@/utilities';
import Form from '@/Pages/Products/Partials/Form.vue';

const route = inject('route');
const user = useUser();

const props = defineProps({
    categoryOptions: Object,
    vatRateOptions: Object,
});

const form = useForm({
    title: '',
    description: '',
    subtitle: '',
    quotation_description: '',
    price: '',
    price_variant: '',
    vat_rate: null,
    scaling_factor: 100,
    category_ids: [],
    ...props.query,
});

const submitForm = () => {
    form.transform((data) => ({
        ...data,
    })).post(route('products.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <AppLayout>
        <div class="sm:px-6 md:px-8">
            <Card>
                <template #title>
                    Product aanmaken
                </template>

                <div class="grid grid-cols-1 gap-2">
                    <Form
                        :category-options="categoryOptions"
                        :form="form"
                        :vat-rate-options="vatRateOptions"
                    />
                </div>

                <template #buttons>
                    <Button
                        v-if="user.can('create-product')"
                        :form="form"
                        label="Opslaan"
                        @click="submitForm"
                    />
                </template>
            </Card>
        </div>
    </AppLayout>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { PlusIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { v4 } from 'uuid';
import { ref } from 'vue';
import Button from '@/Components/Buttons/Button.vue';
import SmallButton from '@/Components/Buttons/SmallButton.vue';
import GenericInput from '@/Components/Input/GenericInput.vue';
import Label from '@/Components/Input/Label.vue';

const props = defineProps({
    location: Object,
    role: Object,
    staffing: Object,
    acceptedAttendants: Array,
    modelValue: Boolean,
});

const emits = defineEmits(['confirm', 'update:modelValue']);

function close() {
    emits('update:modelValue', false);
}

const input = ref('');
const type = ref('select');

function toggleType() {
    type.value = type.value === 'select' ? 'free' : 'select';
}

function addEmployee() {
    props.role.employees.push({ id: v4(), name: input.value });
    input.value = '';
}

function removeEmployee(index) {
    const i = props.role.employees.findIndex((employee) => employee.id === index);
    props.role.employees.splice(i, 1);
}
</script>

<template>
    <TransitionRoot
        as="template"
        :show="modelValue"
    >
        <Dialog
            as="div"
            class="relative z-10"
            @close="close()"
        >
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="relative w-full overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl"
                        >
                            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                {{ location.name }} - {{ role.name }}

                                <ul class="py-4">
                                    <li
                                        v-for="employee in role.employees"
                                        :key="employee.id"
                                        class="flex justify-between py-1"
                                    >
                                        <div class="">
                                            {{ employee.name }}
                                        </div>
                                        <SmallButton
                                            red
                                            @click="removeEmployee(employee.id)"
                                        >
                                            <TrashIcon
                                                class="h-5 w-5 cursor-pointer text-white"
                                                aria-hidden="true"
                                            />
                                        </SmallButton>
                                    </li>
                                    <span v-if="role.employees.length == 0"> Geen </span>
                                </ul>

                                <div class="flex gap-4">
                                    <GenericInput
                                        v-model="input"
                                        label="Wie"
                                        :type="type"
                                        class="col-span-4"
                                        placeholder="Naam"
                                        :options="acceptedAttendants"
                                        :help="type == 'select' ? 'Vrije invoer' : 'Selectie'"
                                        @helpClicked="toggleType()"
                                    />

                                    <div>
                                        <Label label="&nbsp;" />
                                        <SmallButton @click="addEmployee()">
                                            <PlusIcon
                                                class="h-5 w-5 text-white"
                                                aria-hidden="true"
                                            />
                                        </SmallButton>
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-end gap-4 bg-beige-50 p-4 sm:gap-6 sm:rounded-b-lg sm:px-6">
                                <Button
                                    label="Sluiten"
                                    @click="close()"
                                />
                                <slot />
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';

import Button from '@/Components/Buttons/Button.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import Form from '@/Pages/Users/Partials/Form.vue';

import ConformationButton from '@/Components/Buttons/ConformationButton.vue';
import Card from '@/Components/Card.vue';
import {useUser} from '@/stores/userStore';

const route = inject('route');
const currentUser = useUser();

const props = defineProps({
    user: Object,
    roleOptions: Object,
    drivingLicenseOptions: Object,
    skillOptions: Object,
    ownerOptions: Object,
});

const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    role: '',
    driving_licenses: [],
    approved: false,
    favoriet: false,
    permanent_staff_member: false,
    skills: [],
    color_code: null,
    ...props.user,
});

const submitForm = () => {
    form.put(route('users.update', props.user.id), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <AppLayout>
        <div class="h-4" />
        <Card>
            <template #title>
                Gebruiker aanpassen
            </template>
            <FormKit
                id="signup-form"
                :actions="false"
                type="form"
                @submit="submitForm"
            >
                <div class="grid grid-cols-1 gap-2">
                    {{ form.color_code }}
                    <Form
                        :driving-license-options="drivingLicenseOptions"
                        :form="form"
                        :role-options="roleOptions"
                        :skill-options="skillOptions"
                    />
                </div>
            </FormKit>
            <template #buttons>
                <Button
                    v-if="currentUser.can('update-users')"
                    :form="form"
                    label="Aanpassen"
                    @click="submitForm"
                />

                <ConformationButton
                    v-if="currentUser.can('delete-users')"
                    :url="route('users.destroy', user.id)"
                    conformation-message="Weet je zeker dat je deze gebruiker wilt verwijderen?"
                    conformation-title="Gebruiker verwijderen"
                    label="Verwijderen"
                    method="delete"
                />
            </template>
        </Card>
    </AppLayout>
</template>

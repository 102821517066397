<script setup>
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {computed, inject} from 'vue';

import Button from '@/Components/Buttons/Button.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import CompanyForm from '@/Pages/Contacts/Partials/CompanyForm.vue';
import ConsumerForm from '@/Pages/Contacts/Partials/ConsumerForm.vue';

import AddressForm from '@/Components/Address/Form.vue';
import Card from '@/Components/Card.vue';
import Divider from '@/Components/Divider.vue';
import SelectSearch from '@/Components/Input/SelectSearch.vue';
import {useUser} from '@/stores/userStore';

const route = inject('route');

const props = defineProps({
    query: Object,
    typeOptions: Object,
    companyOptions: Object,
});

const form = useForm({
    type: Object.keys(props.typeOptions)[0],
    company_name: '',
    company_email: '',
    company_phone_number: '',
    kvk_number: '',
    vat_number: '',

    company_id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    postcode: '',
    street_number: '',
    street_number_addition: '',
    street_name: '',
    city: '',
    country: '',
    longitude: '',
    latitude: '',
    ...props.query,
});

const submitForm = () => {
    form.post(route('contacts.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

const hasIntended = computed(() => Object.keys(props.query).includes('intended'));
</script>

<template>
    <AppLayout>
        <Card>
            <template #title>
                Contact aanmaken
            </template>

            <FormKit
                id="signup-form"
                :actions="false"
                type="form"
                @submit="submitForm"
            >
                <div class="grid grid-cols-1 gap-2">
                    <FormKit
                        v-model="form.type"
                        :errors="form.errors?.type"
                        :options="typeOptions"
                        :value="form.type"
                        label="Type"
                        type="select"
                        validate="required"
                    />

                    <SelectSearch
                        v-if="form.type === 'employee'"
                        v-model="form.company_id"
                        :options="companyOptions"
                        label="Bedrijf"
                    />

                    <CompanyForm
                        v-if="form.type === 'company'"
                        :form="form"
                        :type-options="typeOptions"
                    />

                    <Divider title="Contact persoon" />

                    <ConsumerForm
                        :form="form"
                        :type-options="typeOptions"
                    />

                    <AddressForm
                        v-if="form.type != 'employee'"
                        :form="form"
                        :permission="true"
                    />
                </div>
            </FormKit>

            <template
                v-if="useUser().canAny(['create-contacts'])"
                #buttons
            >
                <Button
                    v-if="useUser().can('create-contacts')"
                    :form="form"
                    :label="hasIntended ? `Opslaan & terug` : `Opslaan`"
                    @click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>

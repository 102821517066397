<script setup>
import Button from '@/Components/Buttons/Button.vue';
import MultiButton from '@/Components/Buttons/MultiButton.vue';
import DayBar from '@/Pages/Events/Partials/DayBar.vue';
import {useUser} from '@/stores/userStore.js';
import {capitalizeFirstLetter, getTimesBetween, mapErrors} from '@/utilities.js';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/vue/24/outline';
import {router, useForm} from '@inertiajs/vue3';
import {useEventBus, useSwipe} from '@vueuse/core';
import {computed, inject, ref} from 'vue';
import PrintModal from "@/Components/Modal/PrintModal.vue";

const user = useUser();

const route = inject('route');

const props = defineProps({
    items: Object,
    selectedDate: Object,
    statussenByDate: Object,
    typeOptions: Object,
});

const bus = useEventBus('command-palette');

const emits = defineEmits(['update:selectedDate', 'nextMonth', 'prevMonth', 'dateMarkingUpdated', 'update:selectedType']);
const displayDate = ref(props.selectedDate);

const dates = computed(() =>
    getTimesBetween(displayDate.value.startOf('month').startOf('week'), displayDate.value.endOf('month').endOf('week'))
);

function nextMonth() {
    displayDate.value = displayDate.value.add(1, 'month');
    emits('nextMonth', displayDate.value);
}

function prevMonth() {
    displayDate.value = displayDate.value.subtract(1, 'month');
    emits('prevMonth', displayDate.value);
}

const calendar = ref(null);
const {lengthX} = useSwipe(calendar, {
    onSwipeEnd() {
        if (lengthX.value < -70) {
            prevMonth();
        }
        if (lengthX.value > 70) {
            nextMonth();
        }
    },
});

const status = computed(() => {
    return props.statussenByDate[props.selectedDate.format('YYYY-MM-DD')];
});

const form = useForm({
    date: props.date,
});

function submitForm(value) {
    if (value === 'main') {
        router.get(route('events.create', {start: props.selectedDate.format('YYYY-MM-DD')}));
    }
    if (value === 'mark-as-full') {
        form.post(route('dates.store', {date: props.selectedDate.format('YYYY-MM-DD'), status: 'full', stay: true}), {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors)),
            onSuccess: () => emits('dateMarkingUpdated', props.selectedDate),
        });
    }
    if (value === 'mark-as-almost-full') {
        form.post(
            route('dates.store', {date: props.selectedDate.format('YYYY-MM-DD'), status: 'almost_full', stay: true}),
            {
                preserveState: true,
                preserveScroll: true,
                onError: (errors) => (form.errors = mapErrors(errors)),
                onSuccess: () => emits('dateMarkingUpdated', props.selectedDate),
            }
        );
    }

    if (value === 'delete') {
        form.delete(route('dates.destroy', {date: props.selectedDate.format('YYYY-MM-DD'), value, stay: true}), {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors)),
            onSuccess: () => emits('dateMarkingUpdated', props.selectedDate),
        });
    }
}


const showPrintModal = ref(false);

function openModal() {
    console.log('openModal');
    showPrintModal.value = true;
}


</script>
<template>
    <div class="relative">
        <div
            ref="calendar"
            class="flex flex-1 flex-col"
        >
            <div class="flex select-none items-center justify-between py-2">
                <ChevronLeftIcon
                    class="ml-2 w-6 cursor-pointer"
                    @click="prevMonth()"
                />

                <div class="py-2 text-base font-semibold text-pine-500">
                    {{ capitalizeFirstLetter(displayDate.format('MMMM YYYY')) }}
                </div>

                <ChevronRightIcon
                    class="mr-2 w-6 cursor-pointer"
                    @click="nextMonth()"
                />
            </div>
            <DayBar />
            <div class="flex bg-gray-200 text-xs leading-6 text-gray-700 shadow lg:flex-auto">
                <div
                    :style="`grid-template-rows: repeat(${dates / 7}, minmax(0, 1fr))`"
                    class="isolate grid w-full grid-cols-7 gap-px"
                >
                    <template
                        v-for="date in dates"
                        :key="date.format()"
                    >
                        <button
                            :class="{
                                'bg-red-600': statussenByDate[date.format('YYYY-MM-DD')] === 'full',
                                'bg-yellow-400': statussenByDate[date.format('YYYY-MM-DD')] === 'almost_full',
                                'bg-white':
                                    statussenByDate[date.format('YYYY-MM-DD')] == null &&
                                    displayDate.isSame(date, 'month') &&
                                    !selectedDate.isSame(date, 'day'),
                                'bg-gray-50 text-pine-400': !displayDate.isSame(date, 'month'),
                                'bg-gray-100': selectedDate.isSame(date, 'day'),
                            }"
                            class="flex h-14 flex-col px-2 py-1 hover:bg-gray-100 focus:z-10"
                            type="button"
                            @click="emits('update:selectedDate', date)"
                        >
                            <div class="flex items-center justify-between gap-1">
                                <time
                                    :class="{
                                        'font-bold text-pine-500': date.isToday(),
                                    }"
                                    :datetime="date.format('D')"
                                    class="text-left"
                                >
                                    {{ date.format('D') }}
                                </time>
                            </div>

                            <div
                                v-if="displayDate.isSame(date, 'month')"
                                class="font-bold"
                            >
                                {{ items[date.format('YYYY-MM-DD')]?.length }}
                            </div>
                        </button>
                    </template>
                </div>
            </div>
            <span class="mt-4 grid gap-4">
                <MultiButton
                    v-if="user.can('create-events')"
                    :form="form"
                    :items="[
                        { label: 'Markeren als vol', value: 'mark-as-full', visible: status !== 'full' },
                        {
                            label: 'Markeren als bijna vol',
                            value: 'mark-as-almost-full',
                            visible: status !== 'almost_full',
                        },
                        { label: 'Markeren verwijderen', value: 'delete', visible: status !== null },
                    ]"
                    :label="`Aanmaken op ` + selectedDate.format('DD-MM')"
                    full-width
                    @button-click="submitForm"
                />

                <Button
                    v-if="user.can('viewAny-events')"
                    full-width
                    label="Zoeken"
                    @click="bus.emit()"
                />

                <FormKit
                    :classes="{input:'$reset relative text-center block w-full h-10 bg-pine-800 text-white rounded-md text-sm px-4 focus:border-pine-500 focus:ring-pine-500  text-left disabled:border-0 disabled:bg-grey-100'}"
                    :options="{null: 'Alle types', ...typeOptions}"
                    placeholder="Alle types"
                    type="select"
                    @change="emits('update:selectedType', $event.target.value)"
                />

                <Button
                    v-if="user.can('viewAny-events')"
                    full-width
                    label="Printen"
                    @click="openModal"
                />

            </span>
        </div>
    </div>
    <PrintModal v-model="showPrintModal" />
</template>

<script setup>
import InputGroup from '@/Components/Input/Group.vue';
import {useUser} from '@/stores/userStore';
import {computed} from 'vue';

defineProps({
    form: Object,
    typeOptions: Object,
});

const permission = computed(() => useUser().can('update-contacts'));
</script>
<template>
    <FormKit
        v-model="form.company_name"
        :disabled="!permission"
        :errors="form.errors?.company_name"
        :value="form.company_name"
        label="Naam"
        type="text"
        validate="required"
    />

    <InputGroup>
        <FormKit
            v-model="form.company_email_addresses"
            :disabled="!permission"
            :errors="form.errors?.company_email_addresses"
            help="Kommagescheiden lijst van email adressen"
            label="Email Addresses"
            type="email"
        />

        <FormKit
            v-model="form.company_phone_number"
            :disabled="!permission"
            :errors="form.errors?.company_phone_number"
            :value="form.company_phone_number"
            label="Telefoon"
            type="text"
        />
    </InputGroup>

    <InputGroup>
        <FormKit
            v-model="form.kvk_number"
            :disabled="!permission"
            :errors="form.errors?.kvk_number"
            :value="form.kvk_number"
            label="KvK nummer"
            type="text"
        />
        <FormKit
            v-model="form.vat_number"
            :disabled="!permission"
            :errors="form.errors?.vat_number"
            :value="form.vat_number"
            label="BTW nummer"
            type="text"
        />
    </InputGroup>
</template>

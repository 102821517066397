<script setup>
import Button from '@/Components/Buttons/Button.vue';
import CardInset from '@/Components/CardInset.vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    modelValue: Boolean,
    exportTypeOptions: Object,
});

const emits = defineEmits(['close', 'update:modelValue']);

function close() {
    emits('update:modelValue', false);
}

const form = useForm({
    start: '',
    end: '',
    type: Object.keys(props.exportTypeOptions)[0],
});

const submit = () => {
    if (form.start === '') {
        form.errors = {
            start: ['Dit veld is verplicht.'],
        };
        return;
    }
    window.open(route('time-registrations.export', { start: form.start, end: form.end ,type:form.type}), '_blank');

    close();
};
</script>

<template>
    <TransitionRoot
        as="template"
        :show="modelValue"
    >
        <Dialog
            as="div"
            class="relative z-10"
            @close="close()"
        >
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="relative w-full overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl"
                        >
                            <div
                                class="items-top flex justify-between border-b border-pine-100 px-4 py-5 sm:rounded-t-lg sm:px-6"
                            >
                                <DialogTitle
                                    as="h3"
                                    class="text-lg font-medium leading-tight"
                                >
                                    Uren exporteren
                                </DialogTitle>
                            </div>

                            <CardInset class="flex-1">
                                <FormKit
                                    v-model="form.start"
                                    name="start"
                                    type="date"
                                    label="Van"
                                    :value="form.start"
                                    :errors="form?.errors.start"
                                />
                                <FormKit
                                    v-model="form.end"
                                    name="start"
                                    type="date"
                                    label="Tot"
                                    :value="form.end"
                                    :errors="form?.errors.end"
                                />
                                <FormKit
                                    v-model="form.type"
                                    name="type"
                                    type="select"
                                    label="Type"
                                    :options="exportTypeOptions"
                                    :value="form.type"
                                    :errors="form?.errors.type"
                                />
                            </CardInset>

                            <div class="flex justify-end gap-4 bg-beige-50 p-4 sm:gap-6 sm:rounded-b-lg sm:px-6">
                                <Button
                                    label="Annuleren"
                                    red
                                    @click="close()"
                                />
                                <Button
                                    :form="form"
                                    @click="submit"
                                >
                                    Exporteren
                                </Button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import {ChevronRightIcon} from '@heroicons/vue/24/outline';

import ItemCardChildLine from '@/Pages/Events/Partials/ItemCardChildLine.vue';
import {Link} from '@inertiajs/vue3';
import {inject} from 'vue';
import OwnersBar from "@/Pages/Events/Index/OwnersBar.vue";
import DetailsBar from "@/Pages/Events/Index/DetailsBar.vue";

const route = inject('route');

const props = defineProps({
    event: Object,
    date: String,
});


</script>
<template>
    <div
        :id="event.id"
        class="min-w-0 rounded-lg bg-beige-100 shadow-pine-300 drop-shadow-md"
        style="transform: translateZ(0)"
    >
        <div
            :class="{ 'rounded-b-lg': event.children.length === 0 }"
            class="items-top group flex items-center justify-between rounded-t-lg bg-pine-500 px-4 py-5 transition-colors"
        >
            <div class="grid flex-1 gap-2">
                <h3 class="mb-2 whitespace-break-spaces text-base font-medium leading-tight text-white">
                    {{ event.title }}
                </h3>

                <DetailsBar :event="event" />

                <OwnersBar :owners="event.owners" />
            </div>
            <Link :href="route('events.show', props.event.id)">
                <ChevronRightIcon
                    class="w-10 rounded-full p-2 text-white transition-all duration-300 hover:bg-pine-800"
                />
            </Link>
        </div>

        <div class="divide-y rounded-b-lg bg-white">
            <template
                v-for="child in event.children"
                :key="child.id"
            >
                <ItemCardChildLine :event="child" />
            </template>
        </div>
    </div>
</template>

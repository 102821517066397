<script lang="ts" setup>
import AppLayout from '@/Layouts/AppLayout.vue';
import Card from '@/Components/Card.vue';
import {useForm} from "@inertiajs/vue3";
import Form from "@/Pages/Products/Partials/Form.vue";
import {mapErrors} from "@/utilities";
import {route as routeFn} from 'ziggy-js';
import {inject} from "vue";
import ConformationButton from "@/Components/Buttons/ConformationButton.vue";
import Button from "@/Components/Buttons/Button.vue";
import {useUser} from "@/stores/userStore";
import ProductDto = App.Dtos.ProductDto;
import CategoryDto = App.Dtos.CategoryDto;

const route = inject('route') as typeof routeFn;
const user = useUser();

const props = defineProps<{
    product: ProductDto,
    categoryOptions: object,
    vatRateOptions: object,
}>();

const form = useForm({
    ...props.product,
    category_ids: props.product.categories.map((i: CategoryDto) => i.id),
    price: props.product.price.price_including_vat,
    price_variant: props.product.price_variant.price_including_vat,
});

const submitForm = () => {
    form.put(route('products.update', props.product.id), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

</script>

<template>
    <AppLayout>
        <Card dark>
            <template #title>
                Product aanpassen
            </template>
            <FormKit
                id="signup-form"
                :actions="false"
                type="form"
                @submit="submitForm"
            >
                <div class="grid grid-cols-1 gap-2">
                    <Form
                        :category-options="categoryOptions"
                        :form="form"
                        :vat-rate-options="vatRateOptions"
                    />
                </div>
            </FormKit>
            <template #buttons>
                <ConformationButton
                    v-if="user.can('delete-product')"
                    :url="route('products.destroy', product.id)"
                    conformation-message="Weet je zeker dat je dit product wilt verwijderen?"
                    conformation-title="Verwijderen"
                    label="Verwijderen"
                    method="delete"
                />
                <Button
                    v-if="user.can('update-products')"
                    :form="form"
                    label="Aanpassen"
                    @click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>

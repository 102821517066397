<script setup>
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

import Button from '@/Components/Buttons/Button.vue';
import Password from '@/Components/Input/Password.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import { mapErrors } from '@/utilities';

let route = inject('route');

const props = defineProps({
    email: String,
    token: String,
});

const form = useForm({
    token: props.token,
    email: props.email,
    password: '',
    password_confirmation: '',
});

const submit = () => {
    form.post(route('password.update'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <GuestLayout :title="$t('Create {attribute}', { attribute: $t('password') })">
        <div class="container relative -top-[200px] flex w-full flex-col items-center">
            <div class="text-blue w-full max-w-[550px] rounded-2xl bg-white p-8">
                <h1 class="mb-2 text-[32px] font-bold">
                    {{ $t('Create {attribute}', { attribute: $t('password') }) }}
                </h1>
                <form @submit.prevent="submit">
                    <FormKit
                        v-model="form.token"
                        type="hidden"
                        name="_token"
                    />
                    <FormKit
                        v-model="form.email"
                        type="email"
                        name="email"
                        label="E-mailadres"
                        validation="required|email"
                        :errors="form.errors?.email"
                    />

                    <Password
                        v-model="form.password"
                        name="password"
                        label="Wachtwoord"
                        validation="required"
                        :errors="form.errors?.password"
                    />

                    <Password
                        v-model="form.password_confirmation"
                        name="password_confirm"
                        label="Wachtwoord herhaling"
                        validation="required|confirm"
                        validation-label="Wachtwoord herhaling"
                        :errors="form.errors?.password_confirmation"
                    />

                    <div class="flex justify-end">
                        <Button
                            class="w-full"
                            type="button"
                            :processing="form.processing"
                            :recently-successful="form.recentlySuccessful"
                            :text="$t('Log in')"
                            @click="submit()"
                        />
                    </div>
                </form>
            </div>
        </div>
    </GuestLayout>
</template>

<script setup></script>
<template>
    <div class="relative h-full w-full">
        <div
            class="absolute inset-0 flex justify-center"
            aria-hidden="true"
        >
            <div class="h-full border-r border-pine-500/30" />
        </div>
        <div class="relative flex h-full flex-col justify-center">
            <span class="cursor-pointer bg-white p-2 text-sm text-pine-500">
                <slot />
            </span>
        </div>
    </div>
</template>

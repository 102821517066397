<script setup>
import {v4} from 'uuid';
import {reactive, ref} from 'vue';

import SmallButton from '@/Components/Buttons/SmallButton.vue';
import Divider from '@/Components/Divider.vue';
import Label from '@/Components/Input/Label.vue';
import SelectSearch from '@/Components/Input/SelectSearch.vue';
import {PlusIcon} from '@heroicons/vue/24/outline';
import LinesCategoryForm from '@/Pages/Quotations/Partials/LinesCategoryForm.vue';

const props = defineProps({
    form: Object,
    productOptions: Object,
    vatRateOptions: Object,
    categoryOptions: Object,
});

const localForm = reactive(props.form);

const selectedProductId = ref(null);
const selectedProductError = reactive([]);

const selectedCategoryId = ref(null);
const selectedCategoryError = reactive([]);

function addQuotationLine(product = null) {
    selectedProductError.length = 0;
    const alreadyAdded = localForm.quotation_lines.find((line) => line.product_id === product?.id);

    if (alreadyAdded) {
        selectedProductError.push('Product is al toegevoegd');
        return;
    }
    if (product === null) {
        localForm.quotation_lines.push({
            id: v4(),
            product_id: '',
            description: '',
            quantity: 1,
            price: {
                price: 0,
                price_includes_vat: true,
                vat_rate: 9,
            },
            amount: 0,
        });
        return;
    }
    localForm.quotation_lines.push({
        id: v4(),
        product_id: product.id,
        description: product.title,
        quantity: product.scaling_factor * localForm.participants,
        price: {
            price: form.type === 'go_ahead_eagles' ? product.price_variant.price_including_vat : product.price.price_including_vat,
            price_includes_vat: product.price_includes_vat,
            vat_rate: product.vat_rate,
        },
        amount: (form.type === 'go_ahead_eagles' ? product.price_variant.price_including_vat : product.price.price_including_vat) * product.scaling_factor * localForm.participants,
    });
    selectedProductId.value = null;
}

function removeQuotationLine(id) {
    localForm.quotation_lines = localForm.quotation_lines.filter((line) => line.id !== id);
}


function addAllProductsFromCategory() {
    selectedCategoryError.length = 0;
    const products = props.productOptions.filter((product) =>
        product.categories.map((cat) => parseInt(cat.id)).includes(parseInt(selectedCategoryId.value))
    );

    if (products.length === 0) {
        selectedCategoryError.push('Geen producten gevonden');
        return;
    }

    products.forEach((product) => addQuotationLine(product));
    selectedCategoryId.value = null;
}

function productIsInCategory(product_id, category) {
    const product = props.productOptions.find((prod) => prod.id === product_id);
    return product.categories.map((cat) => parseInt(cat.id)).includes(parseInt(category));
}
</script>
<template>
    <template
        v-for="(category, id) in categoryOptions"
        :key="category.id"
    >
        <LinesCategoryForm
            :category="category"
            :form="localForm"
            :product-options="productOptions"
            :vat-rate-options="vatRateOptions"
            @remove="removeQuotationLine"
        />
    </template>

    <Divider class="-mx-4 sm:-mx-8" />

    <div class="flex gap-4 pt-2">
        <div class="flex-1">
            <SelectSearch
                v-model="selectedProductId"
                :errors="selectedProductError"
                :options="productOptions"
                clear-on-change
                label="Product toevoegen"
                @change="addQuotationLine(productOptions.find((prod) => prod.id === selectedProductId))"
            />
        </div>
        <div class="mb-3 flex flex-col">
            <Label label="&nbsp;" />
            <SmallButton @click="addQuotationLine()">
                <PlusIcon
                    aria-hidden="true"
                    class="size-5 text-white"
                />
            </SmallButton>
        </div>
    </div>

    <div class="">
        <div class="flex-1">
            <SelectSearch
                v-model="selectedCategoryId"
                :errors="selectedCategoryError"
                :options="categoryOptions"
                clear-on-change
                label="Category toevoegen"
                @change="addAllProductsFromCategory"
            />
        </div>
    </div>
</template>

<script setup>
import Button from '@/Components/Buttons/Button.vue';
import FormButton from '@/Components/Buttons/FormButton.vue';
import Card from '@/Components/Card.vue';
import CardInset from '@/Components/CardInset.vue';
import Label from '@/Components/Input/Label.vue';
import ShowEventAttachments from '@/Pages/Events/Partials/ShowEventAttachments.vue';
import theme from '@/formkitTheme';
import {useUser} from '@/stores/userStore';
import {Link} from '@inertiajs/vue3';
import {computed, inject} from 'vue';
import Description from "@/Components/Description.vue";

const dayjs = inject('dayjs');

const props = defineProps({
    event: Object,
    selectedEventId: [Number, String],
    userOptions: Object,
    categoryInQuotation: Object,
    statusOptions: Object,
    contactOptions: Object,
    foodOptions: Object,
    drinkOptions: Object,
    templateOptions: Object,
    typeOptions: Object,
    totals: Object,
});

const selectedEvent = computed(() => {
    return props.event.children.find((evt) => evt.id === props.selectedEventId) || props.event;
});

const user = useUser();

const attendanceCurrentUser = computed(() => {
    return selectedEvent.value.attendances.find((attendance) => attendance.user_id === user.id);
});

const canAttend = computed(() => {
    return (
        (typeof attendanceCurrentUser.value === 'undefined' || attendanceCurrentUser.value.status === 'retracted') &&
        dayjs(selectedEvent.value.open_attendance_from).isBefore(dayjs().startOf('day'))
    );
});

const canAccept = computed(() => {
    return typeof attendanceCurrentUser.value !== 'undefined' && attendanceCurrentUser.value.status === 'invited';
});

const canRetract = computed(() => {
    return typeof attendanceCurrentUser.value !== 'undefined' && attendanceCurrentUser.value.status === 'applied';
});

const canWithdraw = computed(() => {
    return (
        typeof attendanceCurrentUser.value !== 'undefined' &&
        attendanceCurrentUser.value.status === 'accepted' &&
        dayjs(selectedEvent.value.retract_attendance_till).isAfter(dayjs().startOf('day'))
    );
});
</script>
<template>
    <Card
        collapsable
        remove-padding
    >
        <template #title>
            {{ event.title }}
        </template>
        <div
            v-if="event.children.length > 0"
            class="flex border-b border-pine-100"
        >
            <Link
                v-for="evt in [event, ...event.children]"
                :key="evt.id"
                :class="{ 'mb-[-2px] border-b-2 border-beige-500': evt.id === selectedEventId }"
                :href="route('events.show', evt.id)"
                class="events-center relative flex w-full flex-col justify-center p-2 outline-0"
            >
                <div class="mb-1 flex flex-1 items-center justify-center text-center leading-tight md:min-h-10">
                    {{ evt.title }}

                    <div
                        :class="{
                            'bg-pine-500 text-beige-50': evt.accepted_attendances_count === event.capacity,
                            'bg-beige-100 text-pine-600 ': evt.accepted_attendances_count !== event.capacity,
                        }"
                        class="absolute bottom-2 right-2 ml-3 hidden whitespace-nowrap rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block"
                    >
                        {{ evt.accepted_attendances_count }} / {{ evt.capacity }}
                    </div>
                </div>

                <div
                    v-if="!evt.all_day"
                    class="text-sm"
                >
                    {{ dayjs(evt.start).format('HH:mm') }} - {{ dayjs(evt.end).format('HH:mm') }}
                </div>
            </Link>
        </div>

        <CardInset class="grid gap-4 md:grid-cols-3">
            <div class="col-span-full">
                <div :class="theme.global.outer">
                    <dt :class="theme.global.label">
                        Omschrijving
                    </dt>
                    <dd
                        class="mt-1 text-sm"
                        v-html="selectedEvent.description ?? '---'"
                    />
                </div>
            </div>

            <div>
                <Label label="Total uren" />
                <div class="mt-1 text-sm">
                    {{ totals.duration ? totals.duration : '---' }}
                </div>
            </div>
            <div>
                <Label label="Total Opbouw uren" />
                <div class="mt-1 text-sm">
                    {{ totals.durationAssembly ? totals.durationAssembly : '---' }}
                </div>
            </div>
            <div>
                <Label label="Total Afbouw uren" />
                <div class="mt-1 text-sm">
                    {{ totals.durationDisassembly ? totals.durationDisassembly : '---' }}
                </div>
            </div>

            <Description
                v-if="event.address"
                :description="event.address.full_address"
                :url="event.address.url"
                external
                term="Adres"
            />

            <ShowEventAttachments :attachments="selectedEvent.attachments" />
        </CardInset>

        <template #buttons>
            <Button
                is="a"
                v-if="event.type === 'go_ahead_eagles'"
                :disabled="!selectedEvent.schema"
                :href="route('events.schema', { id: selectedEvent.id })"
                :label="selectedEvent.schema ? `Download schema` : 'Nog geen schema bekent'"
                target="_blank"
            />

            <FormButton
                v-if="canAttend"
                :data="{ event_id: selectedEvent.id, user_id: user.id }"
                :url="route('attendances.store')"
                label="Aanmelden"
                method="post"
            />

            <FormButton
                v-if="canAccept"
                :data="{ attendance_id: attendanceCurrentUser.id }"
                :url="route('accepted-attendances.store')"
                label="Uitnodiging accepteren"
                method="post"
            />

            <FormButton
                v-if="canRetract"
                :data="{ attendance_id: attendanceCurrentUser.id }"
                :url="route('retracted-attendances.store')"
                label="Aanmelden intrekken"
                method="post"
            />

            <FormButton
                v-if="canWithdraw"
                :data="{ attendance_id: attendanceCurrentUser.id }"
                :url="route('retracted-attendances.store')"
                label="Afzeggen"
                method="post"
                red
            />

            <FormButton
                v-if="user.can('update-events')"
                :url="route('events.edit', { id: event.parent_id ?? event.id })"
                label="Aanpassen"
            />
        </template>
    </Card>
</template>

<script setup>
import {computed, inject} from 'vue';

import Card from '@/Components/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';

import {collect} from "collect.js";
import Divider from "@/Components/Divider.vue";
import Line from "@/Pages/Quotations/Lines/Line.vue";
import AutoCompleet from "@/Components/Input/AutoCompleet.vue";
import {useForm} from "@inertiajs/vue3";
import Group from "@/Components/Input/Group.vue";
import SmallButton from "@/Components/Buttons/SmallButton.vue";
import {PlusIcon} from "@heroicons/vue/24/outline/index.js";
import Label from "@/Components/Input/Label.vue";
import {mapErrors} from "@/utilities.js";
import {blank} from "@/typeSciptHelpers";

const route = inject('route');

const props = defineProps({
    query: Object,
    quotation: Object,
    productOptions: Object,
    vatRateOptions: Object,
    categoryOptions: Object,
});

const linesWithCategory = computed(() => {
    return collect(props.quotation.quotation_lines)
        .whereNotNull('category_dto')
        .groupBy('category_dto.title')
});

const linesWithoutCategory = computed(() => {
    return collect(props.quotation.quotation_lines)
        .whereNull('category_dto')
});

const productNames = computed(() => {

    let categoryId = props.categoryOptions.find((category) => category.title === form.category_name)?.id;
    if (categoryId) {
        return props.productOptions.filter(function (product) {
            let categoryIds = product.categories.map((category) => category.id);

            return categoryIds.includes(categoryId);
        }).map((product) => product.title);
    }

    if (blank(form.category_name)) {
        return props.productOptions.map((product) => product.title);
    }

    return [];
});

const categoryNames = computed(() => {
    return props.categoryOptions.map((category) => category.title)
});

const form = useForm({
    quotation_id: props.quotation.id,
    category_name: '',
    product_name: '',
})

function submit() {
    form.post(route('quotation-lines.store', props.quotation.id), {
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
}

</script>

<template>
    <AppLayout>
        <Card>
            <template #title>
                Offerte aanpassen
            </template>
            <template
                v-for="(lines, categoryName) in linesWithCategory.all()"
                :key="categoryName"
            >
                <Divider :title="categoryName" />
                <Line
                    v-for="line in lines"
                    :key="line.id"
                    :line="line"
                    :vat-rate-options="vatRateOptions"
                />
            </template>


            <Divider class="-mx-4 sm:-mx-8" />

            <Form>
                <Group>
                    <AutoCompleet
                        v-model="form.category_name"
                        :errors="form.errors.category_name"
                        :options="categoryNames"
                        label="Category"
                    />

                    <div class="flex gap-4">
                        <AutoCompleet
                            v-model="form.product_name"
                            :errors="form.errors.product_name"
                            :options="productNames"
                            label="Producten"
                        />
                        <div class="mb-2 flex flex-col">
                            <Label label="&nbsp;" />
                            <SmallButton @click="submit">
                                <PlusIcon
                                    aria-hidden="true"
                                    class="size-5 text-white"
                                />
                            </SmallButton>
                        </div>
                    </div>
                </Group>
            </Form>
        </Card>
    </AppLayout>
</template>

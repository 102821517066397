import { useSessionStorage } from '@vueuse/core';
import { notify } from 'notiwind';
import { defineStore } from 'pinia';

export const useToasts = defineStore('toasts', {
    state: () => ({
        toasts: {},
        nonces: useSessionStorage('stores-toasts-nonces', []),
    }),
    getters: {
        notYetShownToasts: (state) => {
            return Object.values(state.toasts).filter((toast) => {
                return !state.nonces.includes(toast.nonce);
            });
        },
    },
    actions: {
        load(toasts) {
            this.toasts = toasts;
            this.show();
        },
        show() {
            this.notYetShownToasts.forEach((toast) => {
                notify({ title: toast.title }, 4000);
                this.nonces.push(toast.nonce);
            });
        },
    },
});

<template>
    <div
        class="grid grid-cols-7 gap-px rounded-t-lg border-b border-pine-300 bg-pine-500 text-center text-xs leading-6 text-beige-500 lg:flex-none"
    >
        <div class="flex justify-center py-2">
            <span>M</span>
            <span class="sr-only sm:not-sr-only">a</span>
        </div>
        <div class="flex justify-center py-2">
            <span>D</span>
            <span class="sr-ronly sm:not-sr-only">i</span>
        </div>
        <div class="flex justify-center py-2">
            <span>W</span>
            <span class="sr-only sm:not-sr-only">o</span>
        </div>
        <div class="flex justify-center py-2">
            <span>D</span>
            <span class="sr-only sm:not-sr-only">o</span>
        </div>
        <div class="flex justify-center py-2">
            <span>V</span>
            <span class="sr-only sm:not-sr-only">r</span>
        </div>
        <div class="flex justify-center py-2">
            <span>Z</span>
            <span class="sr-only sm:not-sr-only">a</span>
        </div>
        <div class="flex justify-center py-2">
            <span>Z</span>
            <span class="sr-only sm:not-sr-only">o</span>
        </div>
    </div>
</template>

<script setup>
import Divider from '@/Components/Divider.vue';
import ShowAttendancesRow from '@/Pages/Events/Partials/ShowAttendancesRow.vue';
import {useUser} from '@/stores/userStore';
import {computed, inject} from 'vue';

const route = inject('route');
const dayjs = inject('dayjs');
const user = useUser();

const props = defineProps({
    event: Object,
    area: String,
    statusOptions: Object,
    acceptedAttendances: Array,
});

const tableSettings = computed(() => [
    {label: 'Time', width: '1%'},
    {label: 'Naam', width: '1%'},
    {label: 'Rol', width: '25%'},
    {label: '', width: '1%', cellType: 'arrow', permission: user.can('update-attendances')},
]);

const tableData = computed(() =>
    props.acceptedAttendances
        .filter((att) => att.area == props.area)
        .filter((att) => !['rejected', 'retracted'].includes(att.status))
        .sort((a, b) => (a.start ?? props.event.start > b.start ?? props.event.start ? 1 : -1))
        .map((attendance) => {
            return {
                id: attendance.id,
                rowData: [
                    dayjs(attendance.start ?? props.event.start).format('HH:mm') +
                    ' - ' +
                    dayjs(attendance.end ?? props.event.end).format('HH:mm'),
                    attendance.user.name,
                    attendance.role,
                    route('attendances.edit', attendance.id),
                ],
            };
        })
);
</script>
<template>
    <div v-if="tableData.length">
        <Divider :title="area" />

        <template
            v-for="{ id, rowData } in tableData"
            :key="id"
        >
            <ShowAttendancesRow :row="rowData" />
        </template>
    </div>
</template>

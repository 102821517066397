export function blank(any: string | Array<string> | object | null): boolean {
    if (any === null) {
        return false;
    }

    if (typeof any === 'string') {
        return any === '';
    }

    if (Array.isArray(any)) {
        return any.length === 0;
    }

    if (typeof any === 'object') {
        return Object.keys(any).length === 0;
    }

    return false;
}

export function extractError(errors: Record<string, string>, key: string): string[] {
    const error = errors[key];
    if (error === undefined) {
        return [];
    }
    console.log(`Error: ${error} for key: ${key}`);
    return [error];
}

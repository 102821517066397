<script setup>
import SelectColor from '@/Components/Input/SelectColor.vue';
import SelectMultiple from '@/Components/Input/SelectMultiple.vue';
import InputGroup from '@/Components/Input/Group.vue';
import {colors} from '@/colors';
import {useUser} from '@/stores/userStore';

const user = useUser();

defineProps({
    form: Object,
    roleOptions: Object,
    drivingLicenseOptions: Object,
    skillOptions: Object,
});
</script>
<template>
    <InputGroup>
        <FormKit
            v-model="form.first_name"
            :errors="form.errors?.first_name"
            :value="form.first_name"
            label="Voornaam"
            type="text"
            validate="required"
        />
        <FormKit
            v-model="form.last_name"
            :errors="form.errors?.last_name"
            :value="form.last_name"
            label="Achternaam"
            type="text"
        />
    </InputGroup>
    <InputGroup>
        <FormKit
            v-model="form.email"
            :errors="form.errors?.email"
            :value="form.email"
            label="Email"
            type="email"
        />

        <FormKit
            v-model="form.phone_number"
            :errors="form.errors?.phone_number"
            :value="form.phone_number"
            label="Telefoon"
            type="text"
        />
    </InputGroup>

    <SelectColor
        v-model="form.color_code"
        :errors="form.errors?.color_code"
        :options="colors"
        label="Kleur"
        placeholder="Geen Kleur"
    />

    <SelectMultiple
        v-model="form.driving_licenses"
        :errors="form.errors?.driving_licenses"
        :options="drivingLicenseOptions"
        label="Rijbewijzen"
        placeholder="Geen Rijbewijs"
    />

    <SelectMultiple
        v-model="form.skills"
        :errors="form.errors?.skills"
        :options="skillOptions"
        label="Vaardigheden"
        placeholder="Geen vaardigheden"
    />

    <FormKit
        v-if="user.can('update-roles')"
        v-model="form.role"
        :errors="form.errors?.role"
        :options="{ '': 'Selecteer een rol', ...roleOptions }"
        :value="form.role"
        label="Rol"
        type="select"
    />

    <InputGroup>
        <FormKit
            v-model="form.favoriet"
            :errors="form.errors?.favoriet"
            label="Favoriet"
            type="checkbox"
        />
        <FormKit
            v-model="form.approved"
            :errors="form.errors?.approved"
            label="Goedgekeurd"
            type="checkbox"
        />
        <FormKit
            v-model="form.permanent_staff_member"
            :errors="form.errors?.permanent_staff_member"
            label="Vaste medewerker"
            type="checkbox"
        />
    </InputGroup>
</template>

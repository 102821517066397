<script setup>
import {ChevronDownIcon} from '@heroicons/vue/24/outline';
import {ref, useSlots} from 'vue';
import CardInset from '@/Components/CardInset.vue';

const props = defineProps({
    light: Boolean,
    narrow: Boolean,
    removePadding: Boolean,
    collapsable: Boolean,
    collapsed: Boolean,
});

const slots = useSlots();

const open = ref(!props.collapsed);

function toggle() {
    if (props.collapsable) {
        open.value = !open.value;
    }
}
</script>
<template>
    <div
        :class="{ 'max-w-xl': narrow }"
        class="mx-auto w-full rounded-lg shadow"
    >
        <component
            :is="collapsable ? 'button' : 'div'"
            v-if="slots.title"
            :class="{ 'rounded-lg': !open }"
            class="items-top flex w-full justify-between rounded-t-lg border-b border-pine-100 bg-pine-500 p-4 text-white"
            @click="toggle"
        >
            <div class="">
                <h3 class="font-bold leading-tight">
                    <slot name="title" />
                </h3>
            </div>
            <button
                v-if="collapsable || collapsed"
                class="-m-1 flex gap-2 rounded-full p-1 transition-colors hover:bg-pine-600"
            >
                <ChevronDownIcon
                    :class="{ 'rotate-90': !open }"
                    class="w-6 text-white transition-transform"
                />
            </button>
        </component>
        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-99 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-out"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-99 opacity-0"
        >
            <div v-show="open || !collapsable">
                <div
                    :class="[{ 'rounded-t-lg': !slots.title, 'rounded-b-lg': !slots.buttons }]"
                    class="flex flex-col bg-white text-black lg:flex-row"
                >
                    <template v-if="removePadding">
                        <div class="flex-1">
                            <slot />
                        </div>
                    </template>
                    <template v-else>
                        <CardInset class="flex-1">
                            <slot />
                        </CardInset>
                    </template>

                    <aside
                        v-if="slots.aside"
                        :class="{ 'px-4 py-5 sm:p-6': !removePadding }"
                        class="relative w-96 shrink-0 overflow-y-auto border-l border-beige-50"
                    >
                        <slot name="aside" />
                    </aside>
                </div>

                <CardInset
                    v-if="slots.buttons"
                    class="flex justify-end gap-4 rounded-b-lg border-grey-300 bg-beige-50"
                >
                    <slot name="buttons" />
                </CardInset>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { computed, inject } from 'vue';

const dayjs = inject('dayjs');
const props = defineProps({
    item: Object,
});

const entity = computed(() => {
    if (props.item.subject_type.includes('Attendance')) {
        return 'Aanwezigheid';
    }

    return '';
});
</script>
<template>
    <div class="flex space-x-3">
        <!-- <img class="h-6 w-6 rounded-full" :src="activityItem.person.imageUrl" alt="" /> -->
        <div class="flex-1 space-y-1">
            <div class="flex items-center justify-between">
                <h3 class="text-sm font-medium">
                    {{ item.causer.name }}
                </h3>
                <p class="text-sm text-gray-500">
                    {{ dayjs(item.created_at).format('DD-MM-YYYY') }}
                </p>
            </div>
            <p class="text-sm text-gray-500">
                {{ entity }} {{ item.description }}
            </p>
        </div>
    </div>
</template>

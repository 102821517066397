export default {
    "en": {
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more errors)",
        "Accept {model}": "Accept {model}",
        "All rights reserved.": "All rights reserved.",
        "Confirm Password": "Confirm Password",
        "Create {attribute}": "Create {attribute}",
        "Dear": "Dear",
        "Forbidden": "Forbidden",
        "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.": "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.",
        "Go to page {page}": "Go to page {page}",
        "Hello!": "Hello!",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "If you no longer have the email, please contact us.": "If you no longer have the email, please contact us.",
        "Login": "Login",
        "Log in": "Log in",
        "Logout": "Logout",
        "Not Found": "Not Found",
        "of": "of",
        "From date": "From date",
        "Page Expired": "Page Expired",
        "Pagination Navigation": "Pagination Navigation",
        "password": "password",
        "Password": "password",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "Please use the link in the email you received.": "Please use the link in the email you received.",
        "Quotation": "Quotation",
        "Quotation has been accepted.": "Quotation has been accepted.",
        "Regards": "Regards",
        "Register": "Register",
        "Reset Password": "Reset Password",
        "Reset Password Notification": "Reset Password Notification",
        "results": "results",
        "Select an option": "Select an option",
        "Server Error": "Server Error",
        "Service Unavailable": "Service Unavailable",
        "Showing": "Showing",
        "Sign out": "Sign out",
        "Thank you for your business.": "Thank you for your business.",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.",
        "The given data was invalid.": "The given data was invalid.",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "to": "to",
        "To conform your acceptance of the quotation, please click the button below.": "To conform your acceptance of the quotation, please click the button below.",
        "Toggle navigation": "Toggle navigation",
        "Too Many Requests": "Too Many Requests",
        "Unauthorized": "Unauthorized",
        "Verify Email Address": "Verify Email Address",
        "Whoops!": "Whoops!",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "Your Profile": "Your Profile",
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} must only contain letters.",
            "alpha_dash": "The {attribute} must only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} must only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} must be between {min} and {max}.",
                "string": "The {attribute} must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "declined": "The {attribute} must be declined.",
            "declined_if": "The {attribute} must be declined when {other} is {value}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} may not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} may not start with one of the following: {values}.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "array": "The {attribute} must have more than {value} items.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "numeric": "The {attribute} must be greater than {value}.",
                "string": "The {attribute} must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more.",
                "file": "The {attribute} must be greater than or equal to {value} kilobytes.",
                "numeric": "The {attribute} must be greater than or equal to {value}.",
                "string": "The {attribute} must be greater than or equal to {value} characters."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lowercase": "The {attribute} must be lowercase.",
            "lt": {
                "array": "The {attribute} must have less than {value} items.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "numeric": "The {attribute} must be less than {value}.",
                "string": "The {attribute} must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items.",
                "file": "The {attribute} must be less than or equal to {value} kilobytes.",
                "numeric": "The {attribute} must be less than or equal to {value}.",
                "string": "The {attribute} must be less than or equal to {value} characters."
            },
            "mac_address": "The {attribute} must be a valid MAC address.",
            "max": {
                "array": "The {attribute} must not have more than {max} items.",
                "file": "The {attribute} must not be greater than {max} kilobytes.",
                "numeric": "The {attribute} must not be greater than {max}.",
                "string": "The {attribute} must not be greater than {max} characters."
            },
            "max_digits": "The {attribute} must not have more than {max} digits.",
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} must have at least {min} items.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "numeric": "The {attribute} must be at least {min}.",
                "string": "The {attribute} must be at least {min} characters."
            },
            "min_digits": "The {attribute} must have at least {min} digits.",
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": {
                "letters": "The {attribute} must contain at least one letter.",
                "mixed": "The {attribute} must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} must contain at least one number.",
                "symbols": "The {attribute} must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "present": "The {attribute} field must be present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "array": "The {attribute} must contain {size} items.",
                "file": "The {attribute} must be {size} kilobytes.",
                "numeric": "The {attribute} must be {size}.",
                "string": "The {attribute} must be {size} characters."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid timezone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} must be uppercase.",
            "url": "The {attribute} must be a valid URL.",
            "uuid": "The {attribute} must be a valid UUID.",
            "attributes": {
                "address": "address",
                "age": "age",
                "amount": "amount",
                "area": "area",
                "available": "available",
                "birthday": "birthday",
                "body": "body",
                "city": "city",
                "content": "content",
                "country": "country",
                "created_at": "created at",
                "creator": "creator",
                "current_password": "current password",
                "date": "date",
                "date_of_birth": "date of birth",
                "day": "day",
                "deleted_at": "deleted at",
                "description": "description",
                "district": "district",
                "duration": "duration",
                "email": "email",
                "excerpt": "excerpt",
                "filter": "filter",
                "first_name": "first name",
                "gender": "gender",
                "group": "group",
                "hour": "hour",
                "image": "image",
                "last_name": "last name",
                "lesson": "lesson",
                "line_address_1": "line address 1",
                "line_address_2": "line address 2",
                "message": "message",
                "middle_name": "middle name",
                "minute": "minute",
                "mobile": "mobile",
                "month": "month",
                "name": "name",
                "national_code": "national code",
                "number": "number",
                "password": "password",
                "password_confirmation": "password confirmation",
                "phone": "phone",
                "photo": "photo",
                "postal_code": "postal code",
                "price": "price",
                "province": "province",
                "recaptcha_response_field": "recaptcha response field",
                "remember": "remember",
                "restored_at": "restored at",
                "result_text_under_image": "result text under image",
                "role": "role",
                "second": "second",
                "sex": "sex",
                "short_text": "short text",
                "size": "size",
                "state": "state",
                "street": "street",
                "student": "student",
                "subject": "subject",
                "teacher": "teacher",
                "terms": "terms",
                "test_description": "test description",
                "test_locale": "test locale",
                "test_name": "test name",
                "text": "text",
                "time": "time",
                "title": "title",
                "updated_at": "updated at",
                "username": "username",
                "year": "year",
                "quotation_lines.*.description": "description",
                "quotation_lines.*.product_id": "product",
                "quotation_lines.*.quantity": "quantity",
                "quotation_lines.*.unit_price": "unit price",
                "quotation_lines.*.amount": "amount"
            }
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        }
    },
    "nl": {
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more errors)",
        "0%": "0%",
        "9%": "9%",
        "21%": "21%",
        "{model} {action}": "{model} {action}",
        "Accept {model}": "Accepteer {model}",
        "Accepted": "Geaccepteerd",
        "accepted": "geaccepteerd",
        "Address": "Adres",
        "All rights reserved.": "Alle rechten voorbehouden.",
        "Almost full": "Bijna vol",
        "amount": "bedrag",
        "Applied": "Aangemeld",
        "Approved": "Goedgekeurd",
        "Assembly and disassembly": "Op- & afbouw",
        "Attachment": "Bijlage",
        "Attendance": "Aanwezigheid",
        "attendance": "attendance",
        "auth.password": "auth.password",
        "City": "Stad",
        "Company": "Bedrijf",
        "From date": "Van datum",
        "To date": "Tot datum",
        "Cancel": "Annuleren",
        "Confirm Password": "Bevestig wachtwoord",
        "Consumer": "Consument",
        "Cook": "Kok",
        "Country": "Land",
        "Create {attribute}": "Maak {attribuut}",
        "created": "aangemaakt",
        "Currency": "Valuta",
        "Date": "Datum",
        "Days": "Dagen",
        "Dear": "Beste",
        "Declined": "Afgewezen",
        "deleted": "verwijderd",
        "Description": "Omschrijving",
        "description": "omschrijving",
        "employee": "medewerker",
        "Employee": "Medewerker",
        "End": "Einde",
        "End date": "Einddatum",
        "End time": "Eindtijd",
        "Event": "Evenement",
        "Events": "Evenementen",
        "events": "evenementen",
        "Events:": "Evenementen:",
        "Event type": "Evenement type",
        "Event type color": "Evenement type kleur",
        "Event type description": "Evenement type omschrijving",
        "Forbidden": "Geen toegang",
        "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.": "Wachtwoord vergeten? Geen probleem. Laat ons uw e-mailadres weten en wij sturen u een link om uw wachtwoord te resetten, zodat u een nieuw wachtwoord kunt kiezen.",
        "Full": "Vol",
        "Go Ahead Eagles": "Go Ahead Eagles",
        "Go to page {page}": "Ga naar pagina {page}",
        "Hello!": "Hallo!",
        "Hours": "Uren",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "Indien ingeschakeld, en er is geen afbeelding geupload, dan wordt de banner van de homepage getoond.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL below": "Als u moeite heeft met het aanklikken van de \"{actionText}\" knop, kopieer en plak dan onderstaande url",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL below into your web browser:": "Als je moeite hebt met het aanklikken van de \"{actionText}\" knop, kopieer en plak dan onderstaande url en plak het in je webbrowser",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belown": "Als u moeite heeft met het aanklikken van de \"{actionText}\" knop, kopieer en plak dan onderstaande url",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hierondernin je webbrowser:",
        "If you did not create an account, no further action is required.": "Als je geen account hebt aangemaakt hoef je verder niets te doen.",
        "If you did not request a password reset, no further action is required.": "Als je geen wachtwoordherstel hebt aangevraagd, hoef je verder niets te doen.",
        "If you no longer have the email, please contact us.": "Als u de e-mail niet meer heeft, neem dan contact met ons op.",
        "Image": "Afbeelding",
        "into your web browser:": "in je webbrowser:",
        "Invitation accepted": "Uitnodiging geaccepteerd",
        "Invitation rejected": "Uitnodiging afgewezen",
        "Invited": "Uitgenodigd",
        "Local cake makers flyer": "Lokale taartenbakkers flyer",
        "Local florists flyer": "Lokale bloemisten flyer",
        "Local musicians flyer": "Lokale muzikanten flyer",
        "Local photographers flyer": "Lokale fotografen flyer",
        "Location": "Locatie",
        "Log in": "Inloggen",
        "Login": "Inloggen",
        "Logistics": "Logistiek",
        "Logout": "Uitloggen",
        "Missing time registrations": "Uren Ontbreken",
        "Months": "Maanden",
        "None": "Geen",
        "none": "geen",
        "Not Found": "Niet gevonden",
        "of": "van",
        "Only the attendee is allowed to accept the invited application": "Alleen de deelnemer mag de uitgenodigde aanvraag aanvaarden",
        "Only the attendee is allowed to retract the application": "Alleen de deelnemer kan de aanvraag intrekken.",
        "Others": "Overige",
        "Page Expired": "Pagina niet meer geldig",
        "Pagination Navigation": "Paginanavigatie",
        "Password": "Wachtwoord",
        "password": "wachtwoord",
        "Personal appointment": "Persoonlijke afspraak",
        "Please click the button below to verify your email address.": "Klik op de knop hieronder om je e-mailadres te verifiëren.",
        "Please use the link in the email you received.": "Gebruik de link in de e-mail die u hebt ontvangen.",
        "Post calculation": "Na calculatie",
        "Price": "Prijs",
        "product": "product",
        "quantity": "hoeveelheid",
        "Quotation": "Offerte",
        "Quotation has been accepted.": "Offerte is geaccepteerd.",
        "Regards": "Met vriendelijke groet",
        "Register": "Registreren",
        "Register time": "Registreer tijd",
        "Registration link": "Registratie link",
        "Regular": "Reguliere",
        "Regular staff": "Standaard medewerker",
        "Rejected": "Afgewezen",
        "rejected": "afgewezen",
        "Reset Password": "Wachtwoord herstellen",
        "Reset Password Notification": "Wachtwoordherstel notificatie",
        "results": "resultaten",
        "Revised": "Herzien",
        "Role": "Rol",
        "role": "rol",
        "Select an option": "Selecteer een optie",
        "Send": "Verstuurd",
        "Sent": "Verstuurd",
        "Server Error": "Server fout",
        "Service Unavailable": "Website onbeschikbaar",
        "Setting": "Instelling",
        "Settings": "Instellingen",
        "Showing": "Toont",
        "Sign out": "Afmelden",
        "Skill": "Vaardigheid",
        "skill": "vaardigheid",
        "Skills": "Vaardigheden",
        "skills": "vaardigheden",
        "Some events you attended have not yet been registered. Please register your time.": "Sommige evenementen die u bijwoonde zijn nog niet geregistreerd. Registreer uw tijd.",
        "Start": "Start",
        "Start date": "Startdatum",
        "Start time": "Starttijd",
        "Submitted": "Ingediend",
        "Text": "Tekst",
        "Thank you for your business.": "Dank u voor uw zaken.",
        "The {attribute} must contain at least one letter.": "Het {attribute} moet minimaal één letter bevatten.",
        "The {attribute} must contain at least one number.": "Het {attribute} moet minimaal één cijfer bevatten.",
        "The {attribute} must contain at least one symbol.": "Het {attribute} moet minimaal één symbool bevatten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Het {attribute} moet minimaal één hoofdletter en één kleine letter bevatten.",
        "The attendance has already been rejected": "De aanwezigheid is al afgewezen",
        "The event has conflicts with other events": "Het evenement heeft conflicten met andere evenementen",
        "The event is already full": "Het evenement is al vol",
        "The event is based on the quotation {quotation} for {name}": "De evenement is gebaseerd op de offerte {quotation} voor {name}",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Het {attribute} is aangetroffen in een datalek. Geef een ander {attribute}.",
        "The given data was invalid.": "De gegeven data was ongeldig.",
        "The provided password does not match your current password.": "Het opgegeven wachtwoord komt niet overeen met uw huidige wachtwoord.",
        "This password reset link will expire in {count} minutes.": "Deze link om je wachtwoord te herstellen verloopt over {count} minuten.",
        "Time registration": "Uren",
        "time registration": "uren",
        "Title": "Titel",
        "to": "tot",
        "To conform your acceptance of the quotation, please click the button below.": "Om uw aanvaarding van de offerte te bevestigen, klikt u op onderstaande knop.",
        "Toggle navigation": "Schakel navigatie",
        "Too Many Requests": "Te veel serververzoeken",
        "Unauthorized": "Onbevoegd",
        "unit price": "eenheidsprijs",
        "updated": "bijgewerkt",
        "Verify Email Address": "Verifieer e-mailadres",
        "Weeks": "Weken",
        "Whoops!": "Oeps!",
        "Years": "Jaren",
        "You are not allowed to create {model}": "Je mag geen {model} maken",
        "You are not allowed to delete {model}": "Je mag dit {model} niet verwijderen",
        "You are not allowed to update {model}": "Je mag dit {model} niet bijwerken",
        "You are not allowed to view {model}": "Je mag dit {model} niet bekijken",
        "You are not authorized to update the role of this user.": "U bent niet gemachtigd om de rol van deze gebruiker bij te werken.",
        "You are receiving this email because we received a password reset request for your account.": "Je ontvangt deze e-mail omdat we een wachtwoordherstel verzoek hebben ontvangen voor je account.",
        "Your account for {siteName}": "Uw account voor {siteName}",
        "Your account is not approved yet.": "Uw account is nog niet goedgekeurd.",
        "Your Profile": "Uw profiel",
        "Zip code": "Postcode",
        "validation": {
            "accepted": "{Attribute} moet worden geaccepteerd.",
            "accepted_if": "{Attribute} moet worden geaccepteerd als {other} {value} is.",
            "active_url": "{Attribute} is geen geldige URL.",
            "after": "{Attribute} moet een datum na {date} zijn.",
            "after_or_equal": "{Attribute} moet een datum na of gelijk aan {date} zijn.",
            "alpha": "{Attribute} mag alleen letters bevatten.",
            "alpha_dash": "{Attribute} mag alleen letters, nummers, underscores (_) en streepjes (-) bevatten.",
            "alpha_num": "{Attribute} mag alleen letters en nummers bevatten.",
            "array": "{Attribute} moet geselecteerde elementen bevatten.",
            "before": "{Attribute} moet een datum vóór {date} zijn.",
            "before_or_equal": "{Attribute} moet een datum vóór of gelijk aan {date} zijn.",
            "between": {
                "array": "{Attribute} moet tussen {min} en {max} waardes bevatten.",
                "file": "{Attribute} moet tussen {min} en {max} kilobytes zijn.",
                "numeric": "{Attribute} moet tussen {min} en {max} zijn.",
                "string": "{Attribute} moet tussen {min} en {max} karakters zijn."
            },
            "boolean": "{Attribute} moet ja of nee zijn.",
            "confirmed": "Bevestiging van {attribute} komt niet overeen.",
            "current_password": "Huidig wachtwoord is onjuist.",
            "date": "{Attribute} moet een datum bevatten.",
            "date_equals": "{Attribute} moet een datum gelijk aan {date} zijn.",
            "date_format": "{Attribute} voldoet niet aan het formaat {format}.",
            "declined": "{Attribute} moet afgewezen worden.",
            "declined_if": "{Attribute} moet afgewezen worden wanneer {other} gelijk is aan {value}.",
            "different": "{Attribute} en {other} moeten verschillend zijn.",
            "digits": "{Attribute} moet bestaan uit {digits} cijfers.",
            "digits_between": "{Attribute} moet bestaan uit minimaal {min} en maximaal {max} cijfers.",
            "dimensions": "{Attribute} heeft geen geldige afmetingen voor afbeeldingen.",
            "distinct": "{Attribute} heeft een dubbele waarde.",
            "doesnt_end_with": "The {attribute} may not end with one of the following: {values}.",
            "doesnt_start_with": "{Attribute} mag niet beginnen met één van de volgende waarden: {values}.",
            "email": "{Attribute} is geen geldig e-mailadres.",
            "ends_with": "{Attribute} moet met één van de volgende waarden eindigen: {values}.",
            "enum": "Gekozen {attribute} is ongeldig.",
            "exists": "{Attribute} bestaat niet.",
            "file": "{Attribute} moet een bestand zijn.",
            "filled": "{Attribute} is verplicht.",
            "gt": {
                "array": "{Attribute} moet meer dan {value} waardes bevatten.",
                "file": "{Attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet groter zijn dan {value}.",
                "string": "{Attribute} moet meer dan {value} tekens bevatten."
            },
            "gte": {
                "array": "{Attribute} moet {value} of meer waardes bevatten.",
                "file": "{Attribute} moet groter of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet groter of gelijk zijn aan {value}.",
                "string": "{Attribute} moet minimaal {value} tekens bevatten."
            },
            "image": "{Attribute} moet een afbeelding zijn.",
            "in": "{Attribute} is ongeldig.",
            "in_array": "{Attribute} bestaat niet in {other}.",
            "integer": "{Attribute} moet een getal zijn.",
            "ip": "{Attribute} moet een geldig IP-adres zijn.",
            "ipv4": "{Attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "{Attribute} moet een geldig IPv6-adres zijn.",
            "json": "{Attribute} moet een geldige JSON-string zijn.",
            "lowercase": "The {attribute} must be lowercase.",
            "lt": {
                "array": "{Attribute} moet minder dan {value} waardes bevatten.",
                "file": "{Attribute} moet kleiner zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner zijn dan {value}.",
                "string": "{Attribute} moet minder dan {value} tekens bevatten."
            },
            "lte": {
                "array": "{Attribute} moet {value} of minder waardes bevatten.",
                "file": "{Attribute} moet kleiner of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner of gelijk zijn aan {value}.",
                "string": "{Attribute} moet maximaal {value} tekens bevatten."
            },
            "mac_address": "{Attribute} moet een geldig MAC-adres zijn.",
            "max": {
                "array": "{Attribute} mag niet meer dan {max} waardes bevatten.",
                "file": "{Attribute} mag niet meer dan {max} kilobytes zijn.",
                "numeric": "{Attribute} mag niet hoger dan {max} zijn.",
                "string": "{Attribute} mag niet uit meer dan {max} tekens bestaan."
            },
            "max_digits": "The {attribute} must not have more than {max} digits.",
            "mimes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "mimetypes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "min": {
                "array": "{Attribute} moet minimaal {min} waardes bevatten.",
                "file": "{Attribute} moet minimaal {min} kilobytes zijn.",
                "numeric": "{Attribute} moet minimaal {min} zijn.",
                "string": "{Attribute} moet minimaal {min} tekens zijn."
            },
            "min_digits": "The {attribute} must have at least {min} digits.",
            "multiple_of": "{Attribute} moet een veelvoud van {value} zijn.",
            "not_in": "{Attribute} is ongeldig.",
            "not_regex": "Het formaat van {attribute} is ongeldig.",
            "numeric": "{Attribute} moet een getal zijn.",
            "password": {
                "letters": "{Attribute} moet minimaal één letter bevatten.",
                "mixed": "{Attribute} moet minimaal één kleine letter en één hoofdletter bevatten.",
                "numbers": "{Attribute} moet minimaal één cijfer bevatten.",
                "symbols": "{Attribute} moet minimaal één vreemd teken bevatten.",
                "uncompromised": "Het opgegeven {attribute} komt voor in een datalek. Kies een ander {attribute}."
            },
            "present": "{Attribute} moet aanwezig zijn.",
            "prohibited": "{Attribute} is niet toegestaan.",
            "prohibited_if": "{Attribute} is niet toegestaan indien {other} gelijk is aan {value}.",
            "prohibited_unless": "{Attribute} is niet toegestaan tenzij {other} gelijk is aan {values}.",
            "prohibits": "{Attribute} is niet toegestaan in combinatie met {other}.",
            "regex": "Het formaat van {attribute} is ongeldig.",
            "required": "{Attribute} is verplicht.",
            "required_array_keys": "{Attribute} moet waardes bevatten voor {values}.",
            "required_if": "{Attribute} is verplicht indien {other} gelijk is aan {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_unless": "{Attribute} is verplicht tenzij {other} gelijk is aan {values}.",
            "required_with": "{Attribute} is verplicht in combinatie met {values}.",
            "required_with_all": "{Attribute} is verplicht in combinatie met {values}.",
            "required_without": "{Attribute} is verplicht als {values} niet ingevuld is.",
            "required_without_all": "{Attribute} is verplicht als {values} niet ingevuld zijn.",
            "same": "{Attribute} en {other} moeten overeenkomen.",
            "size": {
                "array": "{Attribute} moet {size} waardes bevatten.",
                "file": "{Attribute} moet {size} kilobytes groot zijn.",
                "numeric": "{Attribute} moet {size} zijn.",
                "string": "{Attribute} moet {size} tekens zijn."
            },
            "starts_with": "{Attribute} moet beginnen met een van de volgende: {values}.",
            "string": "{Attribute} moet een tekst zijn.",
            "timezone": "{Attribute} moet een geldige tijdzone zijn.",
            "unique": "{Attribute} is al in gebruik.",
            "uploaded": "Het uploaden van {attribute} is mislukt.",
            "uppercase": "The {attribute} must be uppercase.",
            "url": "{Attribute} moet een geldige URL zijn.",
            "uuid": "{Attribute} moet een geldige UUID zijn.",
            "attributes": {
                "address": "adres",
                "age": "leeftijd",
                "amount": "bedrag",
                "area": "regio",
                "available": "beschikbaar",
                "birthday": "verjaardag",
                "body": "body",
                "city": "stad",
                "content": "inhoud",
                "country": "land",
                "created_at": "aangemaakt op",
                "creator": "maker",
                "current_password": "huidig wachtwoord",
                "date": "datum",
                "date_of_birth": "geboortedatum",
                "day": "dag",
                "deleted_at": "verwijderd op",
                "description": "omschrijving",
                "district": "wijk",
                "duration": "tijdsduur",
                "email": "e-mailadres",
                "excerpt": "uittreksel",
                "filter": "filter",
                "first_name": "voornaam",
                "gender": "geslacht",
                "group": "groep",
                "hour": "uur",
                "image": "afbeelding",
                "last_name": "achternaam",
                "lesson": "les",
                "line_address_1": "adresregel 1",
                "line_address_2": "adresregel 2",
                "message": "bericht",
                "middle_name": "tweede naam",
                "minute": "minuut",
                "mobile": "mobiel",
                "month": "maand",
                "name": "naam",
                "national_code": "landcode",
                "number": "nummer",
                "password": "wachtwoord",
                "password_confirmation": "wachtwoordbevestiging",
                "phone": "telefoonnummer",
                "photo": "foto",
                "postal_code": "postcode",
                "price": "prijs",
                "province": "provincie",
                "recaptcha_response_field": "recaptcha antwoordveld",
                "remember": "onthouden",
                "restored_at": "hersteld op",
                "result_text_under_image": "antwoord tekst onder afbeelding",
                "role": "rol",
                "second": "seconde",
                "sex": "geslacht",
                "short_text": "korte tekst",
                "size": "grootte",
                "state": "staat",
                "street": "straatnaam",
                "student": "student",
                "subject": "onderwerp",
                "teacher": "docent",
                "terms": "voorwaarden",
                "test_description": "test omschrijving",
                "test_locale": "test landinstelling",
                "test_name": "test naam",
                "text": "tekst",
                "time": "tijd",
                "title": "titel",
                "updated_at": "bijgewerkt op",
                "username": "gebruikersnaam",
                "year": "jaar",
                "quotation_lines.*.description": "omschrijving",
                "quotation_lines.*.product_id": "product",
                "quotation_lines.*.quantity": "aantal",
                "quotation_lines.*.unit_price": "eenheidsprijs",
                "quotation_lines.*.amount": "totaal",
                "start_disassembly": "start afbouw",
                "end_disassembly": "eind afbouw",
                "break_disassembly": "pauze afbouw",
                "start_assembly": "start opbouw",
                "end_assembly": "eind opbouw",
                "break_assembly": "pauze opbouw",
                "end": "eind",
                "start": "start",
                "break": "pauze",
                "start_break": "start pauze",
                "end_break": "eind pauze",
                "Phone number": "telefoonnummer",
                "phone_number": "telefoonnummer",
                "E-mailaddress": "e-mailadres"
            }
        },
        "auth": {
            "failed": "Deze combinatie van e-mailadres en wachtwoord is niet geldig.",
            "password": "Wachtwoord is onjuist.",
            "throttle": "Te veel mislukte aanmeldpogingen. Probeer het nog eens over {seconds} seconden."
        },
        "pagination": {
            "next": "Volgende &raquo;",
            "previous": "&laquo; Vorige"
        },
        "passwords": {
            "reset": "Het wachtwoord van uw account is gewijzigd.",
            "sent": "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen.",
            "throttled": "Gelieve even te wachten en het dan opnieuw te proberen.",
            "token": "Dit wachtwoordhersteltoken is niet geldig.",
            "user": "Geen gebruiker bekend met het e-mailadres."
        }
    }
}

<script setup>
import Button from '@/Components/Buttons/Button.vue';
import Card from '@/Components/Card.vue';
import Divider from '@/Components/Divider.vue';
import ShowAttendancesArea from '@/Pages/Events/Partials/ShowAttendancesArea.vue';
import ShowAttendancesRow from '@/Pages/Events/Partials/ShowAttendancesRow.vue';
import {useUser} from '@/stores/userStore';
import {computed, inject} from 'vue';

const route = inject('route');
const dayjs = inject('dayjs');
const user = useUser();

const props = defineProps({
    event: Object,
    selectedEventId: [Number, String],
    statusOptions: Object,
    areaOptions: Object,
});

const selectedEvent = computed(() => {
    return props.event.children.find((evt) => evt.id == props.selectedEventId) || props.event;
});

const acceptedAttendances = computed(() => {
    return selectedEvent.value.attendances.filter((att) => att.status == 'accepted');
});

const tableSettings = computed(() => [
    {label: 'Time', width: '1%'},
    {label: 'Naam', width: '1%'},
    {label: 'Rol', width: '25%'},
    {label: '', width: '1%', cellType: 'arrow', permission: user.can('update-attendances')},
]);

const tableData = computed(() =>
    acceptedAttendances.value
        .filter((att) => att.area == null || att.area == '')
        .filter((att) => !['rejected', 'retracted'].includes(att.status))
        .sort((a, b) => (a.start ?? props.event.start > b.start ?? props.event.start ? 1 : -1))
        .map((attendance) => {
            return {
                id: attendance.id,
                rowData: [
                    dayjs(attendance.start ?? props.event.start).format('H:mm') +
                    ' - ' +
                    dayjs(attendance.end ?? props.event.end).format('H:mm'),
                    attendance.user.name,
                    attendance.role,
                    route('attendances.edit', attendance.id),
                ],
            };
        })
);

const href = computed(() => route('events.staffing', {id: selectedEvent.value.id}));
</script>
<template>
    <Card
        collapsable
        remove-padding
    >
        <template #title>
            Bezetting
        </template>

        <template
            v-for="area in areaOptions"
            :key="area"
        >
            <ShowAttendancesArea
                :accepted-attendances="acceptedAttendances"
                :area="area"
                :event="selectedEvent"
                :status-options="statusOptions"
            />
        </template>
        <template v-if="tableData.length > 0">
            <Divider
                v-if="areaOptions.length != 0"
                title="Overige"
            />

            <template
                v-for="{ id, rowData } in tableData"
                :key="id"
            >
                <ShowAttendancesRow :row="rowData" />
            </template>
        </template>
        <template #buttons>
            <Button
                is="a"
                :href="href"
                label="Download bezetting"
                target="_blank"
            />
        </template>
    </Card>
</template>

<script setup>
import Errors from '@/Components/Input/Errors.vue';
import Label from '@/Components/Input/Label.vue';
import theme from '@/formkitTheme';
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

const props = defineProps({
    label: {},
    modelValue: {},
    errors: [],
    disabled: Boolean,
    classes: String,
});

const emit = defineEmits(['update:modelValue', 'change']);

const locale = computed(() => usePage().props.locale);

const displayValue = computed(() => {
    let money = (props.modelValue / 100).toFixed(2);
    return locale.value == 'nl' ? String(money).replace('.', ',') : money;
});

const emitValue = (value) => {
    value = value.target.value;

    let asString = String(value)
        .replace(',', '.')
        .replace(/[^\d.-]/g, '');

    let asFloat = parseFloat(asString);

    if (isNaN(asFloat)) {
        asFloat = 0;
    }

    emit('update:modelValue', Math.round(asFloat * 100));
    emit('change', Math.round(asFloat * 100));
};
</script>

<template>
    <div :class="theme.global.outer">
        <Label :label="label" />
        <div :class="theme.global.inner">
            <input
                :id="label ?? 'input'"
                type="text"
                :class="theme.global.input + ' text-right ' + classes"
                :value="displayValue"
                :disabled="disabled"
                @blur="emitValue"
                @change="emitValue"
            >
        </div>
        <Errors :errors="errors" />
    </div>
</template>

<script setup>
import AddressForm from '@/Components/Address/Form.vue';
import SelectSearch from '@/Components/Input/SelectSearch.vue';
import InputGroup from '@/Components/Input/Group.vue';
import {inject} from 'vue';

const route = inject('route');

const props = defineProps({
    form: Object,
    typeOptions: Object,
    contactOptions: Object,
    quotationOptions: Object,
    edit: Boolean,
    create: Boolean,
});

const fillAddress = () => {
    if (props.form.contact_id == '') {
        return;
    }
    window.axios
        .get(
            route('api.address.index', {
                contact_id: props.form.contact_id,
            })
        )
        .then(function (response) {
            props.form.errors.contact_id = [];
            if (response.data.data.length == 0) {
                props.form.postcode = '';
                props.form.street_number = '';
                props.form.street_name = '';
                props.form.city = '';
                props.form.country = '';
                props.form.longitude = '';
                props.form.latitude = '';
                props.form.errors.contact_id = ['Geen adres bekent'];
                return;
            }
            let address = response.data.data[0];
            props.form.postcode = address.postcode;
            props.form.street_number = address.street_number;
            props.form.street_name = address.street_name;
            props.form.city = address.city;
            props.form.country = 'NL';
            props.form.longitude = address.longitude;
            props.form.latitude = address.latitude;
            props.form.errors.postcode = [];
        });
};
</script>
<template>
    <FormKit
        v-if="create"
        v-model="form.type"
        type="select"
        label="Type"
        :errors="form.errors?.type"
        :value="form.type"
        :options="typeOptions"
        validate="required"
    />

    <SelectSearch
        v-if="form.type == 'event'"
        v-model="form.contact_id"
        :help="`<span class='underline cursor-pointer'>Adres overnemen</span>`"
        label="Klant"
        :errors="form.errors?.contact_id"
        :options="contactOptions"
        @helpClicked="fillAddress"
    />

    <SelectSearch
        v-if="form.type == 'event'"
        v-model="form.quotation_id"
        label="Offerte"
        :errors="form.errors?.quotation_id"
        :options="quotationOptions"
    />

    <AddressForm
        v-if="form.type == 'event'"
        :form="form"
        :permission="true"
    />

    <FormKit
        v-model="form.title"
        type="text"
        label="Titel"
        :errors="form.errors?.title"
        :value="form.title"
    />
    <FormKit
        v-model="form.description"
        type="textarea"
        label="Omschrijving"
        :errors="form.errors?.description"
        :value="form.description"
        rows="4"
    />

    <FormKit
        v-model="form.all_day"
        type="checkbox"
        label="Hele dag"
        :errors="form.errors?.all_day"
        :value="form.all_day"
    />

    <template v-if="form.all_day">
        <FormKit
            v-model="form.start"
            type="date"
            label="Start"
            :errors="form.errors?.start"
            :value="form.start"
        />
    </template>

    <template v-else>
        <InputGroup>
            <FormKit
                v-model="form.start"
                type="datetime-local"
                label="Start"
                :errors="form.errors?.start"
                :value="form.start"
            />
            <FormKit
                v-model="form.end"
                type="datetime-local"
                label="Eind"
                :errors="form.errors?.end"
                :value="form.end"
            />
        </InputGroup>
    </template>

    <template v-if="form.type == 'event'">
        <FormKit
            v-model="form.capacity"
            type="number"
            label="Aantal werknemer"
            :errors="form.errors?.capacity"
            :value="form.capacity"
        />
        <InputGroup>
            <FormKit
                v-model="form.open_attendance_from"
                type="date"
                label="Algemene inschrijving openzetten"
                :errors="form.errors?.open_attendance_from"
                :value="form.open_attendance_from"
            />

            <FormKit
                v-model="form.retract_attendance_till"
                type="date"
                label="Afmelden tot"
                :errors="form.errors?.retract_attendance_till"
                :value="form.retract_attendance_till"
            />
        </InputGroup>
    </template>
</template>

<script setup>
import theme from '@/formkitTheme';
import {ArrowTopRightOnSquareIcon} from '@heroicons/vue/24/outline';
import {Link} from '@inertiajs/vue3';
import {computed} from 'vue';

const props = defineProps({
    term: String,
    description: [Number, String],
    url: String,
    external: Boolean,
    classes: {
        type: Object,
        default: () => ({
            outer: '',
            label: '',
        }),
    },
});

const is = computed(() => {
    if (props.external) {
        return 'a';
    }
    return props.url ? Link : 'div';
})

</script>
<template>
    <div :class="theme.global.outer + ' ' + classes.outer">
        <dt :class="theme.global.label + ' '+ classes.label">
            {{ term }}
        </dt>
        <component
            :is="is"
            :class="url ? 'hover:text-gray-900' : ''"
            :href="url"
            :target="external ? '_blank' : ''"
            class="flex items-center gap-1 "
        >
            <dd class="mt-1 text-sm">
                {{ description ?? '---' }}
            </dd>

            <ArrowTopRightOnSquareIcon
                v-if="url"
                class="ml-1 size-4"
            />
        </component>
    </div>
</template>

<script setup>
import AppLayout from '@/Layouts/AppLayout.vue';
import {tryOnMounted, useInfiniteScroll, useIntersectionObserver} from '@vueuse/core';
import {computed, inject, nextTick, onMounted, ref} from 'vue';
import Calendar from '@/Pages/Events/Index/Calendar.vue';
import Date from '@/Pages/Events/Index/Date.vue';

const route = inject('route');
const dayjs = inject('dayjs');

const props = defineProps({
    query: Object,
    eventsByDate: Object,
    statussenByDate: Object,
    typeOptions: Object,
});

const selectedDate = ref(dayjs(props.query.date));
const statussenByDate = ref(props.statussenByDate);

const scroll = ref(null);
const localEventsByDate = ref(props.eventsByDate);

const firstDate = computed(() => dayjs(Object.keys(localEventsByDate.value)[0]));
const lastDate = computed(() =>
    dayjs(Object.keys(localEventsByDate.value)[Object.keys(localEventsByDate.value).length - 1])
);

const getMore = async (start, end) => {
    const res = await window.axios.get(
        route('api.events.index', {
            start: start.startOf('month').format('YYYY-MM-DD'),
            end: end.format('YYYY-MM-DD'),
        })
    );

    localEventsByDate.value = {...localEventsByDate.value, ...res.data.data};

    localEventsByDate.value = Object.keys(localEventsByDate.value)
        .sort()
        .reduce((obj, key) => {
            obj[key] = localEventsByDate.value[key];
            return obj;
        }, {});

    getMoreStatussen(start, end);
};

const getMoreStatussen = async (start, end) => {
    start = typeof start === 'string' ? dayjs(start) : start;
    end = end || dayjs(start).endOf('month');

    const dateRes = await window.axios.get(
        route('api.dates.index', {
            start: start.startOf('month').format('YYYY-MM-DD'),
            end: end.format('YYYY-MM-DD'),
        })
    );

    dateRes.data.data.forEach((item) => {
        statussenByDate.value[item.date] = item.status;
    });
};

// Attempt to load more events before we reach the start of the list
useInfiniteScroll(
    scroll,
    async () => {
        getMore(firstDate.value.subtract(1, 'month').startOf('month'), firstDate.value.subtract(1, 'day'));
    },
    {distance: 4000, direction: 'top'}
);
// Attempt to load more events before we reach the end of the list
useInfiniteScroll(
    scroll,
    async () => {
        getMore(lastDate.value.add(1, 'day'), lastDate.value.add(1, 'month').endOf('month'));
    },
    {distance: 4000, direction: 'bottom'}
);

// Previous attempt to load more events are unreliable, so we use IntersectionObserver as a fallback
const top = ref(null);
const bottom = ref(null);

useIntersectionObserver(top, ([{isIntersecting}]) => {
    if (isIntersecting) {
        getMore(firstDate.value.subtract(1, 'month').startOf('month'), firstDate.value.subtract(1, 'day'));
    }
});

useIntersectionObserver(bottom, ([{isIntersecting}]) => {
    if (isIntersecting) {
        getMore(lastDate.value.add(1, 'day'), lastDate.value.add(1, 'month').endOf('month'));
    }
});

tryOnMounted(async () => {
    scrollToDate(selectedDate, true);
});

const scrollToDate = async (date, instant = false, attempt = 0) => {
    if (typeof date.format === 'function') {
        selectedDate.value = date;
    } else {
        selectedDate.value = date.value;
    }

    if (attempt > 2) {
        return;
    }

    while (
        !Object.keys(localEventsByDate.value).includes(selectedDate.value.format('YYYY-MM-DD')) &&
        selectedDate.value.isAfter(firstDate.value)
        ) {
        selectedDate.value = selectedDate.value.subtract(1, 'day');
    }

    let target = document.getElementById(selectedDate.value.format('YYYY-MM-DD'));

    if (target === null) {
        return;
    }

    let idRect = target.getBoundingClientRect();

    let scrollDivRect = document.getElementById('scroll').getBoundingClientRect();
    let y = scrollDivRect.y;
    let y1 = idRect.y;

    let offset = y1 - y;

    document.getElementById('scroll').scrollBy({
        top: offset + 50,
        behavior: instant ? 'auto' : 'smooth',
    });

    nextTick(() => {
        scrollToDate(date, instant, attempt + 1);
    });
};

onMounted(() => {
    // document.getElementById('scroll').onscroll = () => {
    //     let firstVisible = [...document.getElementById('scroll').children].find((child) => {
    //         return child.getBoundingClientRect().y > 20;
    //     });
    //
    //     if ([...firstVisible.children].length == 0) {
    //         return;
    //     }
    //
    //     selectedDate.value = dayjs(firstVisible.children[0].id);
    // };
});

const selectedType = ref(null);
</script>

<template>
    <AppLayout
        disable-scroll
        remove-padding
    >
        <template #header />

        <div
            id="scroll"
            ref="scroll"
            class="hide-scrollbar flex h-full flex-col gap-y-4 overflow-x-hidden overflow-y-scroll px-4 py-6 sm:px-6 md:gap-y-8 md:px-8 "
            scroll-region
        >
            <div
                ref="top"
                class="h-20"
            />

            <template
                v-for="(items, dateString) in localEventsByDate"
                :key="dateString"
            >
                <Date
                    :date="dateString"
                    :events="items"
                    :selected-type="selectedType"
                    :status="statussenByDate[dateString]"
                    @date-marking-updated="getMoreStatussen"
                />
            </template>

            <div
                ref="bottom"
                class="h-50"
            >
                <div class="h-50" />
            </div>
        </div>

        <template #aside>
            <Calendar
                v-model:selectedType="selectedType"
                :items="localEventsByDate"
                :selected-date="selectedDate"
                :statussen-by-date="statussenByDate"
                :type-options="typeOptions"
                @next-month="getMore($event.startOf('month'), $event.endOf('month'))"
                @prev-month="getMore($event.startOf('month'), $event.endOf('month'))"
                @update:selected-date="scrollToDate"
                @date-marking-updated="getMoreStatussen"
            />
        </template>
    </AppLayout>
</template>

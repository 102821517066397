<script setup>
// SelectMultiple\
import theme from '@/formkitTheme';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { computed, onMounted, ref } from 'vue';
import Errors from '@/Components/Input/Errors.vue';

const props = defineProps({
    label: String,
    modelValue: Array,
    options: {},
    help: String,
    errors: Array,
    placeholder: String,
    disabled: Boolean,
});

const emits = defineEmits(['update:modelValue', 'change', 'helpClicked']);

const selectedOptions = ref([]);

// Casting options to a universal format
const localeOptions = computed(() => {
    if (Array.isArray(props.options)) {
        return props.options.map((option) => {
            return {
                label: option.label ?? option.name ?? option.title ?? option,
                value: option.value ?? option.id ?? option,
            };
        });
    }

    let ans = [];
    for (const [key, value] of Object.entries(props.options)) {
        ans.push({
            label: value,
            value: key,
        });
    }
    return ans;
});

onMounted(() => {
    if (Array.isArray(props.modelValue)) {
        selectedOptions.value = props.modelValue.map((initValue) =>
            localeOptions.value.find((option) => option.value == initValue)
        );
    }
});

function emitValue() {
    emits(
        'update:modelValue',
        selectedOptions.value.map((option) => option.value)
    );
    emits('change');
}
</script>
<template>
    <Listbox
        v-model="selectedOptions"
        as="div"
        horizontal
        multiple
        :class="theme.global.outer"
        @update:model-value="emitValue()"
    >
        <ListboxLabel :class="theme.global.label">
            {{ label }}
        </ListboxLabel>

        <div :class="theme.global.inner">
            <ListboxButton
                :class="theme.global.input"
                class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm sm:text-sm"
            >
                <span class="block truncate">
                    <template v-if="selectedOptions.length > 0">
                        {{ selectedOptions.map((option) => option.label).join(', ') }}
                    </template>
                    <template v-else>
                        {{ placeholder }}
                    </template>

                    &nbsp;
                </span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </span>
            </ListboxButton>

            <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute z-10 mt-1 max-h-50 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <ListboxOption
                        v-for="(option, key) in localeOptions"
                        :key="key"
                        v-slot="{ active, selected }"
                        as="template"
                        :value="option"
                    >
                        <li
                            :class="[
                                active ? 'bg-pine-400 text-white' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-8 pr-4',
                            ]"
                        >
                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                {{ option.label }}
                            </span>

                            <span
                                v-if="selected"
                                :class="[
                                    active ? 'text-white' : 'text-pine-500',
                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                ]"
                            >
                                <CheckIcon
                                    class="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
    <div
        v-if="help"
        :class="theme.global.help"
        @click="emits('helpClicked')"
        v-html="help"
    />
    <Errors :errors="errors" />
</template>

<script setup>
import FormButton from '@/Components/Buttons/FormButton.vue';
import InputGroup from '@/Components/Input/Group.vue';
import PageTitle from '@/Components/PageTitle.vue';
import Pagination from '@/Components/Pagination.vue';
import Select from '@/Components/Select.vue';
import Table from '@/Components/Table/Table.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useUser} from '@/stores/userStore';

import {useForm} from '@inertiajs/vue3';
import {useDebounceFn} from '@vueuse/shared';

import {computed, inject} from 'vue';

const route = inject('route');
const user = useUser();

const props = defineProps({
    query: Object,
    quotations: Object,
    statusOptions: Object,
});

const form = useForm({
    search: props.query.search ?? '',
    orderBy: props.query.orderBy ?? '',
    orderDir: props.query.orderDir ?? 'desc',
    status: props.query.status ?? '',
    page: props.query.page ?? 1,
});

const getData = (page = 1) => {
    form.page = page;

    form.get(route('quotations.index'), {
        preserveState: true,
        only: ['quotations', 'query'],
    });
};
const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = computed(() => [
    {label: 'Naam', width: '15%'},
    {label: 'Event start', orderBy: 'start', width: '15%', from: 'md'},
    {label: 'Status', width: '15%'},
    {label: '', width: '1%', cellType: 'arrow', permission: user.can('update-quotations')},
]);

const tableData = computed(() =>
    props.quotations.data.map((quotation) => {
        return {
            id: quotation.id,
            rowData: [
                quotation.contact?.name,
                quotation.start,
                props.statusOptions[quotation.status],
                route('quotations.edit', quotation.id),
            ],
        };
    })
);
</script>

<template>
    <AppLayout>
        <PageTitle title="Offertes">
            <FormButton
                v-if="user.can('create-quotations')"
                :url="route('quick-quotations.create')"
                label="Snel formulier"
                method="get"
            />
            <FormButton
                v-if="user.can('create-quotations')"
                :url="route('quotations.create')"
                label="Offerte toevoegen"
                method="get"
            />
        </PageTitle>

        <Table
            v-model:orderBy="form.orderBy"
            v-model:orderDir="form.orderDir"
            :loading="form.processing"
            :table-data="tableData"
            :table-settings="tableSettings"
            @update:order-by="getData()"
            @update:order-dir="getData()"
        >
            <template #filters>
                <InputGroup>
                    <FormKit
                        v-model="form.search"
                        :errors="form.errors?.search"
                        :value="form.search"
                        label="Zoeken"
                        suffix-icon="search"
                        type="text"
                        @input="debouncedGetData"
                    />
                    <Select
                        v-model="form.status"
                        :options="statusOptions"
                        clearable
                        label="Status"
                        placeholder="Selecteer een status"
                        @change="getData()"
                    />
                </InputGroup>
            </template>

            <Pagination
                :page-data="quotations"
                @set-page="getData"
            />
        </Table>
    </AppLayout>
</template>

<script setup>
import Table from '@/Components/Table/Table.vue';
import {useUser} from '@/stores/userStore';
import {computed, inject} from 'vue';

const route = inject('route');
const user = useUser();

const props = defineProps({
    contacts: Object,
});

const tableSettings = computed(() => [
    {
        label: 'Naam',
        orderBy: 'name',
        width: '15%',
    },
    {
        label: 'Email',
        width: '15%',
        cellType: 'email',
        header: {
            from: 'md',
        },
        combinesWith: {
            horizontal: true,
            items: [{label: 'Telefoon', till: 'md'}],
        },
    },
    {label: 'Telefoon', width: '15%', cellType: 'phone', from: 'md'},
    {label: '', width: '1%', cellType: 'arrow', permission: user.can('viewAny-contacts')},
]);

const tableData = computed(() =>
    props.contacts.map((contact) => {
        return {
            id: contact.id,
            rowData: [contact.name, contact.email, contact.phone_number, route('contacts.edit', contact.id)],
        };
    })
);
</script>
<template>
    <Table
        :table-data="tableData"
        :table-settings="tableSettings"
    />
</template>

<script setup>
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';
import MultiButton from '@/Components/Buttons/MultiButton.vue';
import Card from '@/Components/Card.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useUser} from '@/stores/userStore';
import {mapErrors} from '@/utilities';
import Form from '@/Pages/Quotations/Partials/Form.vue';
import LinesForm from '@/Pages/Quotations/Partials/LinesForm.vue';

import {defaultText} from '@/Pages/Quotations/Partials/defaultText';
import DesignForm from '@/Pages/Quotations/Partials/DesignForm.vue';
import SendForm from '@/Pages/Quotations/Partials/SendForm.vue';
import FormButton from "@/Components/Buttons/FormButton.vue";

const route = inject('route');
const user = useUser();

const props = defineProps({
    query: Object,
    quotation: Object,
    contactOptions: Object,
    productOptions: Object,
    templateOptions: Object,
    typeOptions: Object,
    vatRateOptions: Object,
    categoryOptions: Object,
    additionalInformationOptions: Object,
});

const form = useForm({
    status: 'concept',
    template: Object.keys(props.templateOptions)[0],
    show_total: true,
    type: Object.keys(props.typeOptions)[0],
    number: '',
    date: '',
    start: '',
    end: '',
    assembly_date: '',
    disassembly_date: '',
    delivery_date: '',
    participants: '',
    quotation_lines: [],
    postcode: '',
    street_number: '',
    street_number_addition: '',
    street_name: '',
    city: '',
    country: '',
    longitude: '',
    latitude: '',
    additional_information: [],
    include_vat: true,
    ...props.quotation,
    intro: props.quotation.intro ?? defaultText.intro,
    outro: props.quotation.outro ?? defaultText.outro,
    email: props.quotation.email ?? defaultText.email,
});

const submitForm = async (button = false) => {
    form.put(
        route('quotations.update', {
            quotation: props.quotation.id,
            stay: button !== 'save and plan',
            send: button === 'save and send',
            plan: button === 'save and plan',
        }),
        {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors)),
            onSuccess: () => {
                if (button === 'save and preview') {
                    window.open(route('quotations.preview', {id: props.quotation.id}), '_blank');
                }
            },
        }
    );
    return true;
};
</script>

<template>
    <AppLayout>
        <Card collapsable>
            <template #title>
                Offerte aanpassen
            </template>
            <FormKit
                id="signup-form"
                :actions="false"
                type="form"
                @submit="submitForm"
            >
                <div class="grid grid-cols-1 gap-2">
                    <Form
                        :contact-options="contactOptions"
                        :form="form"
                        :template-options="templateOptions"
                        :type-options="typeOptions"
                    />
                </div>
            </FormKit>

            <template #buttons>
                <FormButton
                    v-if="user.can('update-quotations')"
                    :label="'Regels aanpassen'"
                    :url="route('quotations.lines', {id: props.quotation.id})"
                />
                <MultiButton
                    v-if="user.can('update-quotations')"
                    :form="form"
                    :items="[
                        { label: 'Opslaan en bekijken', value: 'save and preview' },
                        { label: 'Opslaan en inplannen', value: 'save and plan' },
                        { label: 'Opslaan en versturen', value: 'save and send' },
                    ]"
                    label="Opslaan"
                    @button-click="submitForm"
                />
            </template>
        </Card>

        <Card
            collapsable
            collapsed
        >
            <template #title>
                Offerte regels
            </template>
            <LinesForm
                :category-options="categoryOptions"
                :form="form"
                :product-options="productOptions"
                :vat-rate-options="vatRateOptions"
            />
            <template #buttons>
                <MultiButton
                    v-if="user.can('update-quotations')"
                    :form="form"
                    :items="[
                        { label: 'Opslaan en bekijken', value: 'save and preview' },
                        { label: 'Opslaan en inplannen', value: 'save and plan' },
                        { label: 'Opslaan en versturen', value: 'save and send' },
                    ]"
                    label="Opslaan"
                    @button-click="submitForm"
                />
            </template>
        </Card>

        <Card
            collapsable
            collapsed
        >
            <template #title>
                Offerte opmaak
            </template>
            <DesignForm
                :form="form"
                :template-options="templateOptions"
            />

            <template #buttons>
                <MultiButton
                    v-if="user.can('update-quotations')"
                    :form="form"
                    :items="[
                        { label: 'Opslaan en bekijken', value: 'save and preview' },
                        { label: 'Opslaan en inplannen', value: 'save and plan' },
                        { label: 'Opslaan en versturen', value: 'save and send' },
                    ]"
                    label="Opslaan"
                    @button-click="submitForm"
                />
            </template>
        </Card>

        <Card
            collapsable
            collapsed
        >
            <template #title>
                Offerte versturen
            </template>
            <SendForm
                :additional-information-options="additionalInformationOptions"
                :form="form"
            />

            <template #buttons>
                <MultiButton
                    v-if="user.can('update-quotations')"
                    :form="form"
                    :items="[
                        { label: 'Opslaan en bekijken', value: 'save and preview' },
                        { label: 'Opslaan en inplannen', value: 'save and plan' },
                        { label: 'Opslaan en versturen', value: 'save and send' },
                    ]"
                    label="Opslaan"
                    @button-click="submitForm"
                />
            </template>
        </Card>
    </AppLayout>
</template>

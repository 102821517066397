<script setup>
import Body from '@/Components/Table/Body.vue';
import Header from '@/Components/Table/Header.vue';

const props = defineProps({
    tableSettings: Array,
    tableData: Array,
    orderBy: String,
    orderDir: String,
    loading: Boolean,
    removeSpacer: Boolean,
});

const emits = defineEmits(['update:orderBy', 'update:orderDir', 'buttonClicked', 'rowClicked', 'cellClicked']);

const orderByUpdated = (newOrderBy) => emits('update:orderBy', newOrderBy);
const orderDirUpdated = (newOrderDir) => emits('update:orderDir', newOrderDir);
</script>

<template>
    <div class="">
        <slot name="filters" />
        <div :class="{ 'min-h-[700px]': !removeSpacer }">
            <div class="mt-4 overflow-hidden rounded-md shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-300 border-b border-gray-300 bg-beige-100">
                    <Header
                        :order-by="orderBy"
                        :order-dir="orderDir"
                        :table-settings="tableSettings"
                        @update:order-by="orderByUpdated"
                        @update:order-dir="orderDirUpdated"
                    />
                    <Body
                        :class="{ 'opacity-60': loading }"
                        :loading="loading"
                        :table-data="tableData"
                        :table-settings="tableSettings"
                        class="transition-opacity"
                    />
                </table>
                <slot />
            </div>
        </div>
        <hr class="border-beige-50">
    </div>
</template>

<script setup>
import { mapErrors } from '@/utilities';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    date: String,
    status: String,
});

const form = useForm({
    date: props.date,
});

const emits = defineEmits(['update']);

function submitForm(status) {
    if (status == 'delete') {
        form.delete(route('dates.destroy', { date: props.date, status, stay: true }), {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors)),
            onSuccess: () => emits('update', props.date),
        });
    } else {
        form.post(route('dates.store', { date: props.date, status, stay: true }), {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => (form.errors = mapErrors(errors)),
            onSuccess: () => emits('update', props.date),
        });
    }
}
</script>
<template>
    <Menu
        as="div"
        class="relative inline-block text-left"
    >
        <div>
            <MenuButton
                class="flex items-center rounded-full bg-gray-100 text-pine-400 hover:text-pine-600 focus:outline-none focus:ring-2 focus:ring-pine-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            >
                <span class="sr-only">Open options</span>
                <EllipsisVerticalIcon
                    class="h-5 w-5"
                    aria-hidden="true"
                />
            </MenuButton>
        </div>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
                <div class="py-1">
                    <MenuItem
                        v-if="status !== 'full'"
                        v-slot="{ active }"
                    >
                        <button
                            class="w-full text-left"
                            :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                            @click="submitForm('full')"
                        >
                            Markeren als vol
                        </button>
                    </MenuItem>
                    <MenuItem
                        v-if="status !== 'almost_full'"
                        v-slot="{ active }"
                    >
                        <button
                            class="w-full text-left"
                            :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                            @click="submitForm('almost_full')"
                        >
                            Markeren als bijna vol
                        </button>
                    </MenuItem>
                    <MenuItem
                        v-if="status != null"
                        v-slot="{ active }"
                    >
                        <button
                            class="w-full text-left"
                            :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                            @click="submitForm('delete')"
                        >
                            Markering verwijderen
                        </button>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

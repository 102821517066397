<script setup>
import {mapErrors} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {inject} from 'vue';

import Button from '@/Components/Buttons/Button.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import CompanyForm from '@/Pages/Contacts/Partials/CompanyForm.vue';
import ConsumerForm from '@/Pages/Contacts/Partials/ConsumerForm.vue';
import ContactPersonsTable from '@/Pages/Contacts/Partials/ContactPersonsTable.vue';

import AddressForm from '@/Components/Address/Form.vue';
import ConformationButton from '@/Components/Buttons/ConformationButton.vue';
import Card from '@/Components/Card.vue';
import {useUser} from '@/stores/userStore';
import SelectSearch from "@/Components/Input/SelectSearch.vue";

const route = inject('route');

const props = defineProps({
    contact: Object,
    typeOptions: Object,
    companyOptions: Object,
});

const form = useForm({
    company_name: '',
    company_email_addresses: '',
    company_phone_number: '',
    kvk_number: '',
    vat_number: '',
    first_name: '',
    last_name: '',
    email_addresses: '',
    phone_number: '',
    postcode: '',
    street_number: '',
    street_number_addition: '',
    street_name: '',
    city: '',
    country: '',
    longitude: '',
    latitude: '',
    ...props.contact,
    ...props.contact.address,
});

const submitForm = () => {
    form.put(route('contacts.update', props.contact.id), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <AppLayout>
        <Card>
            <template #title>
                {{ contact.name }}
            </template>

            <FormKit
                id="signup-form"
                :actions="false"
                type="form"
                @submit="submitForm"
            >
                <div class="grid grid-cols-1 gap-2">
                    <FormKit
                        v-model="form.type"
                        :errors="form.errors?.type"
                        :options="typeOptions"
                        :value="form.type"
                        label="Type"
                        type="select"
                        validate="required"
                    />

                    <ConsumerForm
                        v-if="form.type == 'consumer' || form.type == 'employee'"
                        :form="form"
                        :permission="useUser().can('update-contacts')"
                        :type-options="typeOptions"
                    />

                    <SelectSearch
                        v-if="form.type == 'employee'"
                        v-model="form.company_id"
                        :options="companyOptions"
                        label="Bedrijf"
                    />

                    <CompanyForm
                        v-if="form.type == 'company'"
                        :form="form"
                        :type-options="typeOptions"
                    />

                    <AddressForm
                        v-if="form.type != 'employee'"
                        :form="form"
                        :permission="useUser().can('update-contacts')"
                    />
                </div>
            </FormKit>

            <template
                v-if="useUser().canAny(['update-contacts', 'delete-contacts'])"
                #buttons
            >
                <ConformationButton
                    v-if="useUser().can('delete-contacts')"
                    :url="route('contacts.destroy', contact.id)"
                    conformation-message="Weet je zeker dat je deze contactpersoon wilt verwijderen?"
                    conformation-title="Verwijderen"
                    label="Verwijderen"
                    method="delete"
                />
                <Button
                    v-if="useUser().can('update-contacts')"
                    :form="form"
                    label="Aanpassen"
                    @click="submitForm"
                />
            </template>
        </Card>

        <ContactPersonsTable
            v-if="contact.contacts.length > 0"
            :contacts="contact.contacts"
        />
    </AppLayout>
</template>

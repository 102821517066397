<script setup>
import { useToasts } from '@/stores/toastStore';
import { usePage } from '@inertiajs/vue3';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { Notification, NotificationGroup } from 'notiwind';
import { computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const breakpoints = useBreakpoints(breakpointsTailwind);

const maxNotifications = computed(() => (breakpoints.isSmallerOrEqual('md') ? 1 : 3));

watch(
    () => usePage().props.toasts,
    (newToasts) => {
        useToasts().load(newToasts);
    },
    { deep: true }
);

onMounted(() => {
    const newToasts = usePage().props.toasts;

    useToasts().load(newToasts);
});
</script>
<template>
    <NotificationGroup>
        <div
            class="pointer-events-none fixed inset-0 z-50 flex flex-col items-start justify-end p-6 px-4 md:flex-row"
        >
            <div class="w-full max-w-sm">
                <Notification
                    v-slot="{ notifications, close }"
                    :max-notifications="maxNotifications"
                    enter="transform ease-out duration-300 transition"
                    enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                    enter-to="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-500"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                    move="transition duration-500"
                    move-delay="delay-300"
                >
                    <div
                        v-for="notification in notifications"
                        :key="notification.id"
                        class="pointer-events-auto mx-auto mt-4 flex w-full max-w-sm cursor-pointer overflow-hidden rounded-lg bg-white p-4 shadow-md"
                        @click="close(notification.id)"
                    >
                        <div class="flex w-8 justify-center py-1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    id="check_circle_FILL0_wght400_GRAD0_opsz48_2_"
                                    data-name="check_circle_FILL0_wght400_GRAD0_opsz48 (2)"
                                    d="M10.82,13.82,8.86,11.86a.6.6,0,0,0-.44-.18.684.684,0,0,0-.66.66.57.57,0,0,0,.2.44l2.44,2.44a.58.58,0,0,0,.84,0l4.8-4.8a.566.566,0,0,0,.17-.44.635.635,0,0,0-.19-.44.58.58,0,0,0-.45-.2.643.643,0,0,0-.47.2ZM12,20a8.114,8.114,0,0,1-3.16-.61,7.743,7.743,0,0,1-4.23-4.23A8.114,8.114,0,0,1,4,12a8.062,8.062,0,0,1,.61-3.14A7.808,7.808,0,0,1,6.3,6.32,7.976,7.976,0,0,1,12,4a7.936,7.936,0,0,1,8,8,7.976,7.976,0,0,1-2.32,5.7,7.808,7.808,0,0,1-2.54,1.69A8.062,8.062,0,0,1,12,20ZM12,12Zm0,6.8A6.663,6.663,0,0,0,18.8,12,6.663,6.663,0,0,0,12,5.2,6.663,6.663,0,0,0,5.2,12,6.663,6.663,0,0,0,12,18.8Z"
                                    transform="translate(-4 -4)"
                                    fill="#33de40"
                                />
                            </svg>
                        </div>

                        <div class="text-sm text-black">
                            <span class="font-medium">{{ notification.title }}</span>
                            <p class="">
                                {{ notification.text }}
                            </p>
                        </div>
                    </div>
                </Notification>
            </div>
        </div>
    </NotificationGroup>
</template>

<script lang="ts" setup>
import Label from "@/Components/Input/Label.vue";
import SmallButton from "@/Components/Buttons/SmallButton.vue";
import {TrashIcon} from "@heroicons/vue/24/outline";
import Money from "@/Components/Input/Money.vue";
import Number from "@/Components/Input/Number.vue";
import {InertiaForm, router, useForm} from "@inertiajs/vue3";
import {inject} from "vue";
import {route as routeFn} from 'ziggy-js';
import {extractError} from "@/typeSciptHelpers";

const route = inject('route') as typeof routeFn;

const props = defineProps<{
    line: object;
    vatRateOptions: object;
}>();

function destroy() {
    router.delete(route('quotation-lines.destroy', {quotation_line: props.line.id}));
}

const form: InertiaForm<{
    description: string,
    quantity: number,
    price: number,
    vat_rate: number,
    amount: number,
}> = useForm({
    description: props.line.description,
    quantity: props.line.quantity,
    price: props.line.price.price,
    vat_rate: props.line.price.vat_rate,
    amount: props.line.amount,
});

function setAmount() {
    form.amount = form.quantity * form.price;
    submit();
}

function submit() {
    console.log('submit');
    form.put(route('quotation-lines.update', {quotation_line: props.line.id}), {
        preserveScroll: true,
    });
}

</script>

<template>
    <div>
        {{ form.isDirty }}
    </div>
    <div class="grid grid-cols-12 gap-4 gap-y-0 md:grid-cols-11">
        <FormKit
            v-model="form.description"
            :classes="{ outer: 'col-span-9 md:col-span-6' }"
            :errors="extractError(form.errors,'description')"
            label="Omschrijving"
            type="text"
            @update:model-value="submit"
        />

        <Number
            v-model="form.quantity"
            :errors="extractError(form.errors,'quantity')"
            class="col-span-3 md:col-span-1"
            label="Aantal"
            @update:model-value="setAmount"
        />

        <Money
            v-model="form.price"
            :errors="extractError(form.errors,'price')"
            class="col-span-3 md:col-span-1"
            label="Prijs"
            @update:model-value="setAmount"
        />

        <FormKit
            v-model="form.vat_rate"
            :classes="{ outer: 'col-span-4 md:col-span-1' }"
            :errors="extractError(form.errors,'vat_rate')"
            :options="vatRateOptions"
            label="BTW"
            name="vat_rate"
            type="select"
            @update:model-value="submit"
        />

        <div class="col-span-5 flex gap-4 md:col-span-2">
            <div class="flex-1">
                <Money
                    v-model="form.amount"
                    :errors="extractError(form.errors,'amount')"
                    classes="text-right"
                    label="Totaal"
                    @update:model-value="submit"
                />
            </div>
            <div class="mb-2 flex flex-col">
                <Label label="&nbsp;" />
                <SmallButton @click="destroy()">
                    <TrashIcon
                        aria-hidden="true"
                        class="size-5 text-white"
                    />
                </SmallButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import {inject} from 'vue';
import Divider from '@/Components/Divider.vue';
import InputGroup from '@/Components/Input/Group.vue';

const route = inject('route');

const props = defineProps({
    form: Object,
    permission: Boolean,
});

const fillAddress = () => {
    if (props.form.postcode == '' || props.form.street_number == '') {
        return;
    }

    props.form.errors.postcode = [];
    window.axios
        .get(
            route('api.address-check.index', {
                postcode: props.form.postcode,
                street_number: props.form.street_number,
            })
        )
        .then(function (response) {
            props.form.postcode = response.data.data.postcode;
            props.form.street_number = response.data.data.street_number;
            props.form.street_name = response.data.data.street_name;
            props.form.city = response.data.data.city;
            props.form.country = 'NL';
            props.form.longitude = response.data.data.longitude;
            props.form.latitude = response.data.data.latitude;
            props.form.errors.postcode = [];
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 404) {
                    props.form.errors.postcode = ['"Kon geen adres vinden met deze postcode en huisnummer'];
                }
            }
        });
};
</script>
<template>
    <Divider title="Adres" />
    <InputGroup>
        <FormKit
            v-model="form.postcode"
            type="text"
            name="postcode"
            label="Postcode"
            :errors="form.errors?.postcode"
            :value="form.postcode"
            :disabled="!permission"
            validate="required"
            @change="fillAddress()"
        />
        <FormKit
            v-model="form.street_number"
            type="text"
            name="street_number"
            label="Huisnummer"
            :errors="form.errors?.street_number"
            :value="form.street_number"
            :disabled="!permission"
            validate="required"
            @change="fillAddress()"
        />

        <FormKit
            v-model="form.street_number_addition"
            type="text"
            name="street_number_addition"
            label="Huisnummer toev."
            :errors="form.errors?.street_number_addition"
            :value="form.street_number_additions"
            :disabled="!permission"
            validate="required"
        />
    </InputGroup>

    <InputGroup>
        <FormKit
            v-model="form.street_name"
            type="text"
            name="street_name"
            label="Straatnaam"
            :errors="form.errors?.street_name"
            :value="form.street_name"
            :disabled="!permission"
            validate="required"
        />

        <FormKit
            v-model="form.city"
            type="text"
            name="city"
            label="Stad"
            :errors="form.errors?.city"
            :value="form.city"
            :disabled="!permission"
            validate="required"
        />
    </InputGroup>
    <!-- <InputGroup>
        <FormKit
            type="text"
            name="country"
            label="Land"
            v-model="form.country"
            :errors="form.errors?.country"
            :value="form.country"
            validate="required"
        />

        <FormKit
            type="text"
            name="longitude"
            label="Lengtegraad"
            v-model="form.longitude"
            :errors="form.errors?.longitude"
            :value="form.longitude"
            validate="required"
        />

        <FormKit
            type="text"
            name="latitude"
            label="Breedtegraad"
            v-model="form.latitude"
            :errors="form.errors?.latitude"
            :value="form.latitude"
            validate="required"
        />
    </InputGroup> -->
</template>

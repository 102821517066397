<script setup>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';
const props = defineProps({
    pageData: Object,
});
const emit = defineEmits(['setPage']);

const getPage = (url) => {
    if (!url) {
        return;
    }
    const params = new URLSearchParams(url.split('?')[1]);

    return params.get('page');
};
</script>
<template>
    <div class="flex items-center justify-between border-t border-gray-200 bg-beige-100 px-4 py-3 sm:px-6">
        <div class="flex flex-1 items-center justify-between">
            <div>
                <p class="text-sm text-gray-700">
                    Toont
                    {{ ' ' }}
                    <span class="font-medium">{{ pageData.from ?? 0 }}</span>
                    {{ ' ' }}
                    tot
                    {{ ' ' }}
                    <span class="font-medium">{{ pageData.to ?? 0 }}</span>
                    {{ ' ' }}
                    van
                    {{ ' ' }}
                    <span class="font-medium">{{ pageData.total }}</span>
                    {{ ' ' }}
                    resultaten
                </p>
            </div>
            <div>
                <nav
                    class="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                >
                    <template
                        v-for="(link, key) in pageData.links"
                        :key="key"
                    >
                        <template v-if="key === 0">
                            <button
                                class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                @click="emit('setPage', getPage(link.url) || 1)"
                            >
                                <span class="sr-only">Previous</span>
                                <ChevronLeftIcon
                                    class="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                        </template>
                        <template v-else-if="key === pageData.links.length - 1">
                            <button
                                class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                @click="emit('setPage', getPage(link.url) || 1)"
                            >
                                <span class="sr-only">Next</span>
                                <ChevronRightIcon
                                    class="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </button>
                        </template>
                        <template v-else-if="link.url == null">
                            <span
                                class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
                            >
                                ...
                            </span>
                        </template>
                        <template v-else>
                            <button
                                class="relative hidden items-center border border-gray-300 px-4 py-2 text-sm font-medium focus:z-20 md:inline-flex"
                                :class="{
                                    'z-10 border-pine-500 bg-white text-pine-600': link.active,
                                    'border-gray-300 bg-white text-gray-400 hover:bg-gray-50': !link.active,
                                }"
                                @click="emit('setPage', getPage(link.url) || 1)"
                            >
                                {{ link.label }}
                            </button>
                        </template>
                    </template>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup>
import InputGroup from '@/Components/Input/Group.vue';
import {inject} from 'vue';

const props = defineProps({
    form: Object,
    disabled: Boolean,
});
const route = inject('route');

const fillAddress = () => {
    if (
        props.form.postcode == '' ||
        props.form.postcode == null ||
        props.form.street_number == '' ||
        props.form.street_number == null
    ) {
        return;
    }
    props.form.errors = {};
    window.axios
        .get(
            route('api.address-check.index', {
                postcode: props.form.postcode,
                street_number: props.form.street_number,
            })
        )
        .then(function (response) {
            props.form.postcode = response.data.data.postcode;
            props.form.street_number = response.data.data.street_number;
            props.form.street_name = response.data.data.street_name;
            props.form.city = response.data.data.city;
            props.form.country = 'NL';
            props.form.longitude = response.data.data.longitude;
            props.form.latitude = response.data.data.latitude;
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 404) {
                    props.form.errors.postcode = ['Geen adres gevonden met deze postcode en huisnummer'];
                }
            }
        });
};
</script>
<template>
    <InputGroup cols="4">
        <FormKit
            v-model="form.postcode"
            type="text"
            label="Postcode"
            :errors="form.errors?.postcode"
            :value="form.postcode"
            validate="required"
            :classes="{
                outer: 'col-span-2',
            }"
            :disabled="disabled"
            @blur="fillAddress()"
        />

        <FormKit
            v-model="form.street_number"
            type="number"
            label="Huisnummer"
            :errors="form.errors?.street_number"
            :value="form.street_number"
            :disabled="disabled"
            @blur="fillAddress()"
        />

        <FormKit
            v-model="form.street_number_addition"
            type="text"
            label="Toevoeging"
            :errors="form.errors?.street_number_addition"
            :value="form.street_number_addition"
            :disabled="disabled"
        />
    </InputGroup>
    <InputGroup>
        <FormKit
            v-model="form.street_name"
            type="text"
            label="Straatnaam"
            :errors="form.errors?.street_name"
            :value="form.street_name"
            :disabled="disabled"
        />
        <FormKit
            v-model="form.city"
            type="text"
            label="Stad"
            :errors="form.errors?.city"
            :value="form.city"
            :disabled="disabled"
        />
    </InputGroup>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    modelValue: Boolean,
});

const emits = defineEmits(['close', 'update:modelValue']);

function close() {
    emits('update:modelValue', false);
}
</script>

<template>
    <TransitionRoot
        as="template"
        :show="modelValue"
    >
        <Dialog
            as="div"
            class="relative z-10"
            @close="close()"
        >
            <div class="fixed inset-0" />

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex w-full max-w-full pl-10 sx:w-96">
                        <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full"
                            enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0"
                            leave-to="translate-x-full"
                        >
                            <DialogPanel
                                class="scroll-hidden hide-scrollbar pointer-events-auto relative w-full max-w-96 shrink-0 overflow-y-auto border-l border-beige-500 bg-beige-500"
                            >
                                <div class="hide-scrollbar flex h-full flex-col overflow-y-scroll shadow-xl">
                                    <div class="h-16 bg-pine-500 px-4 py-6 sm:px-6">
                                        <div class="flex items-start justify-between">
                                            <div class="ml-3 flex h-4 items-center">
                                                <button
                                                    type="button"
                                                    class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    @click="close()"
                                                >
                                                    <span class="sr-only">Close panel</span>
                                                    <XMarkIcon
                                                        class="h-6 w-6 text-white"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relative flex-1 px-4 md:px-6">
                                        <!-- Replace with your content -->
                                        <slot />
                                        <!-- /End replace -->
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

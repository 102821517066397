<script setup>
import Button from '@/Components/Buttons/Button.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { computed, useSlots } from 'vue';

const props = defineProps({
    label: String,
    form: Object,
    red: Boolean,
    disabled: Boolean,
    is: String,
    items: {
        type: Array,
        default: [],
    },
    fullWidth: Boolean,
});

const emits = defineEmits(['buttonClick']);

const normal = !props.red;

const slots = useSlots();

const visibleItems = computed(() => props.items.filter((item) => item?.visible ?? true));
const hasItems = typeof props.items !== 'undefined' && visibleItems.value.length > 0;
</script>
<template>
    <div class="inline-flex rounded-md shadow-sm">
        <Button
            :label="label"
            :form="form"
            :red="red"
            :disabled="disabled"
            :remove-rounding="hasItems"
            :full-width="fullWidth"
            classes="rounded-l-md"
            @click="emits('buttonClick', 'main')"
        />

        <Menu
            v-if="hasItems"
            as="div"
            class="relative -ml-px block"
        >
            <MenuButton
                class="relative inline-flex h-10 items-center rounded-r-md border-l border-gray-300 bg-pine-800 p-2 text-sm font-medium text-white hover:bg-pine-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-pine-500 focus:ring-offset-1"
            >
                <span class="sr-only">Open options</span>
                <ChevronDownIcon
                    class="h-5 w-5"
                    aria-hidden="true"
                />
            </MenuButton>
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <MenuItems
                    class="absolute right-0 z-10 -mr-1 mt-2 min-w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <div class="py-1">
                        <MenuItem
                            v-for="item in visibleItems"
                            :key="item"
                            v-slot="{ active }"
                        >
                            <button
                                :class="[
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block w-full whitespace-nowrap px-4 py-2 text-right text-sm',
                                ]"
                                @click="emits('buttonClick', item.value)"
                            >
                                {{ item.label }}
                            </button>
                        </MenuItem>
                    </div>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script setup>
import Errors from '@/Components/Input/Errors.vue';
import Label from '@/Components/Input/Label.vue';
import theme from '@/formkitTheme';
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/20/solid';
import { computed, ref } from 'vue';

const props = defineProps({
    label: {},
    modelValue: {},
    errors: [],
    disabled: Boolean,
    help: String,
    type: String,
    classes: String,
    options: Array,
});

const emits = defineEmits(['update:modelValue', 'change', 'helpClicked']);

const displayValue = computed(() => props.modelValue);

const emitValue = (value) => {
    value = value.target.value;

    emits('update:modelValue', value);
    emits('change', value);
};

const show = ref(false);
</script>
<template>
    <div :class="theme.global.outer">
        <Label :label="label" />
        <div :class="theme.global.inner">
            <input
                :id="label ?? 'input'"
                :type="show ? 'text' : 'password'"
                :class="theme.global.input"
                :value="displayValue"
                :disabled="disabled"
                @blur="emitValue"
                @change="emitValue"
            >
            <button
                class="absolute right-4 top-1/2 -translate-y-1/2"
                @click="show = !show"
            >
                <EyeIcon
                    v-if="show"
                    class="h-5 w-5 text-pine-500"
                />
                <EyeSlashIcon
                    v-if="!show"
                    class="h-5 w-5 text-pine-500"
                />
            </button>
        </div>
        <Errors :error="errors" />
    </div>
</template>

<script setup>
import Button from '@/Components/Buttons/Button.vue';
import FormButton from '@/Components/Buttons/FormButton.vue';
import InputGroup from '@/Components/Input/Group.vue';
import SelectSearch from '@/Components/Input/SelectSearch.vue';
import PageTitle from '@/Components/PageTitle.vue';
import Pagination from '@/Components/Pagination.vue';
import Select from '@/Components/Select.vue';
import Table from '@/Components/Table/Table.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import ExportModal from '@/Pages/TimeRegistration/Partials/ExportModal.vue';
import {useUser} from '@/stores/userStore';
import {capitalFirstLetter} from '@/utilities';
import {useForm} from '@inertiajs/vue3';
import {computed, inject, ref} from 'vue';

const route = inject('route');
const user = useUser();
const dayjs = inject('dayjs');

const props = defineProps({
    query: Object,
    timeRegistrations: Object,
    statusOptions: Object,
    userOptions: Object,
    exportTypeOptions: Object,
    eventOptions: Object,
});

const form = useForm({
    orderBy: props.query.orderBy ?? 'name',
    orderDir: props.query.orderDir ?? 'desc',
    page: props.query.page ?? 1,
    user_id: props.query.user_id ?? '',
    event_id: props.query.event_id ?? '',
    status: props.query.status ?? '',
});

const getData = (page = 1) => {
    form.page = page;

    form.get(route('time-registrations.index'), {
        preserveState: true,
        only: ['timeRegistrations', 'query'],
    });
};

const tableSettings = computed(() => [
    {
        label: 'Naam',
        orderBy: 'name',
        width: '15%',
        combinesWith: {
            label: 'Start',
            till: 'md',
        },
    },
    {
        label: 'Start',
        orderBy: 'start',
        width: '10%',
        from: 'md',
    },
    {label: 'Einde', width: '10%', from: 'xl'},
    {label: 'Pauze', width: '10%', from: 'md'},
    {label: 'Status', width: '10%'},
    {
        label: 'Acties',
        width: '15%',
        cellType: 'formButton',
        permission: user.canAny(['approve-time-registrations', 'reject-time-registrations']),
        from: 'md',
    },
    {
        width: '1%',
        cellType: 'arrow',
        permission: user.canAny(['update-time-registrations', 'update-own-time-registrations']),
    },
]);

const tableData = computed(() =>
    props.timeRegistrations.data.map((registration) => {
        return {
            id: registration.id,
            rowData: [
                registration.user_name,
                capitalFirstLetter(dayjs(registration.start).format('dddd, DD-MM-YYYY HH:mm')),
                capitalFirstLetter(dayjs(registration.end).format('dddd, DD-MM-YYYY HH:mm')),
                registration.break,
                props.statusOptions[registration.status],
                getButton(registration),
                route('time-registrations.edit', registration.id),
            ],
        };
    })
);

function getButton(registration) {
    let buttons = [];

    if (registration.status === 'submitted') {
        if (user.can('approve-time-registrations')) {
            buttons.push({
                label: 'Accorderen',
                method: 'post',
                url: route('approved-time-registrations.store'),
                data: {time_registration_id: registration.id},
            });
        }
        if (user.can('reject-time-registrations')) {
            buttons.push({
                label: 'Afwijzen',
                method: 'post',
                url: route('rejected-time-registrations.store', registration.id),
                red: true,
                data: {time_registration_id: registration.id},
            });
        }
    }

    return buttons;
}

const showExportModal = ref(false);
</script>

<template>
    <AppLayout>
        <PageTitle title="Uren registratie">
            <FormButton
                v-if="user.can('viewAny-time-registrations')"
                :url="route('time-registrations.overviews.month')"
                label="Maand overzicht"
                method="get"
            />
            <Button
                v-if="user.can('viewAny-time-registrations')"
                label="Uren exporteren"
                @click="showExportModal = true"
            />
            <FormButton
                v-if="user.canAny(['create-time-registrations', 'create-own-time-registrations'])"
                :url="route('time-registrations.create')"
                label="Uren toevoegen"
                method="get"
            />
        </PageTitle>
        <Table
            v-model:orderBy="form.orderBy"
            v-model:orderDir="form.orderDir"
            :loading="form.processing"
            :table-data="tableData"
            :table-settings="tableSettings"
            @update:order-by="getData()"
            @update:order-dir="getData()"
        >
            <template #filters>
                <InputGroup>
                    <SelectSearch
                        v-if="user.can('viewAny-time-registrations')"
                        v-model="form.user_id"
                        :options="userOptions"
                        clearable
                        first-selected
                        label="Werknemer"
                        placeholder="Selecteer een werknemer"
                        @change="getData()"
                    />
                    <Select
                        v-model="form.status"
                        :options="statusOptions"
                        clearable
                        label="Status"
                        placeholder="Selecteer een status"
                        @change="getData()"
                    />
                </InputGroup>
            </template>
            <Pagination
                :page-data="timeRegistrations"
                @set-page="getData"
            />
        </Table>
        <ExportModal
            v-model="showExportModal"
            :export-type-options="exportTypeOptions"
        />
    </AppLayout>
</template>

<script setup>
import FormButton from '@/Components/Buttons/FormButton.vue';
import InputGroup from '@/Components/Input/Group.vue';
import PageTitle from '@/Components/PageTitle.vue';
import Pagination from '@/Components/Pagination.vue';
import Table from '@/Components/Table/Table.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import {useUser} from '@/stores/userStore';

import {useForm} from '@inertiajs/vue3';
import {useDebounceFn} from '@vueuse/shared';
import {computed, inject} from 'vue';

const route = inject('route');
const user = useUser();

const props = defineProps({
    query: Object,
    contacts: Object,
});

const form = useForm({
    search: props.query.search ?? '',
    orderBy: props.query.orderBy ?? 'name',
    orderDir: props.query.orderDir ?? 'desc',
    page: props.query.page ?? 1,
});

const getData = (page = 1) => {
    form.page = page;

    form.get(route('contacts.index'), {
        preserveState: true,
        only: ['contacts', 'query'],
    });
};
const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = computed(() => [
    {
        label: 'Naam',
        orderBy: 'name',
        width: '15%',
    },
    {
        label: 'Email',
        width: '15%',
        cellType: 'email',
        combinesWith: {
            horizontal: true,
            items: [
                {
                    label: 'Telefoon',
                    till: 'md',
                },
            ],
        },
    },
    {label: 'Telefoon', width: '15%', cellType: 'phone', from: 'md'},
    {label: 'Type', width: '15%', from: 'lg'},
    {label: '', width: '1%', cellType: 'arrow', permission: user.can('update-contacts')},
]);

const tableData = computed(() =>
    props.contacts.data.map((contact) => {
        return {
            id: contact.id,
            rowData: [
                contact.name,
                contact.email_addresses,
                contact.phone_number,
                contact.type,
                route('contacts.edit', {contact: contact.id}),
            ],
        };
    })
);
</script>

<template>
    <AppLayout>
        <PageTitle title="Contacten">
            <FormButton
                v-if="user.can('create-contacts')"
                :url="route('contacts.create')"
                label="Contact toevoegen"
                method="get"
            />
        </PageTitle>

        <Table
            v-model:orderBy="form.orderBy"
            v-model:orderDir="form.orderDir"
            :loading="form.processing"
            :table-data="tableData"
            :table-settings="tableSettings"
            @update:order-by="getData()"
            @update:order-dir="getData()"
        >
            <template #filters>
                <InputGroup>
                    <FormKit
                        v-model="form.search"
                        :errors="form.errors?.search"
                        :value="form.search"
                        label="Zoeken"
                        suffix-icon="search"
                        type="text"
                        @input="debouncedGetData"
                    />
                </InputGroup>
            </template>

            <Pagination
                :page-data="contacts"
                @set-page="getData"
            />
        </Table>
    </AppLayout>
</template>

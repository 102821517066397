<script setup>
import { computed, useSlots } from 'vue';
import ReactiveSlot from '@/Components/Buttons/ReactiveSlot.vue';

const props = defineProps({
    label: String,
    icon: [String, Object, Function],
    form: Object,
    red: Boolean,
    disabled: Boolean,
    is: String,
    removeRounding: Boolean,
    fullWidth: Boolean,
});

const normal = !props.red;

const slots = useSlots();

const hasError = computed(() => {
    return typeof props.form?.errors == 'undefined'
        ? false
        : Object.values(props.form.errors).some((error) => error.length > 0);
});
</script>
<template>
    <component
        :is="is == 'a' ? 'a' : 'button'"
        :type="is == 'a' ? '' : 'button'"
        class="relative inline-flex h-7 w-7 items-center justify-center rounded-full text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-300 disabled:text-gray-700 md:h-unset md:w-unset md:min-w-30 md:justify-start md:rounded md:px-4 md:py-3"
        :class="{
            'bg-red-700 text-white hover:bg-red-800 focus:ring-red-600': red || hasError,
            'bg-pine-800 text-white hover:bg-pine-600 focus:ring-pine-500': normal && !hasError,
            'cursor-progress': form?.processing,
            'cursor-not-allowed': form?.recentlySuccessful,
            'md:rounded-md': !removeRounding,
            ' ': !fullWidth,
        }"
        :disabled="disabled"
    >
        <ReactiveSlot
            :processing="form?.processing ?? false"
            :recently-successful="form?.recentlySuccessful ?? false"
        >
            <template v-if="!slots.default">
                <div
                    :class="{ 'hidden md:block': icon }"
                    class="leading-none"
                >
                    {{ label }}
                </div>
                <img
                    v-if="icon && typeof icon == 'string'"
                    :src="icon"
                    class="mx-auto h-3 w-3 md:hidden"
                >
                <component
                    :is="icon"
                    v-else-if="icon"
                    class="mx-auto h-4 w-4 md:hidden"
                />
            </template>

            <slot />
        </ReactiveSlot>
    </component>
</template>

<script setup>
import Button from '@/Components/Buttons/Button.vue';
import FormButton from '@/Components/Buttons/FormButton.vue';
import SmallButton from '@/Components/Buttons/SmallButton.vue';
import Card from '@/Components/Card.vue';
import GenericInput from '@/Components/Input/GenericInput.vue';
import Label from '@/Components/Input/Label.vue';
import { useUser } from '@/stores/userStore';
import { TrashIcon } from '@heroicons/vue/24/outline';

const user = useUser();

const props = defineProps({
    form: Object,
});

function removeWhoOption(option) {
    const index = props.form.whatOptions.findIndex((whatOption) => whatOption === option);
    props.form.whatOptions.splice(index, 1);
}

function addWhoOption() {
    props.form.whatOptions.push('');
}
</script>
<template>
    <Card
        collapsable
        collapsed
    >
        <template #title>
            Go Ahead Eagles Wat opties
        </template>

        <div class="grid gap-x-8 md:grid-cols-2 lg:grid-cols-4">
            <div
                v-for="(line, index) in form.whatOptions"
                :key="line"
                class="flex gap-4"
            >
                <GenericInput
                    v-model="form.whatOptions[index]"
                    type="text"
                    label="Optie"
                    class="col-span-4"
                />
                <div>
                    <Label label="&nbsp;" />
                    <SmallButton
                        red
                        @click="removeWhoOption(line)"
                    >
                        <TrashIcon
                            class="h-5 w-5 text-white"
                            aria-hidden="true"
                        />
                    </SmallButton>
                </div>
            </div>
        </div>

        <template #buttons>
            <Button
                label="Optie toevoegen"
                @click="addWhoOption"
            />
            <FormButton
                v-if="user.canAny(['update-setting'])"
                :url="route('settings.update', { setting: 'gea_event', stay: true })"
                method="put"
                :data="{ whatOptions: form.whatOptions }"
                label="Opslaan"
            />
        </template>
    </Card>
</template>

<script setup>
import theme from '@/formkitTheme';
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/vue';
import {CheckIcon, ChevronDownIcon, XMarkIcon} from '@heroicons/vue/20/solid';
import {computed} from 'vue';
import Label from "@/Components/Input/Label.vue";

let props = defineProps({
    modelValue: Array,
    options: Object,
    placeholder: String,
    label: String,
    clearable: Boolean,
    help: String,
    errors: Array,
});

const formattedOptions = computed(() => {
    let formattedOptions = [];
    Object.keys(props.options).map((key) => {
        formattedOptions = [...formattedOptions, {id: parseInt(key), name: props.options[key]}];
    });
    return formattedOptions;
});

const emit = defineEmits(['update:modelValue', 'helpClicked']);

const selected = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});
</script>

<template>
    <Listbox
        v-model="selected"
        as="div"
        multiple
    >
        <Label :label="label" />
        <div class="relative mt-1">
            <ListboxButton
                class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-pine-500 focus:outline-none focus:ring-1 focus:ring-pine-500 sm:text-sm"
            >
                <span
                    v-if="selected.length"
                    class="block truncate"
                >
                    {{ selected.map((id) => options[id]).join(', ') }}
                </span>
                <span
                    v-else
                    class="block truncate"
                >
                    {{ placeholder }}
                </span>
                <span
                    v-if="clearable && selected.length"
                    class="z-100 absolute inset-y-0 right-0 flex items-center pr-2"
                    @click.prevent="selected = []"
                >
                    <XMarkIcon
                        aria-hidden="true"
                        class="size-5 text-gray-400"
                    />
                </span>
                <span
                    v-else
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                >
                    <ChevronDownIcon
                        aria-hidden="true"
                        class="size-5 text-gray-400"
                    />
                </span>
            </ListboxButton>

            <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <ListboxOption
                        v-for="option in formattedOptions"
                        :key="option.id"
                        v-slot="{ active, selected }"
                        :value="option.id"
                        as="template"
                    >
                        <li
                            :class="[
                                active ? 'bg-pine-500 text-white' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9',
                            ]"
                            @click="emit('update:modelValue', option.id)"
                        >
                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                                option.name
                            }}</span>

                            <span
                                v-if="selected"
                                :class="[
                                    active ? 'text-white' : 'text-pine-500',
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                ]"
                            >
                                <CheckIcon
                                    aria-hidden="true"
                                    class="size-5"
                                />
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
        <div
            v-if="help"
            :class="theme.global.help"
            @click="emit('helpClicked')"
            v-html="help"
        />
        <ul
            v-if="typeof errors !== 'undefined' && errors.length > 0"
            :class="theme.global.messages"
        >
            <li
                v-for="error in errors"
                :key="error"
                :class="theme.global.message"
                v-html="error"
            />
        </ul>
    </Listbox>
</template>

<script setup>
import { computed } from 'vue';
import Cell from '@/Components/Table/Cell.vue';

const props = defineProps({
    tableSettings: Object,
    rowData: Object,
    large: Boolean,
});

const filteredRowData = computed(() =>
    props.rowData.filter(
        (_, i) => typeof props.tableSettings[i].permission === 'undefined' || props.tableSettings[i].permission === true
    )
);

const filteredTableSettings = computed(() =>
    props.tableSettings.filter(
        (columnSetting) => typeof columnSetting.permission === 'undefined' || columnSetting.permission === true
    )
);

function indexFromLabel(label) {
    return filteredTableSettings.value.findIndex((columnSetting) => columnSetting.label === label);
}
</script>
<template>
    <td
        v-for="(cellData, j) in filteredRowData"
        :key="j"
        class="text-sm text-pine-500"
        :class="{
            'px-3 py-4': !large,
            'px-3 py-4  xl:p-5 2xl:p-6': large,
            'pl-4 sm:pl-6': j == 0,
            'pr-4 text-right sm:pr-6': j == rowData.length - 1,
            'hidden md:table-cell': filteredTableSettings[j]?.from === 'md',
            'hidden lg:table-cell': filteredTableSettings[j]?.from === 'lg',
            'hidden xl:table-cell': filteredTableSettings[j]?.from === 'xl',
            'hidden 2xl:table-cell': filteredTableSettings[j]?.from === '2xl',
            'hidden 3xl:table-cell': filteredTableSettings[j]?.from === '3xl',
            'md:hidden': filteredTableSettings[j]?.till === 'md',
            'lg:hidden': filteredTableSettings[j]?.till === 'lg',
            'xl:hidden': filteredTableSettings[j]?.till === 'xl',
            '2xl:hidden': filteredTableSettings[j]?.till === '2xl',
            '3xl:hidden': filteredTableSettings[j]?.till === '3xl',
        }"
    >
        <template v-if="typeof filteredTableSettings[j].combinesWith === 'undefined'">
            <Cell
                :cell-data="cellData"
                :table-settings="filteredTableSettings[j]"
                :is-last="j == filteredTableSettings.length - 1"
            />
        </template>
        <template v-else>
            <div
                :class="{ 'flex-col ': filteredTableSettings[j].combinesWith?.horizontal !== true }"
                class="flex gap-2"
            >
                <Cell
                    :cell-data="cellData"
                    :table-settings="filteredTableSettings[j]"
                    :is-last="j == filteredTableSettings.length - 1"
                />
                <Cell
                    v-for="(combineWith, k) in filteredTableSettings[j].combinesWith.items"
                    :key="k"
                    :cell-data="filteredRowData[indexFromLabel(combineWith?.label)]"
                    :table-settings="filteredTableSettings[indexFromLabel(combineWith?.label)]"
                    :is-last="j == filteredTableSettings.length - 1"
                    :from="combineWith?.from"
                    :till="combineWith?.till"
                />
            </div>
        </template>
    </td>
</template>
